import { Auth0Config } from '@clinintell/modules/auth0Config';
import { useAppConfigDispatch, useAuth0ConfigDispatch } from '@clinintell/modules/store';
import { ApplicationAPI, LoginAPI } from '@clinintell/utils/api';
import { useEffect, useState } from 'react';
import createAuth0Client, { Auth0Client } from '@auth0/auth0-spa-js';

type UseCreateAuth0ClientOutput = {
  auth0Client: Auth0Client | undefined;
  isBDTenant: boolean;
};

// Handles environment variables injected through webpack and fetches auth0 tenant information
const useCreateAuth0Client = (): UseCreateAuth0ClientOutput => {
  const [auth0Client, setAuth0Client] = useState<Auth0Client>();
  const [isBDTenant, setIsBDTenant] = useState(false);

  const appConfigDispatch = useAppConfigDispatch();
  const auth0Dispatch = useAuth0ConfigDispatch();

  useEffect(() => {
    // TODO -- quick bandaid to remove browser stack state on browser refresh
    window.history.replaceState(null, '');

    const { origin } = window.location;
    // The application API is always based on the subdomain. If debugging (localhost), it is the same as the login API.
    const splitDomain = origin.split('.');
    // If only one record (localhost) or first record is local (local.clinintell.com), we can assume this is a local debug instance
    const isDebuggerInstance = splitDomain.length === 1 || splitDomain[0].includes('local');

    const apiUrl = isDebuggerInstance ? `https://${LOGIN_API_PREFIX}.${BASE_API}` : `${splitDomain[0]}.${BASE_API}`;

    const isBD = splitDomain[0].toLowerCase().includes('bd');
    setIsBDTenant(isBD);

    // Set base API urls
    ApplicationAPI.setUrl(apiUrl);
    LoginAPI.setUrl(apiUrl);

    appConfigDispatch({
      type: 'SET_CONFIG',
      payload: {
        environment: origin,
        loginAPI: apiUrl,
        applicationAPI: apiUrl,
        isBDTenant: isBD
      }
    });

    const getAuth0Settings = async (): Promise<void> => {
      const { data } = await LoginAPI.get<Auth0Config>({
        endpoint: 'tenantsettings/authconfig'
      });

      const auth0Settings = data as Auth0Config;

      auth0Dispatch({
        type: 'SET_AUTH0_CONFIG',
        payload: { ...auth0Settings }
      });

      const { clientId, endpointUrl, audienceId } = auth0Settings;
      ApplicationAPI.setEnvironment(origin);

      // Now we can authenticate the application API with Auth0 tenant information
      const auth0 = await createAuth0Client({
        client_id: clientId,
        audience: audienceId,
        domain: endpointUrl
      });

      ApplicationAPI.setAuth0WebClient(auth0);
      setAuth0Client(auth0);
    };

    getAuth0Settings();
  }, [appConfigDispatch, auth0Dispatch]);

  return {
    auth0Client,
    isBDTenant
  };
};

export default useCreateAuth0Client;
