/**
 * Generated by @openapi-codegen
 *
 * @version 1.0
 */
import * as reactQuery from '@tanstack/react-query';
import { useApiContext, ApiContext } from './apiContext';
import type * as Fetcher from './apiFetcher';
import { apiFetch } from './apiFetcher';
import type * as Schemas from './apiSchemas';

export type AuthGetIdentitiesQueryParams = {
  /**
   * @format email
   */
  email: string;
};

export type AuthGetIdentitiesHeaders = {
  ['x-api-version']?: string;
};

export type AuthGetIdentitiesError = Fetcher.ErrorWrapper<undefined>;

export type AuthGetIdentitiesResponse = Schemas.AuthIdentityDto[];

export type AuthGetIdentitiesVariables = {
  headers?: AuthGetIdentitiesHeaders;
  queryParams: AuthGetIdentitiesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchAuthGetIdentities = (variables: AuthGetIdentitiesVariables, signal?: AbortSignal) =>
  apiFetch<
    AuthGetIdentitiesResponse,
    AuthGetIdentitiesError,
    undefined,
    AuthGetIdentitiesHeaders,
    AuthGetIdentitiesQueryParams,
    {}
  >({ url: '/api/Auth/Identities', method: 'get', ...variables, signal });

export const useAuthGetIdentities = <TData = AuthGetIdentitiesResponse>(
  variables: AuthGetIdentitiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<AuthGetIdentitiesResponse, AuthGetIdentitiesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<AuthGetIdentitiesResponse, AuthGetIdentitiesError, TData>(
    queryKeyFn({ path: '/api/Auth/Identities', operationId: 'authGetIdentities', variables }),
    ({ signal }) => fetchAuthGetIdentities({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type AuthGetUserHeaders = {
  ['x-api-version']?: string;
};

export type AuthGetUserError = Fetcher.ErrorWrapper<undefined>;

export type AuthGetUserVariables = {
  headers?: AuthGetUserHeaders;
} & ApiContext['fetcherOptions'];

/**
 * For provider users, the providerDetails will be populated with org details of the hospital, group and provider
 */
export const fetchAuthGetUser = (variables: AuthGetUserVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.AuthorizedUserDto, AuthGetUserError, undefined, AuthGetUserHeaders, {}, {}>({
    url: '/api/Auth/User',
    method: 'get',
    ...variables,
    signal
  });

/**
 * For provider users, the providerDetails will be populated with org details of the hospital, group and provider
 */
export const useAuthGetUser = <TData = Schemas.AuthorizedUserDto>(
  variables: AuthGetUserVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.AuthorizedUserDto, AuthGetUserError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.AuthorizedUserDto, AuthGetUserError, TData>(
    queryKeyFn({ path: '/api/Auth/User', operationId: 'authGetUser', variables }),
    ({ signal }) => fetchAuthGetUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CIUniversityGetGroupsHeaders = {
  ['x-api-version']?: string;
};

export type CIUniversityGetGroupsError = Fetcher.ErrorWrapper<undefined>;

export type CIUniversityGetGroupsResponse = Schemas.LearnUponApiLearningGroup[];

export type CIUniversityGetGroupsVariables = {
  headers?: CIUniversityGetGroupsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchCIUniversityGetGroups = (variables: CIUniversityGetGroupsVariables, signal?: AbortSignal) =>
  apiFetch<CIUniversityGetGroupsResponse, CIUniversityGetGroupsError, undefined, CIUniversityGetGroupsHeaders, {}, {}>({
    url: '/api/CIUniversity/Groups',
    method: 'get',
    ...variables,
    signal
  });

export const useCIUniversityGetGroups = <TData = CIUniversityGetGroupsResponse>(
  variables: CIUniversityGetGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<CIUniversityGetGroupsResponse, CIUniversityGetGroupsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<CIUniversityGetGroupsResponse, CIUniversityGetGroupsError, TData>(
    queryKeyFn({ path: '/api/CIUniversity/Groups', operationId: 'cIUniversityGetGroups', variables }),
    ({ signal }) => fetchCIUniversityGetGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CIUniversityPostGroupsByGroupIdMembersPathParams = {
  /**
   * the learning group ID
   *
   * @format int32
   */
  groupId: number;
};

export type CIUniversityPostGroupsByGroupIdMembersHeaders = {
  ['x-api-version']?: string;
};

export type CIUniversityPostGroupsByGroupIdMembersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type CIUniversityPostGroupsByGroupIdMembersVariables = {
  body?: Schemas.ClinIntellUniversityAddMembersToGroupDto;
  headers?: CIUniversityPostGroupsByGroupIdMembersHeaders;
  pathParams: CIUniversityPostGroupsByGroupIdMembersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCIUniversityPostGroupsByGroupIdMembers = (
  variables: CIUniversityPostGroupsByGroupIdMembersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    CIUniversityPostGroupsByGroupIdMembersError,
    Schemas.ClinIntellUniversityAddMembersToGroupDto,
    CIUniversityPostGroupsByGroupIdMembersHeaders,
    {},
    CIUniversityPostGroupsByGroupIdMembersPathParams
  >({ url: '/api/CIUniversity/Groups/{groupId}/Members', method: 'post', ...variables, signal });

export const useCIUniversityPostGroupsByGroupIdMembers = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      CIUniversityPostGroupsByGroupIdMembersError,
      CIUniversityPostGroupsByGroupIdMembersVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    CIUniversityPostGroupsByGroupIdMembersError,
    CIUniversityPostGroupsByGroupIdMembersVariables
  >(
    (variables: CIUniversityPostGroupsByGroupIdMembersVariables) =>
      fetchCIUniversityPostGroupsByGroupIdMembers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type CmiAnalysisGetWidgetDatesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisGetWidgetDatesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisGetWidgetDatesByOrgIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type CmiAnalysisGetWidgetDatesByOrgIdVariables = {
  headers?: CmiAnalysisGetWidgetDatesByOrgIdHeaders;
  pathParams: CmiAnalysisGetWidgetDatesByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisGetWidgetDatesByOrgId = (
  variables: CmiAnalysisGetWidgetDatesByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisGetWidgetDatesByOrgIdError,
    undefined,
    CmiAnalysisGetWidgetDatesByOrgIdHeaders,
    {},
    CmiAnalysisGetWidgetDatesByOrgIdPathParams
  >({ url: '/api/CmiAnalysis/WidgetDates/{orgId}', method: 'get', ...variables, signal });

export const useCmiAnalysisGetWidgetDatesByOrgId = <TData = Schemas.DomainModelOrgCmiAnalysisDates>(
  variables: CmiAnalysisGetWidgetDatesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetWidgetDatesByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetWidgetDatesByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/CmiAnalysis/WidgetDates/{orgId}',
      operationId: 'cmiAnalysisGetWidgetDatesByOrgId',
      variables
    }),
    ({ signal }) => fetchCmiAnalysisGetWidgetDatesByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CmiAnalysisGetDatesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisGetDatesByOrgIdQueryParams = {
  bestFit?: Schemas.DomainServiceBestFitType;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type CmiAnalysisGetDatesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisGetDatesByOrgIdError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: string;
}>;

export type CmiAnalysisGetDatesByOrgIdVariables = {
  headers?: CmiAnalysisGetDatesByOrgIdHeaders;
  pathParams: CmiAnalysisGetDatesByOrgIdPathParams;
  queryParams?: CmiAnalysisGetDatesByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisGetDatesByOrgId = (variables: CmiAnalysisGetDatesByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisGetDatesByOrgIdError,
    undefined,
    CmiAnalysisGetDatesByOrgIdHeaders,
    CmiAnalysisGetDatesByOrgIdQueryParams,
    CmiAnalysisGetDatesByOrgIdPathParams
  >({ url: '/api/CmiAnalysis/Dates/{orgId}', method: 'get', ...variables, signal });

export const useCmiAnalysisGetDatesByOrgId = <TData = Schemas.DomainModelOrgCmiAnalysisDates>(
  variables: CmiAnalysisGetDatesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetDatesByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOrgCmiAnalysisDates, CmiAnalysisGetDatesByOrgIdError, TData>(
    queryKeyFn({ path: '/api/CmiAnalysis/Dates/{orgId}', operationId: 'cmiAnalysisGetDatesByOrgId', variables }),
    ({ signal }) => fetchCmiAnalysisGetDatesByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type CmiAnalysisPutDatesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type CmiAnalysisPutDatesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type CmiAnalysisPutDatesByOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type CmiAnalysisPutDatesByOrgIdVariables = {
  body: Schemas.CmiAnalysisDatesRequestDto;
  headers?: CmiAnalysisPutDatesByOrgIdHeaders;
  pathParams: CmiAnalysisPutDatesByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchCmiAnalysisPutDatesByOrgId = (variables: CmiAnalysisPutDatesByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisPutDatesByOrgIdError,
    Schemas.CmiAnalysisDatesRequestDto,
    CmiAnalysisPutDatesByOrgIdHeaders,
    {},
    CmiAnalysisPutDatesByOrgIdPathParams
  >({ url: '/api/CmiAnalysis/Dates/{orgId}', method: 'put', ...variables, signal });

export const useCmiAnalysisPutDatesByOrgId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DomainModelOrgCmiAnalysisDates,
      CmiAnalysisPutDatesByOrgIdError,
      CmiAnalysisPutDatesByOrgIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DomainModelOrgCmiAnalysisDates,
    CmiAnalysisPutDatesByOrgIdError,
    CmiAnalysisPutDatesByOrgIdVariables
  >(
    (variables: CmiAnalysisPutDatesByOrgIdVariables) =>
      fetchCmiAnalysisPutDatesByOrgId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ConditionsGetQueryParams = {
  /**
   * @format int32
   */
  orgId?: number;
};

export type ConditionsGetHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetResponse = Schemas.ConditionDto[];

export type ConditionsGetVariables = {
  headers?: ConditionsGetHeaders;
  queryParams?: ConditionsGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGet = (variables: ConditionsGetVariables, signal?: AbortSignal) =>
  apiFetch<ConditionsGetResponse, ConditionsGetError, undefined, ConditionsGetHeaders, ConditionsGetQueryParams, {}>({
    url: '/api/Conditions',
    method: 'get',
    ...variables,
    signal
  });

export const useConditionsGet = <TData = ConditionsGetResponse>(
  variables: ConditionsGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<ConditionsGetResponse, ConditionsGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<ConditionsGetResponse, ConditionsGetError, TData>(
    queryKeyFn({ path: '/api/Conditions', operationId: 'conditionsGet', variables }),
    ({ signal }) => fetchConditionsGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ConditionsGetIcd10CodesByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ConditionsGetIcd10CodesByIdHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetIcd10CodesByIdError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetIcd10CodesByIdVariables = {
  headers?: ConditionsGetIcd10CodesByIdHeaders;
  pathParams: ConditionsGetIcd10CodesByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGetIcd10CodesById = (
  variables: ConditionsGetIcd10CodesByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.Icd10CodeConditionDto,
    ConditionsGetIcd10CodesByIdError,
    undefined,
    ConditionsGetIcd10CodesByIdHeaders,
    {},
    ConditionsGetIcd10CodesByIdPathParams
  >({ url: '/api/Conditions/Icd10Codes/{id}', method: 'get', ...variables, signal });

export const useConditionsGetIcd10CodesById = <TData = Schemas.Icd10CodeConditionDto>(
  variables: ConditionsGetIcd10CodesByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.Icd10CodeConditionDto, ConditionsGetIcd10CodesByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.Icd10CodeConditionDto, ConditionsGetIcd10CodesByIdError, TData>(
    queryKeyFn({ path: '/api/Conditions/Icd10Codes/{id}', operationId: 'conditionsGetIcd10CodesById', variables }),
    ({ signal }) => fetchConditionsGetIcd10CodesById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ConditionsGetIcd10CodesDownloadByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ConditionsGetIcd10CodesDownloadByIdHeaders = {
  ['x-api-version']?: string;
};

export type ConditionsGetIcd10CodesDownloadByIdError = Fetcher.ErrorWrapper<undefined>;

export type ConditionsGetIcd10CodesDownloadByIdVariables = {
  headers?: ConditionsGetIcd10CodesDownloadByIdHeaders;
  pathParams: ConditionsGetIcd10CodesDownloadByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchConditionsGetIcd10CodesDownloadById = (
  variables: ConditionsGetIcd10CodesDownloadByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ConditionsGetIcd10CodesDownloadByIdError,
    undefined,
    ConditionsGetIcd10CodesDownloadByIdHeaders,
    {},
    ConditionsGetIcd10CodesDownloadByIdPathParams
  >({ url: '/api/Conditions/Icd10Codes/Download/{id}', method: 'get', ...variables, signal });

export const useConditionsGetIcd10CodesDownloadById = <TData = undefined>(
  variables: ConditionsGetIcd10CodesDownloadByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ConditionsGetIcd10CodesDownloadByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ConditionsGetIcd10CodesDownloadByIdError, TData>(
    queryKeyFn({
      path: '/api/Conditions/Icd10Codes/Download/{id}',
      operationId: 'conditionsGetIcd10CodesDownloadById',
      variables
    }),
    ({ signal }) => fetchConditionsGetIcd10CodesDownloadById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DashboardDeleteByUserIdPathParams = {
  /**
   * Current User ID
   *
   * @format int32
   */
  userId: number;
};

export type DashboardDeleteByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type DashboardDeleteByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type DashboardDeleteByUserIdVariables = {
  headers?: DashboardDeleteByUserIdHeaders;
  pathParams: DashboardDeleteByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardDeleteByUserId = (variables: DashboardDeleteByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DashboardDeleteByUserIdError,
    undefined,
    DashboardDeleteByUserIdHeaders,
    {},
    DashboardDeleteByUserIdPathParams
  >({ url: '/api/Dashboard/{userId}', method: 'delete', ...variables, signal });

export const useDashboardDeleteByUserId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DashboardDeleteByUserIdError, DashboardDeleteByUserIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DashboardDeleteByUserIdError, DashboardDeleteByUserIdVariables>(
    (variables: DashboardDeleteByUserIdVariables) => fetchDashboardDeleteByUserId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DashboardGetByUserIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type DashboardGetByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type DashboardGetByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type DashboardGetByUserIdVariables = {
  headers?: DashboardGetByUserIdHeaders;
  pathParams: DashboardGetByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardGetByUserId = (variables: DashboardGetByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DashboardGetByUserIdError,
    undefined,
    DashboardGetByUserIdHeaders,
    {},
    DashboardGetByUserIdPathParams
  >({ url: '/api/Dashboard/{userId}', method: 'get', ...variables, signal });

export const useDashboardGetByUserId = <TData = undefined>(
  variables: DashboardGetByUserIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DashboardGetByUserIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DashboardGetByUserIdError, TData>(
    queryKeyFn({ path: '/api/Dashboard/{userId}', operationId: 'dashboardGetByUserId', variables }),
    ({ signal }) => fetchDashboardGetByUserId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DashboardPutByUserIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type DashboardPutByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type DashboardPutByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type DashboardPutByUserIdRequestBody = Schemas.Widget[];

export type DashboardPutByUserIdVariables = {
  body?: DashboardPutByUserIdRequestBody;
  headers?: DashboardPutByUserIdHeaders;
  pathParams: DashboardPutByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDashboardPutByUserId = (variables: DashboardPutByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DashboardPutByUserIdError,
    DashboardPutByUserIdRequestBody,
    DashboardPutByUserIdHeaders,
    {},
    DashboardPutByUserIdPathParams
  >({ url: '/api/Dashboard/{userId}', method: 'put', ...variables, signal });

export const useDashboardPutByUserId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DashboardPutByUserIdError, DashboardPutByUserIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DashboardPutByUserIdError, DashboardPutByUserIdVariables>(
    (variables: DashboardPutByUserIdVariables) => fetchDashboardPutByUserId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DefaultDatesGetByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DefaultDatesGetByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DefaultDatesGetByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type DefaultDatesGetByOrgIdVariables = {
  headers?: DefaultDatesGetByOrgIdHeaders;
  pathParams: DefaultDatesGetByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDefaultDatesGetByOrgId = (variables: DefaultDatesGetByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DatesDto,
    DefaultDatesGetByOrgIdError,
    undefined,
    DefaultDatesGetByOrgIdHeaders,
    {},
    DefaultDatesGetByOrgIdPathParams
  >({ url: '/api/DefaultDates/{orgId}', method: 'get', ...variables, signal });

export const useDefaultDatesGetByOrgId = <TData = Schemas.DatesDto>(
  variables: DefaultDatesGetByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DatesDto, DefaultDatesGetByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DatesDto, DefaultDatesGetByOrgIdError, TData>(
    queryKeyFn({ path: '/api/DefaultDates/{orgId}', operationId: 'defaultDatesGetByOrgId', variables }),
    ({ signal }) => fetchDefaultDatesGetByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetHistoryHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetHistoryResponse = Schemas.TargetDocScoreGoalResponseDto[];

export type DocScoreGoalGetHistoryVariables = {
  headers?: DocScoreGoalGetHistoryHeaders;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetHistory = (variables: DocScoreGoalGetHistoryVariables, signal?: AbortSignal) =>
  apiFetch<
    DocScoreGoalGetHistoryResponse,
    DocScoreGoalGetHistoryError,
    undefined,
    DocScoreGoalGetHistoryHeaders,
    {},
    {}
  >({ url: '/api/DocScoreGoal/History', method: 'get', ...variables, signal });

export const useDocScoreGoalGetHistory = <TData = DocScoreGoalGetHistoryResponse>(
  variables: DocScoreGoalGetHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<DocScoreGoalGetHistoryResponse, DocScoreGoalGetHistoryError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<DocScoreGoalGetHistoryResponse, DocScoreGoalGetHistoryError, TData>(
    queryKeyFn({ path: '/api/DocScoreGoal/History', operationId: 'docScoreGoalGetHistory', variables }),
    ({ signal }) => fetchDocScoreGoalGetHistory({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetHistoryDownloadQueryParams = {
  status?: string;
};

export type DocScoreGoalGetHistoryDownloadHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetHistoryDownloadError = Fetcher.ErrorWrapper<undefined>;

export type DocScoreGoalGetHistoryDownloadVariables = {
  headers?: DocScoreGoalGetHistoryDownloadHeaders;
  queryParams?: DocScoreGoalGetHistoryDownloadQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetHistoryDownload = (
  variables: DocScoreGoalGetHistoryDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DocScoreGoalGetHistoryDownloadError,
    undefined,
    DocScoreGoalGetHistoryDownloadHeaders,
    DocScoreGoalGetHistoryDownloadQueryParams,
    {}
  >({ url: '/api/DocScoreGoal/History/Download', method: 'get', ...variables, signal });

export const useDocScoreGoalGetHistoryDownload = <TData = undefined>(
  variables: DocScoreGoalGetHistoryDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DocScoreGoalGetHistoryDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DocScoreGoalGetHistoryDownloadError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/History/Download',
      operationId: 'docScoreGoalGetHistoryDownload',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetHistoryDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetByHospitalOrgIdPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type DocScoreGoalGetByHospitalOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type DocScoreGoalGetByHospitalOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetByHospitalOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetByHospitalOrgIdResponse = Schemas.PhysicianGroupImprovementGoalsDto[];

export type DocScoreGoalGetByHospitalOrgIdVariables = {
  headers?: DocScoreGoalGetByHospitalOrgIdHeaders;
  pathParams: DocScoreGoalGetByHospitalOrgIdPathParams;
  queryParams?: DocScoreGoalGetByHospitalOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetByHospitalOrgId = (
  variables: DocScoreGoalGetByHospitalOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetByHospitalOrgIdResponse,
    DocScoreGoalGetByHospitalOrgIdError,
    undefined,
    DocScoreGoalGetByHospitalOrgIdHeaders,
    DocScoreGoalGetByHospitalOrgIdQueryParams,
    DocScoreGoalGetByHospitalOrgIdPathParams
  >({ url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetByHospitalOrgId = <TData = DocScoreGoalGetByHospitalOrgIdResponse>(
  variables: DocScoreGoalGetByHospitalOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<DocScoreGoalGetByHospitalOrgIdResponse, DocScoreGoalGetByHospitalOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<DocScoreGoalGetByHospitalOrgIdResponse, DocScoreGoalGetByHospitalOrgIdError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}',
      operationId: 'docScoreGoalGetByHospitalOrgId',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetByHospitalOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format double
   * @default 0
   */
  groupTotalAddressableOpportunityRW?: number;
  /**
   * @default false
   */
  useSavedTargetConds?: boolean;
  targetCondIds?: string;
  /**
   * @format double
   * @default 0
   */
  adjustedTargetDocScore?: number;
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdVariables = {
  headers?: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHeaders;
  pathParams: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdPathParams;
  queryParams?: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgId = (
  variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdError,
    undefined,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHeaders,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdQueryParams,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdPathParams
  >({
    url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgId = <
  TData = Schemas.PhysicianGroupImprovementGoalsDto
>(
  variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PhysicianGroupImprovementGoalsDto,
      DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}',
      operationId: 'docScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgId',
      variables
    }),
    ({ signal }) =>
      fetchDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetQueryParams = {
  csvPhysicianGroupOrgHierarchyIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format double
   * @default 0
   */
  groupTotalAddressableOpportunityRW?: number;
  /**
   * @default false
   */
  useSavedTargetConds?: boolean;
  targetCondIds?: string;
  /**
   * @format double
   * @default 0
   */
  adjustedTargetDocScore?: number;
};

export type DocScoreGoalGetHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetVariables = {
  headers?: DocScoreGoalGetHeaders;
  queryParams?: DocScoreGoalGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGet = (variables: DocScoreGoalGetVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PhysicianGroupImprovementGoalsDto,
    DocScoreGoalGetError,
    undefined,
    DocScoreGoalGetHeaders,
    DocScoreGoalGetQueryParams,
    {}
  >({ url: '/api/DocScoreGoal', method: 'get', ...variables, signal });

export const useDocScoreGoalGet = <TData = Schemas.PhysicianGroupImprovementGoalsDto>(
  variables: DocScoreGoalGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PhysicianGroupImprovementGoalsDto, DocScoreGoalGetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PhysicianGroupImprovementGoalsDto, DocScoreGoalGetError, TData>(
    queryKeyFn({ path: '/api/DocScoreGoal', operationId: 'docScoreGoalGet', variables }),
    ({ signal }) => fetchDocScoreGoalGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalPostQueryParams = {
  /**
   * @default false
   */
  isBulkSave?: boolean;
};

export type DocScoreGoalPostHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalPostError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalPostVariables = {
  body: Schemas.NewTargetDocScoreGoalRequestDto;
  headers?: DocScoreGoalPostHeaders;
  queryParams?: DocScoreGoalPostQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalPost = (variables: DocScoreGoalPostVariables, signal?: AbortSignal) =>
  apiFetch<
    number,
    DocScoreGoalPostError,
    Schemas.NewTargetDocScoreGoalRequestDto,
    DocScoreGoalPostHeaders,
    DocScoreGoalPostQueryParams,
    {}
  >({ url: '/api/DocScoreGoal', method: 'post', ...variables, signal });

export const useDocScoreGoalPost = (
  options?: Omit<reactQuery.UseMutationOptions<number, DocScoreGoalPostError, DocScoreGoalPostVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<number, DocScoreGoalPostError, DocScoreGoalPostVariables>(
    (variables: DocScoreGoalPostVariables) => fetchDocScoreGoalPost({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocScoreGoalPutHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalPutError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalPutVariables = {
  body: Schemas.UpdateTargetDocScoreGoalRequestDto;
  headers?: DocScoreGoalPutHeaders;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalPut = (variables: DocScoreGoalPutVariables, signal?: AbortSignal) =>
  apiFetch<undefined, DocScoreGoalPutError, Schemas.UpdateTargetDocScoreGoalRequestDto, DocScoreGoalPutHeaders, {}, {}>(
    { url: '/api/DocScoreGoal', method: 'put', ...variables, signal }
  );

export const useDocScoreGoalPut = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, DocScoreGoalPutError, DocScoreGoalPutVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DocScoreGoalPutError, DocScoreGoalPutVariables>(
    (variables: DocScoreGoalPutVariables) => fetchDocScoreGoalPut({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DocScoreGoalGetSystemsSpecialtyGroupQueryParams = {
  systemsSpecialtyGroupName?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type DocScoreGoalGetSystemsSpecialtyGroupHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetSystemsSpecialtyGroupError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetSystemsSpecialtyGroupResponse = Schemas.PhysicianGroupImprovementGoalsDto[];

export type DocScoreGoalGetSystemsSpecialtyGroupVariables = {
  headers?: DocScoreGoalGetSystemsSpecialtyGroupHeaders;
  queryParams?: DocScoreGoalGetSystemsSpecialtyGroupQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetSystemsSpecialtyGroup = (
  variables: DocScoreGoalGetSystemsSpecialtyGroupVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetSystemsSpecialtyGroupResponse,
    DocScoreGoalGetSystemsSpecialtyGroupError,
    undefined,
    DocScoreGoalGetSystemsSpecialtyGroupHeaders,
    DocScoreGoalGetSystemsSpecialtyGroupQueryParams,
    {}
  >({ url: '/api/DocScoreGoal/SystemsSpecialtyGroup', method: 'get', ...variables, signal });

export const useDocScoreGoalGetSystemsSpecialtyGroup = <TData = DocScoreGoalGetSystemsSpecialtyGroupResponse>(
  variables: DocScoreGoalGetSystemsSpecialtyGroupVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetSystemsSpecialtyGroupResponse,
      DocScoreGoalGetSystemsSpecialtyGroupError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetSystemsSpecialtyGroupResponse,
    DocScoreGoalGetSystemsSpecialtyGroupError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/SystemsSpecialtyGroup',
      operationId: 'docScoreGoalGetSystemsSpecialtyGroup',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetSystemsSpecialtyGroup({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryResponse =
  Schemas.TargetDocScoreGoalResponseDto[];

export type DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryVariables = {
  headers?: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryHeaders;
  pathParams: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistory = (
  variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryResponse,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryError,
    undefined,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryHeaders,
    {},
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryPathParams
  >({
    url: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/History',
    method: 'get',
    ...variables,
    signal
  });

export const useDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistory = <
  TData = DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryResponse
>(
  variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryResponse,
      DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryResponse,
    DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/History',
      operationId: 'docScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistory',
      variables
    }),
    ({ signal }) =>
      fetchDocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistory({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetSmartGoalsModalByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetSmartGoalsModalByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetSmartGoalsModalByOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetSmartGoalsModalByOrgIdVariables = {
  headers?: DocScoreGoalGetSmartGoalsModalByOrgIdHeaders;
  pathParams: DocScoreGoalGetSmartGoalsModalByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetSmartGoalsModalByOrgId = (
  variables: DocScoreGoalGetSmartGoalsModalByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalModalDto,
    DocScoreGoalGetSmartGoalsModalByOrgIdError,
    undefined,
    DocScoreGoalGetSmartGoalsModalByOrgIdHeaders,
    {},
    DocScoreGoalGetSmartGoalsModalByOrgIdPathParams
  >({ url: '/api/DocScoreGoal/SmartGoalsModal/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetSmartGoalsModalByOrgId = <TData = Schemas.DocScoreGoalModalDto>(
  variables: DocScoreGoalGetSmartGoalsModalByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DocScoreGoalModalDto, DocScoreGoalGetSmartGoalsModalByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DocScoreGoalModalDto, DocScoreGoalGetSmartGoalsModalByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/SmartGoalsModal/{orgId}',
      operationId: 'docScoreGoalGetSmartGoalsModalByOrgId',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetSmartGoalsModalByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetSmartGoalsWidgetByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetSmartGoalsWidgetByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetSmartGoalsWidgetByOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetSmartGoalsWidgetByOrgIdVariables = {
  headers?: DocScoreGoalGetSmartGoalsWidgetByOrgIdHeaders;
  pathParams: DocScoreGoalGetSmartGoalsWidgetByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetSmartGoalsWidgetByOrgId = (
  variables: DocScoreGoalGetSmartGoalsWidgetByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetSmartGoalsWidgetByOrgIdError,
    undefined,
    DocScoreGoalGetSmartGoalsWidgetByOrgIdHeaders,
    {},
    DocScoreGoalGetSmartGoalsWidgetByOrgIdPathParams
  >({ url: '/api/DocScoreGoal/SmartGoalsWidget/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetSmartGoalsWidgetByOrgId = <TData = Schemas.DocScoreGoalPieChartDto>(
  variables: DocScoreGoalGetSmartGoalsWidgetByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DocScoreGoalPieChartDto, DocScoreGoalGetSmartGoalsWidgetByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DocScoreGoalPieChartDto, DocScoreGoalGetSmartGoalsWidgetByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/DocScoreGoal/SmartGoalsWidget/{orgId}',
      operationId: 'docScoreGoalGetSmartGoalsWidgetByOrgId',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetSmartGoalsWidgetByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetTargetedConditionsTableByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetTargetedConditionsTableByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetTargetedConditionsTableByOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetTargetedConditionsTableByOrgIdVariables = {
  headers?: DocScoreGoalGetTargetedConditionsTableByOrgIdHeaders;
  pathParams: DocScoreGoalGetTargetedConditionsTableByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetTargetedConditionsTableByOrgId = (
  variables: DocScoreGoalGetTargetedConditionsTableByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DashboardTargetedConditionsPerfTableDto,
    DocScoreGoalGetTargetedConditionsTableByOrgIdError,
    undefined,
    DocScoreGoalGetTargetedConditionsTableByOrgIdHeaders,
    {},
    DocScoreGoalGetTargetedConditionsTableByOrgIdPathParams
  >({ url: '/api/DocScoreGoal/TargetedConditionsTable/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetTargetedConditionsTableByOrgId = <
  TData = Schemas.DashboardTargetedConditionsPerfTableDto
>(
  variables: DocScoreGoalGetTargetedConditionsTableByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DashboardTargetedConditionsPerfTableDto,
      DocScoreGoalGetTargetedConditionsTableByOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DashboardTargetedConditionsPerfTableDto,
    DocScoreGoalGetTargetedConditionsTableByOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/TargetedConditionsTable/{orgId}',
      operationId: 'docScoreGoalGetTargetedConditionsTableByOrgId',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetTargetedConditionsTableByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalGetTargetedConditionsWidgetByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DocScoreGoalGetTargetedConditionsWidgetByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalGetTargetedConditionsWidgetByOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type DocScoreGoalGetTargetedConditionsWidgetByOrgIdVariables = {
  headers?: DocScoreGoalGetTargetedConditionsWidgetByOrgIdHeaders;
  pathParams: DocScoreGoalGetTargetedConditionsWidgetByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalGetTargetedConditionsWidgetByOrgId = (
  variables: DocScoreGoalGetTargetedConditionsWidgetByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetTargetedConditionsWidgetByOrgIdError,
    undefined,
    DocScoreGoalGetTargetedConditionsWidgetByOrgIdHeaders,
    {},
    DocScoreGoalGetTargetedConditionsWidgetByOrgIdPathParams
  >({ url: '/api/DocScoreGoal/TargetedConditionsWidget/{orgId}', method: 'get', ...variables, signal });

export const useDocScoreGoalGetTargetedConditionsWidgetByOrgId = <TData = Schemas.DocScoreGoalPieChartDto>(
  variables: DocScoreGoalGetTargetedConditionsWidgetByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.DocScoreGoalPieChartDto,
      DocScoreGoalGetTargetedConditionsWidgetByOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.DocScoreGoalPieChartDto,
    DocScoreGoalGetTargetedConditionsWidgetByOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/DocScoreGoal/TargetedConditionsWidget/{orgId}',
      operationId: 'docScoreGoalGetTargetedConditionsWidgetByOrgId',
      variables
    }),
    ({ signal }) => fetchDocScoreGoalGetTargetedConditionsWidgetByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DocScoreGoalDeleteByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DocScoreGoalDeleteByIdHeaders = {
  ['x-api-version']?: string;
};

export type DocScoreGoalDeleteByIdError = Fetcher.ErrorWrapper<undefined>;

export type DocScoreGoalDeleteByIdVariables = {
  headers?: DocScoreGoalDeleteByIdHeaders;
  pathParams: DocScoreGoalDeleteByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchDocScoreGoalDeleteById = (variables: DocScoreGoalDeleteByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DocScoreGoalDeleteByIdError,
    undefined,
    DocScoreGoalDeleteByIdHeaders,
    {},
    DocScoreGoalDeleteByIdPathParams
  >({ url: '/api/DocScoreGoal/{id}', method: 'delete', ...variables, signal });

export const useDocScoreGoalDeleteById = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DocScoreGoalDeleteByIdError, DocScoreGoalDeleteByIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, DocScoreGoalDeleteByIdError, DocScoreGoalDeleteByIdVariables>(
    (variables: DocScoreGoalDeleteByIdVariables) => fetchDocScoreGoalDeleteById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FeaturesGetHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesGetError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesGetVariables = {
  headers?: FeaturesGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesGet = (variables: FeaturesGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, FeaturesGetError, undefined, FeaturesGetHeaders, {}, {}>({
    url: '/api/Features',
    method: 'get',
    ...variables,
    signal
  });

export const useFeaturesGet = <TData = undefined>(
  variables: FeaturesGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FeaturesGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FeaturesGetError, TData>(
    queryKeyFn({ path: '/api/Features', operationId: 'featuresGet', variables }),
    ({ signal }) => fetchFeaturesGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type FeaturesGetByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type FeaturesGetByIdHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesGetByIdError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesGetByIdVariables = {
  headers?: FeaturesGetByIdHeaders;
  pathParams: FeaturesGetByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesGetById = (variables: FeaturesGetByIdVariables, signal?: AbortSignal) =>
  apiFetch<undefined, FeaturesGetByIdError, undefined, FeaturesGetByIdHeaders, {}, FeaturesGetByIdPathParams>({
    url: '/api/Features/{id}',
    method: 'get',
    ...variables,
    signal
  });

export const useFeaturesGetById = <TData = undefined>(
  variables: FeaturesGetByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FeaturesGetByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FeaturesGetByIdError, TData>(
    queryKeyFn({ path: '/api/Features/{id}', operationId: 'featuresGetById', variables }),
    ({ signal }) => fetchFeaturesGetById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type FeaturesPutByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type FeaturesPutByIdHeaders = {
  ['x-api-version']?: string;
};

export type FeaturesPutByIdError = Fetcher.ErrorWrapper<undefined>;

export type FeaturesPutByIdVariables = {
  body?: Schemas.FeatureDto;
  headers?: FeaturesPutByIdHeaders;
  pathParams: FeaturesPutByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchFeaturesPutById = (variables: FeaturesPutByIdVariables, signal?: AbortSignal) =>
  apiFetch<undefined, FeaturesPutByIdError, Schemas.FeatureDto, FeaturesPutByIdHeaders, {}, FeaturesPutByIdPathParams>({
    url: '/api/Features/{id}',
    method: 'put',
    ...variables,
    signal
  });

export const useFeaturesPutById = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, FeaturesPutByIdError, FeaturesPutByIdVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, FeaturesPutByIdError, FeaturesPutByIdVariables>(
    (variables: FeaturesPutByIdVariables) => fetchFeaturesPutById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type FinancialTrackerGetQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  preserveFormulas?: boolean;
  /**
   * @default true
   */
  useDefaultDates?: boolean;
};

export type FinancialTrackerGetHeaders = {
  ['x-api-version']?: string;
};

export type FinancialTrackerGetError = Fetcher.ErrorWrapper<undefined>;

export type FinancialTrackerGetVariables = {
  headers?: FinancialTrackerGetHeaders;
  queryParams?: FinancialTrackerGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchFinancialTrackerGet = (variables: FinancialTrackerGetVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    FinancialTrackerGetError,
    undefined,
    FinancialTrackerGetHeaders,
    FinancialTrackerGetQueryParams,
    {}
  >({ url: '/api/FinancialTracker', method: 'get', ...variables, signal });

export const useFinancialTrackerGet = <TData = undefined>(
  variables: FinancialTrackerGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, FinancialTrackerGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, FinancialTrackerGetError, TData>(
    queryKeyFn({ path: '/api/FinancialTracker', operationId: 'financialTrackerGet', variables }),
    ({ signal }) => fetchFinancialTrackerGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetCmiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetCmiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetCmiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetCmiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetCmiByOrgIdVariables = {
  headers?: GraphGetCmiByOrgIdHeaders;
  pathParams: GraphGetCmiByOrgIdPathParams;
  queryParams?: GraphGetCmiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetCmiByOrgId = (variables: GraphGetCmiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetCmiByOrgIdError,
    undefined,
    GraphGetCmiByOrgIdHeaders,
    GraphGetCmiByOrgIdQueryParams,
    GraphGetCmiByOrgIdPathParams
  >({ url: '/api/Graph/Cmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetCmiByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetCmiByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetCmiByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetCmiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/Cmi/{orgId}', operationId: 'graphGetCmiByOrgId', variables }),
    ({ signal }) => fetchGraphGetCmiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetConditionsByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsByOrgIdVariables = {
  headers?: GraphGetConditionsByOrgIdHeaders;
  pathParams: GraphGetConditionsByOrgIdPathParams;
  queryParams?: GraphGetConditionsByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditionsByOrgId = (variables: GraphGetConditionsByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsByOrgIdError,
    undefined,
    GraphGetConditionsByOrgIdHeaders,
    GraphGetConditionsByOrgIdQueryParams,
    GraphGetConditionsByOrgIdPathParams
  >({ url: '/api/Graph/Conditions/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetConditionsByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/Conditions/{orgId}', operationId: 'graphGetConditionsByOrgId', variables }),
    ({ signal }) => fetchGraphGetConditionsByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsVariables = {
  headers?: GraphGetConditionsHeaders;
  queryParams?: GraphGetConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditions = (variables: GraphGetConditionsVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsError,
    undefined,
    GraphGetConditionsHeaders,
    GraphGetConditionsQueryParams,
    {}
  >({ url: '/api/Graph/Conditions', method: 'get', ...variables, signal });

export const useGraphGetConditions = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsError, TData>(
    queryKeyFn({ path: '/api/Graph/Conditions', operationId: 'graphGetConditions', variables }),
    ({ signal }) => fetchGraphGetConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetConditionsSmartModalByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetConditionsSmartModalByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicStartDate?: string;
  /**
   * @format date-time
   */
  historicEndDate?: string;
  /**
   * @format date-time
   */
  currentStartDate?: string;
  /**
   * @format date-time
   */
  currentEndDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
};

export type GraphGetConditionsSmartModalByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetConditionsSmartModalByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetConditionsSmartModalByOrgIdVariables = {
  headers?: GraphGetConditionsSmartModalByOrgIdHeaders;
  pathParams: GraphGetConditionsSmartModalByOrgIdPathParams;
  queryParams?: GraphGetConditionsSmartModalByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetConditionsSmartModalByOrgId = (
  variables: GraphGetConditionsSmartModalByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetConditionsSmartModalByOrgIdError,
    undefined,
    GraphGetConditionsSmartModalByOrgIdHeaders,
    GraphGetConditionsSmartModalByOrgIdQueryParams,
    GraphGetConditionsSmartModalByOrgIdPathParams
  >({ url: '/api/Graph/ConditionsSmartModal/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetConditionsSmartModalByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetConditionsSmartModalByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetConditionsSmartModalByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetConditionsSmartModalByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Graph/ConditionsSmartModal/{orgId}',
      operationId: 'graphGetConditionsSmartModalByOrgId',
      variables
    }),
    ({ signal }) => fetchGraphGetConditionsSmartModalByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetDocScoreByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetDocScoreByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetDocScoreByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetDocScoreByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetDocScoreByOrgIdVariables = {
  headers?: GraphGetDocScoreByOrgIdHeaders;
  pathParams: GraphGetDocScoreByOrgIdPathParams;
  queryParams?: GraphGetDocScoreByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetDocScoreByOrgId = (variables: GraphGetDocScoreByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetDocScoreByOrgIdError,
    undefined,
    GraphGetDocScoreByOrgIdHeaders,
    GraphGetDocScoreByOrgIdQueryParams,
    GraphGetDocScoreByOrgIdPathParams
  >({ url: '/api/Graph/DocScore/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetDocScoreByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetDocScoreByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetDocScoreByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetDocScoreByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/DocScore/{orgId}', operationId: 'graphGetDocScoreByOrgId', variables }),
    ({ signal }) => fetchGraphGetDocScoreByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetDocScoreQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetDocScoreVariables = {
  headers?: GraphGetDocScoreHeaders;
  queryParams?: GraphGetDocScoreQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetDocScore = (variables: GraphGetDocScoreVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetDocScoreError,
    undefined,
    GraphGetDocScoreHeaders,
    GraphGetDocScoreQueryParams,
    {}
  >({ url: '/api/Graph/DocScore', method: 'get', ...variables, signal });

export const useGraphGetDocScore = <TData = Schemas.GraphDto>(
  variables: GraphGetDocScoreVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetDocScoreError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetDocScoreError, TData>(
    queryKeyFn({ path: '/api/Graph/DocScore', operationId: 'graphGetDocScore', variables }),
    ({ signal }) => fetchGraphGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetElixhauserMortalityByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetElixhauserMortalityByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetElixhauserMortalityByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetElixhauserMortalityByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetElixhauserMortalityByOrgIdVariables = {
  headers?: GraphGetElixhauserMortalityByOrgIdHeaders;
  pathParams: GraphGetElixhauserMortalityByOrgIdPathParams;
  queryParams?: GraphGetElixhauserMortalityByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetElixhauserMortalityByOrgId = (
  variables: GraphGetElixhauserMortalityByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetElixhauserMortalityByOrgIdError,
    undefined,
    GraphGetElixhauserMortalityByOrgIdHeaders,
    GraphGetElixhauserMortalityByOrgIdQueryParams,
    GraphGetElixhauserMortalityByOrgIdPathParams
  >({ url: '/api/Graph/ElixhauserMortality/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetElixhauserMortalityByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetElixhauserMortalityByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetElixhauserMortalityByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetElixhauserMortalityByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Graph/ElixhauserMortality/{orgId}',
      operationId: 'graphGetElixhauserMortalityByOrgId',
      variables
    }),
    ({ signal }) => fetchGraphGetElixhauserMortalityByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetElixhauserReadmissionByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetElixhauserReadmissionByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetElixhauserReadmissionByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetElixhauserReadmissionByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetElixhauserReadmissionByOrgIdVariables = {
  headers?: GraphGetElixhauserReadmissionByOrgIdHeaders;
  pathParams: GraphGetElixhauserReadmissionByOrgIdPathParams;
  queryParams?: GraphGetElixhauserReadmissionByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetElixhauserReadmissionByOrgId = (
  variables: GraphGetElixhauserReadmissionByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetElixhauserReadmissionByOrgIdError,
    undefined,
    GraphGetElixhauserReadmissionByOrgIdHeaders,
    GraphGetElixhauserReadmissionByOrgIdQueryParams,
    GraphGetElixhauserReadmissionByOrgIdPathParams
  >({ url: '/api/Graph/ElixhauserReadmission/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetElixhauserReadmissionByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetElixhauserReadmissionByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetElixhauserReadmissionByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetElixhauserReadmissionByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Graph/ElixhauserReadmission/{orgId}',
      operationId: 'graphGetElixhauserReadmissionByOrgId',
      variables
    }),
    ({ signal }) => fetchGraphGetElixhauserReadmissionByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetHcupCmiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetHcupCmiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetHcupCmiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetHcupCmiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetHcupCmiByOrgIdVariables = {
  headers?: GraphGetHcupCmiByOrgIdHeaders;
  pathParams: GraphGetHcupCmiByOrgIdPathParams;
  queryParams?: GraphGetHcupCmiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetHcupCmiByOrgId = (variables: GraphGetHcupCmiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetHcupCmiByOrgIdError,
    undefined,
    GraphGetHcupCmiByOrgIdHeaders,
    GraphGetHcupCmiByOrgIdQueryParams,
    GraphGetHcupCmiByOrgIdPathParams
  >({ url: '/api/Graph/HcupCmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetHcupCmiByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetHcupCmiByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetHcupCmiByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetHcupCmiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/HcupCmi/{orgId}', operationId: 'graphGetHcupCmiByOrgId', variables }),
    ({ signal }) => fetchGraphGetHcupCmiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetLosByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetLosByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetLosByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetLosByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetLosByOrgIdVariables = {
  headers?: GraphGetLosByOrgIdHeaders;
  pathParams: GraphGetLosByOrgIdPathParams;
  queryParams?: GraphGetLosByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetLosByOrgId = (variables: GraphGetLosByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetLosByOrgIdError,
    undefined,
    GraphGetLosByOrgIdHeaders,
    GraphGetLosByOrgIdQueryParams,
    GraphGetLosByOrgIdPathParams
  >({ url: '/api/Graph/Los/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetLosByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetLosByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetLosByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetLosByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/Los/{orgId}', operationId: 'graphGetLosByOrgId', variables }),
    ({ signal }) => fetchGraphGetLosByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetPsiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetPsiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetPsiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetPsiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetPsiByOrgIdVariables = {
  headers?: GraphGetPsiByOrgIdHeaders;
  pathParams: GraphGetPsiByOrgIdPathParams;
  queryParams?: GraphGetPsiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetPsiByOrgId = (variables: GraphGetPsiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetPsiByOrgIdError,
    undefined,
    GraphGetPsiByOrgIdHeaders,
    GraphGetPsiByOrgIdQueryParams,
    GraphGetPsiByOrgIdPathParams
  >({ url: '/api/Graph/Psi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetPsiByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetPsiByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetPsiByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetPsiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/Psi/{orgId}', operationId: 'graphGetPsiByOrgId', variables }),
    ({ signal }) => fetchGraphGetPsiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetRafByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetRafByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetRafByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetRafByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetRafByOrgIdVariables = {
  headers?: GraphGetRafByOrgIdHeaders;
  pathParams: GraphGetRafByOrgIdPathParams;
  queryParams?: GraphGetRafByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetRafByOrgId = (variables: GraphGetRafByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetRafByOrgIdError,
    undefined,
    GraphGetRafByOrgIdHeaders,
    GraphGetRafByOrgIdQueryParams,
    GraphGetRafByOrgIdPathParams
  >({ url: '/api/Graph/Raf/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetRafByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetRafByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetRafByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetRafByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/Raf/{orgId}', operationId: 'graphGetRafByOrgId', variables }),
    ({ signal }) => fetchGraphGetRafByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetSeverityCmiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type GraphGetSeverityCmiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type GraphGetSeverityCmiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetSeverityCmiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type GraphGetSeverityCmiByOrgIdVariables = {
  headers?: GraphGetSeverityCmiByOrgIdHeaders;
  pathParams: GraphGetSeverityCmiByOrgIdPathParams;
  queryParams?: GraphGetSeverityCmiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetSeverityCmiByOrgId = (variables: GraphGetSeverityCmiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.GraphDto,
    GraphGetSeverityCmiByOrgIdError,
    undefined,
    GraphGetSeverityCmiByOrgIdHeaders,
    GraphGetSeverityCmiByOrgIdQueryParams,
    GraphGetSeverityCmiByOrgIdPathParams
  >({ url: '/api/Graph/SeverityCmi/{orgId}', method: 'get', ...variables, signal });

export const useGraphGetSeverityCmiByOrgId = <TData = Schemas.GraphDto>(
  variables: GraphGetSeverityCmiByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GraphDto, GraphGetSeverityCmiByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GraphDto, GraphGetSeverityCmiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Graph/SeverityCmi/{orgId}', operationId: 'graphGetSeverityCmiByOrgId', variables }),
    ({ signal }) => fetchGraphGetSeverityCmiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type GraphGetSpeedometerHeaders = {
  ['x-api-version']?: string;
};

export type GraphGetSpeedometerError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type GraphGetSpeedometerResponse = Schemas.DocScoreSpeedometerDto[];

export type GraphGetSpeedometerVariables = {
  headers?: GraphGetSpeedometerHeaders;
} & ApiContext['fetcherOptions'];

export const fetchGraphGetSpeedometer = (variables: GraphGetSpeedometerVariables, signal?: AbortSignal) =>
  apiFetch<GraphGetSpeedometerResponse, GraphGetSpeedometerError, undefined, GraphGetSpeedometerHeaders, {}, {}>({
    url: '/api/Graph/Speedometer',
    method: 'get',
    ...variables,
    signal
  });

export const useGraphGetSpeedometer = <TData = GraphGetSpeedometerResponse>(
  variables: GraphGetSpeedometerVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GraphGetSpeedometerResponse, GraphGetSpeedometerError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<GraphGetSpeedometerResponse, GraphGetSpeedometerError, TData>(
    queryKeyFn({ path: '/api/Graph/Speedometer', operationId: 'graphGetSpeedometer', variables }),
    ({ signal }) => fetchGraphGetSpeedometer({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetGraphByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DownloadGetGraphByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  monthRange?: string;
  /**
   * @default CMI, LOS, SeverityCMI, DocScore, RAF
   */
  csvViewList?: string;
  /**
   * optional, which items are selected on the LOS table and any COVID inclusions. Full list
   * looks like cmiCOVID,docScoreCOVID,severityCmiCOVID,Actual,HCUPAvg,CEHCUPAvg,Gap
   *
   * @default HCUPAvg,CEHCUPAvg
   */
  metricViewList?: string;
};

export type DownloadGetGraphByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetGraphByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetGraphByOrgIdVariables = {
  headers?: DownloadGetGraphByOrgIdHeaders;
  pathParams: DownloadGetGraphByOrgIdPathParams;
  queryParams?: DownloadGetGraphByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetGraphByOrgId = (variables: DownloadGetGraphByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DownloadGetGraphByOrgIdError,
    undefined,
    DownloadGetGraphByOrgIdHeaders,
    DownloadGetGraphByOrgIdQueryParams,
    DownloadGetGraphByOrgIdPathParams
  >({ url: '/api/Download/Graph/{orgId}', method: 'get', ...variables, signal });

export const useDownloadGetGraphByOrgId = <TData = undefined>(
  variables: DownloadGetGraphByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DownloadGetGraphByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetGraphByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Download/Graph/{orgId}', operationId: 'downloadGetGraphByOrgId', variables }),
    ({ signal }) => fetchDownloadGetGraphByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetGraphConditionsByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DownloadGetGraphConditionsByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  /**
   * @format int32
   */
  conditionId?: number;
  monthRange?: string;
  /**
   * optional, default value false
   *
   * @default false
   */
  isOtoCE?: boolean;
  /**
   * optional, which items are selected in the table (not applicable when IsOtoCE = true). Full
   * list is Secondary,CESecondary,Total,SecondarySingle,AvgImpactSingle
   *
   * @default Secondary,CESecondary
   */
  metricViewList?: string;
};

export type DownloadGetGraphConditionsByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetGraphConditionsByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetGraphConditionsByOrgIdVariables = {
  headers?: DownloadGetGraphConditionsByOrgIdHeaders;
  pathParams: DownloadGetGraphConditionsByOrgIdPathParams;
  queryParams?: DownloadGetGraphConditionsByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetGraphConditionsByOrgId = (
  variables: DownloadGetGraphConditionsByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetGraphConditionsByOrgIdError,
    undefined,
    DownloadGetGraphConditionsByOrgIdHeaders,
    DownloadGetGraphConditionsByOrgIdQueryParams,
    DownloadGetGraphConditionsByOrgIdPathParams
  >({ url: '/api/Download/Graph/Conditions/{orgId}', method: 'get', ...variables, signal });

export const useDownloadGetGraphConditionsByOrgId = <TData = undefined>(
  variables: DownloadGetGraphConditionsByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetGraphConditionsByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetGraphConditionsByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Graph/Conditions/{orgId}',
      operationId: 'downloadGetGraphConditionsByOrgId',
      variables
    }),
    ({ signal }) => fetchDownloadGetGraphConditionsByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsPostGroupMonthlyByPhysicianGroupIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type ReportsPostGroupMonthlyByPhysicianGroupIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostGroupMonthlyByPhysicianGroupIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostGroupMonthlyByPhysicianGroupIdVariables = {
  body?: Schemas.RequestMonthlyReportRequest;
  headers?: ReportsPostGroupMonthlyByPhysicianGroupIdHeaders;
  pathParams: ReportsPostGroupMonthlyByPhysicianGroupIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostGroupMonthlyByPhysicianGroupId = (
  variables: ReportsPostGroupMonthlyByPhysicianGroupIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsPostGroupMonthlyByPhysicianGroupIdError,
    Schemas.RequestMonthlyReportRequest,
    ReportsPostGroupMonthlyByPhysicianGroupIdHeaders,
    {},
    ReportsPostGroupMonthlyByPhysicianGroupIdPathParams
  >({ url: '/api/Reports/Group/Monthly/{physicianGroupId}', method: 'post', ...variables, signal });

export const useReportsPostGroupMonthlyByPhysicianGroupId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReportsPostGroupMonthlyByPhysicianGroupIdError,
      ReportsPostGroupMonthlyByPhysicianGroupIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReportsPostGroupMonthlyByPhysicianGroupIdError,
    ReportsPostGroupMonthlyByPhysicianGroupIdVariables
  >(
    (variables: ReportsPostGroupMonthlyByPhysicianGroupIdVariables) =>
      fetchReportsPostGroupMonthlyByPhysicianGroupId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsPostGroupQuarterlyByPhysicianGroupIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type ReportsPostGroupQuarterlyByPhysicianGroupIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostGroupQuarterlyByPhysicianGroupIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostGroupQuarterlyByPhysicianGroupIdVariables = {
  body?: Schemas.RequestQuarterlyReportRequest;
  headers?: ReportsPostGroupQuarterlyByPhysicianGroupIdHeaders;
  pathParams: ReportsPostGroupQuarterlyByPhysicianGroupIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostGroupQuarterlyByPhysicianGroupId = (
  variables: ReportsPostGroupQuarterlyByPhysicianGroupIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsPostGroupQuarterlyByPhysicianGroupIdError,
    Schemas.RequestQuarterlyReportRequest,
    ReportsPostGroupQuarterlyByPhysicianGroupIdHeaders,
    {},
    ReportsPostGroupQuarterlyByPhysicianGroupIdPathParams
  >({ url: '/api/Reports/Group/Quarterly/{physicianGroupId}', method: 'post', ...variables, signal });

export const useReportsPostGroupQuarterlyByPhysicianGroupId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReportsPostGroupQuarterlyByPhysicianGroupIdError,
      ReportsPostGroupQuarterlyByPhysicianGroupIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReportsPostGroupQuarterlyByPhysicianGroupIdError,
    ReportsPostGroupQuarterlyByPhysicianGroupIdVariables
  >(
    (variables: ReportsPostGroupQuarterlyByPhysicianGroupIdVariables) =>
      fetchReportsPostGroupQuarterlyByPhysicianGroupId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsPostGroupQuarterlySummaryByPhysicianGroupIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupId: number;
};

export type ReportsPostGroupQuarterlySummaryByPhysicianGroupIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostGroupQuarterlySummaryByPhysicianGroupIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostGroupQuarterlySummaryByPhysicianGroupIdVariables = {
  body?: Schemas.RequestQuarterlyReportRequest;
  headers?: ReportsPostGroupQuarterlySummaryByPhysicianGroupIdHeaders;
  pathParams: ReportsPostGroupQuarterlySummaryByPhysicianGroupIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostGroupQuarterlySummaryByPhysicianGroupId = (
  variables: ReportsPostGroupQuarterlySummaryByPhysicianGroupIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsPostGroupQuarterlySummaryByPhysicianGroupIdError,
    Schemas.RequestQuarterlyReportRequest,
    ReportsPostGroupQuarterlySummaryByPhysicianGroupIdHeaders,
    {},
    ReportsPostGroupQuarterlySummaryByPhysicianGroupIdPathParams
  >({ url: '/api/Reports/Group/QuarterlySummary/{physicianGroupId}', method: 'post', ...variables, signal });

export const useReportsPostGroupQuarterlySummaryByPhysicianGroupId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReportsPostGroupQuarterlySummaryByPhysicianGroupIdError,
      ReportsPostGroupQuarterlySummaryByPhysicianGroupIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    ReportsPostGroupQuarterlySummaryByPhysicianGroupIdError,
    ReportsPostGroupQuarterlySummaryByPhysicianGroupIdVariables
  >(
    (variables: ReportsPostGroupQuarterlySummaryByPhysicianGroupIdVariables) =>
      fetchReportsPostGroupQuarterlySummaryByPhysicianGroupId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsGetGroupPhysicianGroupByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ReportsGetGroupPhysicianGroupByIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetGroupPhysicianGroupByIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetGroupPhysicianGroupByIdVariables = {
  headers?: ReportsGetGroupPhysicianGroupByIdHeaders;
  pathParams: ReportsGetGroupPhysicianGroupByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetGroupPhysicianGroupById = (
  variables: ReportsGetGroupPhysicianGroupByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.GroupReportDto,
    ReportsGetGroupPhysicianGroupByIdError,
    undefined,
    ReportsGetGroupPhysicianGroupByIdHeaders,
    {},
    ReportsGetGroupPhysicianGroupByIdPathParams
  >({ url: '/api/Reports/Group/PhysicianGroup/{id}', method: 'get', ...variables, signal });

export const useReportsGetGroupPhysicianGroupById = <TData = Schemas.GroupReportDto>(
  variables: ReportsGetGroupPhysicianGroupByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.GroupReportDto, ReportsGetGroupPhysicianGroupByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.GroupReportDto, ReportsGetGroupPhysicianGroupByIdError, TData>(
    queryKeyFn({
      path: '/api/Reports/Group/PhysicianGroup/{id}',
      operationId: 'reportsGetGroupPhysicianGroupById',
      variables
    }),
    ({ signal }) => fetchReportsGetGroupPhysicianGroupById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetGroupByReportIdPathParams = {
  /**
   * @format int32
   */
  reportId: number;
};

export type ReportsGetGroupByReportIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetGroupByReportIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetGroupByReportIdVariables = {
  headers?: ReportsGetGroupByReportIdHeaders;
  pathParams: ReportsGetGroupByReportIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetGroupByReportId = (variables: ReportsGetGroupByReportIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ReportsGetGroupByReportIdError,
    undefined,
    ReportsGetGroupByReportIdHeaders,
    {},
    ReportsGetGroupByReportIdPathParams
  >({ url: '/api/Reports/Group/{reportId}', method: 'get', ...variables, signal });

export const useReportsGetGroupByReportId = <TData = undefined>(
  variables: ReportsGetGroupByReportIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, ReportsGetGroupByReportIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ReportsGetGroupByReportIdError, TData>(
    queryKeyFn({ path: '/api/Reports/Group/{reportId}', operationId: 'reportsGetGroupByReportId', variables }),
    ({ signal }) => fetchReportsGetGroupByReportId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsPostGroupUploadAndSendHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostGroupUploadAndSendError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostGroupUploadAndSendRequestBody = {
  GroupReportFileList?: Blob[];
  /**
   * @format int32
   */
  PhysicianGroupId?: number;
  /**
   * @format date-time
   */
  ReportDate?: string;
  ReportTypeIds?: number[];
};

export type ReportsPostGroupUploadAndSendVariables = {
  body?: ReportsPostGroupUploadAndSendRequestBody;
  headers?: ReportsPostGroupUploadAndSendHeaders;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostGroupUploadAndSend = (
  variables: ReportsPostGroupUploadAndSendVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsPostGroupUploadAndSendError,
    ReportsPostGroupUploadAndSendRequestBody,
    ReportsPostGroupUploadAndSendHeaders,
    {},
    {}
  >({ url: '/api/Reports/Group/UploadAndSend', method: 'post', ...variables, signal });

export const useReportsPostGroupUploadAndSend = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      ReportsPostGroupUploadAndSendError,
      ReportsPostGroupUploadAndSendVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ReportsPostGroupUploadAndSendError, ReportsPostGroupUploadAndSendVariables>(
    (variables: ReportsPostGroupUploadAndSendVariables) =>
      fetchReportsPostGroupUploadAndSend({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsPostGroupUploadHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostGroupUploadError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostGroupUploadRequestBody = {
  /**
   * @format binary
   */
  GroupReportFile: Blob;
  /**
   * @format int32
   */
  PhysicianGroupId?: number;
  /**
   * @format date-time
   */
  ReportDate?: string;
  /**
   * @format int32
   */
  ReportTypeId?: number;
};

export type ReportsPostGroupUploadVariables = {
  body: ReportsPostGroupUploadRequestBody;
  headers?: ReportsPostGroupUploadHeaders;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostGroupUpload = (variables: ReportsPostGroupUploadVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ReportsPostGroupUploadError,
    ReportsPostGroupUploadRequestBody,
    ReportsPostGroupUploadHeaders,
    {},
    {}
  >({ url: '/api/Reports/Group/Upload', method: 'post', ...variables, signal });

export const useReportsPostGroupUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ReportsPostGroupUploadError, ReportsPostGroupUploadVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ReportsPostGroupUploadError, ReportsPostGroupUploadVariables>(
    (variables: ReportsPostGroupUploadVariables) => fetchReportsPostGroupUpload({ ...fetcherOptions, ...variables }),
    options
  );
};

export type HealthGetHeaders = {
  ['x-api-version']?: string;
};

export type HealthGetError = Fetcher.ErrorWrapper<undefined>;

export type HealthGetVariables = {
  headers?: HealthGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchHealthGet = (variables: HealthGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, HealthGetError, undefined, HealthGetHeaders, {}, {}>({
    url: '/api/Health',
    method: 'get',
    ...variables,
    signal
  });

export const useHealthGet = <TData = undefined>(
  variables: HealthGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, HealthGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, HealthGetError, TData>(
    queryKeyFn({ path: '/api/Health', operationId: 'healthGet', variables }),
    ({ signal }) => fetchHealthGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetQueryParams = {
  /**
   * @format int32
   */
  ItemCount?: number;
  /**
   * @format int32
   */
  PageNumber?: number;
  Search?: string;
};

export type HospitalsGetHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type HospitalsGetVariables = {
  headers?: HospitalsGetHeaders;
  queryParams?: HospitalsGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGet = (variables: HospitalsGetVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelPagedHospital,
    HospitalsGetError,
    undefined,
    HospitalsGetHeaders,
    HospitalsGetQueryParams,
    {}
  >({ url: '/api/Hospitals', method: 'get', ...variables, signal });

export const useHospitalsGet = <TData = Schemas.DomainModelPagedHospital>(
  variables: HospitalsGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelPagedHospital, HospitalsGetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelPagedHospital, HospitalsGetError, TData>(
    queryKeyFn({ path: '/api/Hospitals', operationId: 'hospitalsGet', variables }),
    ({ signal }) => fetchHospitalsGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetHospitalsWithSystemHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetHospitalsWithSystemError = Fetcher.ErrorWrapper<undefined>;

export type HospitalsGetHospitalsWithSystemVariables = {
  headers?: HospitalsGetHospitalsWithSystemHeaders;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetHospitalsWithSystem = (
  variables: HospitalsGetHospitalsWithSystemVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, HospitalsGetHospitalsWithSystemError, undefined, HospitalsGetHospitalsWithSystemHeaders, {}, {}>({
    url: '/api/Hospitals/HospitalsWithSystem',
    method: 'get',
    ...variables,
    signal
  });

export const useHospitalsGetHospitalsWithSystem = <TData = undefined>(
  variables: HospitalsGetHospitalsWithSystemVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, HospitalsGetHospitalsWithSystemError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, HospitalsGetHospitalsWithSystemError, TData>(
    queryKeyFn({
      path: '/api/Hospitals/HospitalsWithSystem',
      operationId: 'hospitalsGetHospitalsWithSystem',
      variables
    }),
    ({ signal }) => fetchHospitalsGetHospitalsWithSystem({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type HospitalsGetByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type HospitalsGetByIdQueryParams = {
  /**
   * @format int32
   * @default 0
   */
  includeAllGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeHiddenSpecialtyGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeOtherGroups?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeOtherMDs?: number;
  /**
   * @format int32
   * @default 0
   */
  excludeHiddenProviders?: number;
};

export type HospitalsGetByIdHeaders = {
  ['x-api-version']?: string;
};

export type HospitalsGetByIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type HospitalsGetByIdVariables = {
  headers?: HospitalsGetByIdHeaders;
  pathParams: HospitalsGetByIdPathParams;
  queryParams?: HospitalsGetByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchHospitalsGetById = (variables: HospitalsGetByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelHospitalWithPhysicianGroup,
    HospitalsGetByIdError,
    undefined,
    HospitalsGetByIdHeaders,
    HospitalsGetByIdQueryParams,
    HospitalsGetByIdPathParams
  >({ url: '/api/Hospitals/{id}', method: 'get', ...variables, signal });

export const useHospitalsGetById = <TData = Schemas.DomainModelHospitalWithPhysicianGroup>(
  variables: HospitalsGetByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelHospitalWithPhysicianGroup, HospitalsGetByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelHospitalWithPhysicianGroup, HospitalsGetByIdError, TData>(
    queryKeyFn({ path: '/api/Hospitals/{id}', operationId: 'hospitalsGetById', variables }),
    ({ signal }) => fetchHospitalsGetById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MdcGetHeaders = {
  ['x-api-version']?: string;
};

export type MdcGetError = Fetcher.ErrorWrapper<undefined>;

export type MdcGetResponse = Schemas.DomainModelMdc[];

export type MdcGetVariables = {
  headers?: MdcGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMdcGet = (variables: MdcGetVariables, signal?: AbortSignal) =>
  apiFetch<MdcGetResponse, MdcGetError, undefined, MdcGetHeaders, {}, {}>({
    url: '/api/Mdc',
    method: 'get',
    ...variables,
    signal
  });

export const useMdcGet = <TData = MdcGetResponse>(
  variables: MdcGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<MdcGetResponse, MdcGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MdcGetResponse, MdcGetError, TData>(
    queryKeyFn({ path: '/api/Mdc', operationId: 'mdcGet', variables }),
    ({ signal }) => fetchMdcGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MdcGetByMdcIdPathParams = {
  /**
   * @format int32
   */
  mdcId: number;
};

export type MdcGetByMdcIdHeaders = {
  ['x-api-version']?: string;
};

export type MdcGetByMdcIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MdcGetByMdcIdVariables = {
  headers?: MdcGetByMdcIdHeaders;
  pathParams: MdcGetByMdcIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMdcGetByMdcId = (variables: MdcGetByMdcIdVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.DomainModelMdc, MdcGetByMdcIdError, undefined, MdcGetByMdcIdHeaders, {}, MdcGetByMdcIdPathParams>({
    url: '/api/Mdc/{mdcId}',
    method: 'get',
    ...variables,
    signal
  });

export const useMdcGetByMdcId = <TData = Schemas.DomainModelMdc>(
  variables: MdcGetByMdcIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.DomainModelMdc, MdcGetByMdcIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelMdc, MdcGetByMdcIdError, TData>(
    queryKeyFn({ path: '/api/Mdc/{mdcId}', operationId: 'mdcGetByMdcId', variables }),
    ({ signal }) => fetchMdcGetByMdcId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetCmiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetCmiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetCmiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetCmiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetCmiByOrgIdResponse = Schemas.CmiDto[];

export type MetricsGetCmiByOrgIdVariables = {
  headers?: MetricsGetCmiByOrgIdHeaders;
  pathParams: MetricsGetCmiByOrgIdPathParams;
  queryParams?: MetricsGetCmiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetCmiByOrgId = (variables: MetricsGetCmiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetCmiByOrgIdResponse,
    MetricsGetCmiByOrgIdError,
    undefined,
    MetricsGetCmiByOrgIdHeaders,
    MetricsGetCmiByOrgIdQueryParams,
    MetricsGetCmiByOrgIdPathParams
  >({ url: '/api/Metrics/Cmi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetCmiByOrgId = <TData = MetricsGetCmiByOrgIdResponse>(
  variables: MetricsGetCmiByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetCmiByOrgIdResponse, MetricsGetCmiByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetCmiByOrgIdResponse, MetricsGetCmiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/Cmi/{orgId}', operationId: 'metricsGetCmiByOrgId', variables }),
    ({ signal }) => fetchMetricsGetCmiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetConditionsByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetConditionsByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * @default false
   */
  sortByGapVsCe?: boolean;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if
   * not sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetConditionsByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsByOrgIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsGetConditionsByOrgIdVariables = {
  headers?: MetricsGetConditionsByOrgIdHeaders;
  pathParams: MetricsGetConditionsByOrgIdPathParams;
  queryParams?: MetricsGetConditionsByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditionsByOrgId = (
  variables: MetricsGetConditionsByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ConditionMetricConditionsMetricDto,
    MetricsGetConditionsByOrgIdError,
    undefined,
    MetricsGetConditionsByOrgIdHeaders,
    MetricsGetConditionsByOrgIdQueryParams,
    MetricsGetConditionsByOrgIdPathParams
  >({ url: '/api/Metrics/Conditions/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetConditionsByOrgId = <TData = Schemas.ConditionMetricConditionsMetricDto>(
  variables: MetricsGetConditionsByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConditionMetricConditionsMetricDto, MetricsGetConditionsByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ConditionMetricConditionsMetricDto, MetricsGetConditionsByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/Conditions/{orgId}', operationId: 'metricsGetConditionsByOrgId', variables }),
    ({ signal }) => fetchMetricsGetConditionsByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsPostConditionsByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsPostConditionsByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsPostConditionsByOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsPostConditionsByOrgIdVariables = {
  body: Schemas.TargetConditionsDto;
  headers?: MetricsPostConditionsByOrgIdHeaders;
  pathParams: MetricsPostConditionsByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsPostConditionsByOrgId = (
  variables: MetricsPostConditionsByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsPostConditionsByOrgIdError,
    Schemas.TargetConditionsDto,
    MetricsPostConditionsByOrgIdHeaders,
    {},
    MetricsPostConditionsByOrgIdPathParams
  >({ url: '/api/Metrics/Conditions/{orgId}', method: 'post', ...variables, signal });

export const useMetricsPostConditionsByOrgId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, MetricsPostConditionsByOrgIdError, MetricsPostConditionsByOrgIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, MetricsPostConditionsByOrgIdError, MetricsPostConditionsByOrgIdVariables>(
    (variables: MetricsPostConditionsByOrgIdVariables) =>
      fetchMetricsPostConditionsByOrgId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MetricsGetConditionsQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * @default false
   */
  sortByGapVsCe?: boolean;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsGetConditionsVariables = {
  headers?: MetricsGetConditionsHeaders;
  queryParams?: MetricsGetConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditions = (variables: MetricsGetConditionsVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.ConditionMetricConditionsMetricDto,
    MetricsGetConditionsError,
    undefined,
    MetricsGetConditionsHeaders,
    MetricsGetConditionsQueryParams,
    {}
  >({ url: '/api/Metrics/Conditions', method: 'get', ...variables, signal });

export const useMetricsGetConditions = <TData = Schemas.ConditionMetricConditionsMetricDto>(
  variables: MetricsGetConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ConditionMetricConditionsMetricDto, MetricsGetConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ConditionMetricConditionsMetricDto, MetricsGetConditionsError, TData>(
    queryKeyFn({ path: '/api/Metrics/Conditions', operationId: 'metricsGetConditions', variables }),
    ({ signal }) => fetchMetricsGetConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDocScoreByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDocScoreByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  forDashboard?: boolean;
};

export type MetricsGetDocScoreByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDocScoreByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDocScoreByOrgIdResponse = Schemas.OEMetricDto[];

export type MetricsGetDocScoreByOrgIdVariables = {
  headers?: MetricsGetDocScoreByOrgIdHeaders;
  pathParams: MetricsGetDocScoreByOrgIdPathParams;
  queryParams?: MetricsGetDocScoreByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDocScoreByOrgId = (variables: MetricsGetDocScoreByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetDocScoreByOrgIdResponse,
    MetricsGetDocScoreByOrgIdError,
    undefined,
    MetricsGetDocScoreByOrgIdHeaders,
    MetricsGetDocScoreByOrgIdQueryParams,
    MetricsGetDocScoreByOrgIdPathParams
  >({ url: '/api/Metrics/DocScore/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDocScoreByOrgId = <TData = MetricsGetDocScoreByOrgIdResponse>(
  variables: MetricsGetDocScoreByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetDocScoreByOrgIdResponse, MetricsGetDocScoreByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetDocScoreByOrgIdResponse, MetricsGetDocScoreByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/DocScore/{orgId}', operationId: 'metricsGetDocScoreByOrgId', variables }),
    ({ signal }) => fetchMetricsGetDocScoreByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDrgMixByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDrgMixByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetDrgMixByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDrgMixByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDrgMixByOrgIdVariables = {
  headers?: MetricsGetDrgMixByOrgIdHeaders;
  pathParams: MetricsGetDrgMixByOrgIdPathParams;
  queryParams?: MetricsGetDrgMixByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDrgMixByOrgId = (variables: MetricsGetDrgMixByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PatientMixAnalysisDto,
    MetricsGetDrgMixByOrgIdError,
    undefined,
    MetricsGetDrgMixByOrgIdHeaders,
    MetricsGetDrgMixByOrgIdQueryParams,
    MetricsGetDrgMixByOrgIdPathParams
  >({ url: '/api/Metrics/DrgMix/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDrgMixByOrgId = <TData = Schemas.PatientMixAnalysisDto>(
  variables: MetricsGetDrgMixByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PatientMixAnalysisDto, MetricsGetDrgMixByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PatientMixAnalysisDto, MetricsGetDrgMixByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/DrgMix/{orgId}', operationId: 'metricsGetDrgMixByOrgId', variables }),
    ({ signal }) => fetchMetricsGetDrgMixByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetDrgMixComparisonByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetDrgMixComparisonByOrgIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type MetricsGetDrgMixComparisonByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetDrgMixComparisonByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetDrgMixComparisonByOrgIdVariables = {
  headers?: MetricsGetDrgMixComparisonByOrgIdHeaders;
  pathParams: MetricsGetDrgMixComparisonByOrgIdPathParams;
  queryParams?: MetricsGetDrgMixComparisonByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetDrgMixComparisonByOrgId = (
  variables: MetricsGetDrgMixComparisonByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DrgMixComparisonDto,
    MetricsGetDrgMixComparisonByOrgIdError,
    undefined,
    MetricsGetDrgMixComparisonByOrgIdHeaders,
    MetricsGetDrgMixComparisonByOrgIdQueryParams,
    MetricsGetDrgMixComparisonByOrgIdPathParams
  >({ url: '/api/Metrics/DrgMixComparison/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetDrgMixComparisonByOrgId = <TData = Schemas.DrgMixComparisonDto>(
  variables: MetricsGetDrgMixComparisonByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DrgMixComparisonDto, MetricsGetDrgMixComparisonByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DrgMixComparisonDto, MetricsGetDrgMixComparisonByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Metrics/DrgMixComparison/{orgId}',
      operationId: 'metricsGetDrgMixComparisonByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetDrgMixComparisonByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetElixhauserMortalityByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetElixhauserMortalityByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetElixhauserMortalityByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetElixhauserMortalityByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetElixhauserMortalityByOrgIdResponse = Schemas.OEMetricDto[];

export type MetricsGetElixhauserMortalityByOrgIdVariables = {
  headers?: MetricsGetElixhauserMortalityByOrgIdHeaders;
  pathParams: MetricsGetElixhauserMortalityByOrgIdPathParams;
  queryParams?: MetricsGetElixhauserMortalityByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetElixhauserMortalityByOrgId = (
  variables: MetricsGetElixhauserMortalityByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetElixhauserMortalityByOrgIdResponse,
    MetricsGetElixhauserMortalityByOrgIdError,
    undefined,
    MetricsGetElixhauserMortalityByOrgIdHeaders,
    MetricsGetElixhauserMortalityByOrgIdQueryParams,
    MetricsGetElixhauserMortalityByOrgIdPathParams
  >({ url: '/api/Metrics/ElixhauserMortality/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetElixhauserMortalityByOrgId = <TData = MetricsGetElixhauserMortalityByOrgIdResponse>(
  variables: MetricsGetElixhauserMortalityByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      MetricsGetElixhauserMortalityByOrgIdResponse,
      MetricsGetElixhauserMortalityByOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    MetricsGetElixhauserMortalityByOrgIdResponse,
    MetricsGetElixhauserMortalityByOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Metrics/ElixhauserMortality/{orgId}',
      operationId: 'metricsGetElixhauserMortalityByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetElixhauserMortalityByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetElixhauserReadmissionByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetElixhauserReadmissionByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetElixhauserReadmissionByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetElixhauserReadmissionByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetElixhauserReadmissionByOrgIdResponse = Schemas.OEMetricDto[];

export type MetricsGetElixhauserReadmissionByOrgIdVariables = {
  headers?: MetricsGetElixhauserReadmissionByOrgIdHeaders;
  pathParams: MetricsGetElixhauserReadmissionByOrgIdPathParams;
  queryParams?: MetricsGetElixhauserReadmissionByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetElixhauserReadmissionByOrgId = (
  variables: MetricsGetElixhauserReadmissionByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetElixhauserReadmissionByOrgIdResponse,
    MetricsGetElixhauserReadmissionByOrgIdError,
    undefined,
    MetricsGetElixhauserReadmissionByOrgIdHeaders,
    MetricsGetElixhauserReadmissionByOrgIdQueryParams,
    MetricsGetElixhauserReadmissionByOrgIdPathParams
  >({ url: '/api/Metrics/ElixhauserReadmission/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetElixhauserReadmissionByOrgId = <TData = MetricsGetElixhauserReadmissionByOrgIdResponse>(
  variables: MetricsGetElixhauserReadmissionByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      MetricsGetElixhauserReadmissionByOrgIdResponse,
      MetricsGetElixhauserReadmissionByOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    MetricsGetElixhauserReadmissionByOrgIdResponse,
    MetricsGetElixhauserReadmissionByOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Metrics/ElixhauserReadmission/{orgId}',
      operationId: 'metricsGetElixhauserReadmissionByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetElixhauserReadmissionByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetLosByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetLosByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetLosByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetLosByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetLosByOrgIdResponse = Schemas.LosDto[];

export type MetricsGetLosByOrgIdVariables = {
  headers?: MetricsGetLosByOrgIdHeaders;
  pathParams: MetricsGetLosByOrgIdPathParams;
  queryParams?: MetricsGetLosByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetLosByOrgId = (variables: MetricsGetLosByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetLosByOrgIdResponse,
    MetricsGetLosByOrgIdError,
    undefined,
    MetricsGetLosByOrgIdHeaders,
    MetricsGetLosByOrgIdQueryParams,
    MetricsGetLosByOrgIdPathParams
  >({ url: '/api/Metrics/Los/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetLosByOrgId = <TData = MetricsGetLosByOrgIdResponse>(
  variables: MetricsGetLosByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetLosByOrgIdResponse, MetricsGetLosByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetLosByOrgIdResponse, MetricsGetLosByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/Los/{orgId}', operationId: 'metricsGetLosByOrgId', variables }),
    ({ signal }) => fetchMetricsGetLosByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetConditionsSharesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetConditionsSharesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsSharesByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetConditionsSharesByOrgIdVariables = {
  headers?: MetricsGetConditionsSharesByOrgIdHeaders;
  pathParams: MetricsGetConditionsSharesByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditionsSharesByOrgId = (
  variables: MetricsGetConditionsSharesByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsGetConditionsSharesByOrgIdError,
    undefined,
    MetricsGetConditionsSharesByOrgIdHeaders,
    {},
    MetricsGetConditionsSharesByOrgIdPathParams
  >({ url: '/api/Metrics/Conditions/Shares/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetConditionsSharesByOrgId = <TData = Schemas.DomainModelOpportunityShare>(
  variables: MetricsGetConditionsSharesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOpportunityShare, MetricsGetConditionsSharesByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOpportunityShare, MetricsGetConditionsSharesByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Metrics/Conditions/Shares/{orgId}',
      operationId: 'metricsGetConditionsSharesByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetConditionsSharesByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetConditionsSharesHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetConditionsSharesError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetConditionsSharesVariables = {
  headers?: MetricsGetConditionsSharesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetConditionsShares = (variables: MetricsGetConditionsSharesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsGetConditionsSharesError,
    undefined,
    MetricsGetConditionsSharesHeaders,
    {},
    {}
  >({ url: '/api/Metrics/Conditions/Shares', method: 'get', ...variables, signal });

export const useMetricsGetConditionsShares = <TData = Schemas.DomainModelOpportunityShare>(
  variables: MetricsGetConditionsSharesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelOpportunityShare, MetricsGetConditionsSharesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelOpportunityShare, MetricsGetConditionsSharesError, TData>(
    queryKeyFn({ path: '/api/Metrics/Conditions/Shares', operationId: 'metricsGetConditionsShares', variables }),
    ({ signal }) => fetchMetricsGetConditionsShares({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsPutConditionsSharesHeaders = {
  ['x-api-version']?: string;
};

export type MetricsPutConditionsSharesError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsPutConditionsSharesVariables = {
  body: Schemas.OpportunityShareDto;
  headers?: MetricsPutConditionsSharesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsPutConditionsShares = (variables: MetricsPutConditionsSharesVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.DomainModelOpportunityShare,
    MetricsPutConditionsSharesError,
    Schemas.OpportunityShareDto,
    MetricsPutConditionsSharesHeaders,
    {},
    {}
  >({ url: '/api/Metrics/Conditions/Shares', method: 'put', ...variables, signal });

export const useMetricsPutConditionsShares = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.DomainModelOpportunityShare,
      MetricsPutConditionsSharesError,
      MetricsPutConditionsSharesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.DomainModelOpportunityShare,
    MetricsPutConditionsSharesError,
    MetricsPutConditionsSharesVariables
  >(
    (variables: MetricsPutConditionsSharesVariables) =>
      fetchMetricsPutConditionsShares({ ...fetcherOptions, ...variables }),
    options
  );
};

export type MetricsGetOverallPerformanceQueryParams = {
  csvOrgIds?: string;
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @default false
   */
  includeChildren?: boolean;
  /**
   * @default false
   */
  aggregateOrgIds?: boolean;
};

export type MetricsGetOverallPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetOverallPerformanceError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetOverallPerformanceResponse = Schemas.OverallPerformanceDto[];

export type MetricsGetOverallPerformanceVariables = {
  headers?: MetricsGetOverallPerformanceHeaders;
  queryParams?: MetricsGetOverallPerformanceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetOverallPerformance = (
  variables: MetricsGetOverallPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetOverallPerformanceResponse,
    MetricsGetOverallPerformanceError,
    undefined,
    MetricsGetOverallPerformanceHeaders,
    MetricsGetOverallPerformanceQueryParams,
    {}
  >({ url: '/api/Metrics/OverallPerformance', method: 'get', ...variables, signal });

export const useMetricsGetOverallPerformance = <TData = MetricsGetOverallPerformanceResponse>(
  variables: MetricsGetOverallPerformanceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetOverallPerformanceResponse, MetricsGetOverallPerformanceError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetOverallPerformanceResponse, MetricsGetOverallPerformanceError, TData>(
    queryKeyFn({ path: '/api/Metrics/OverallPerformance', operationId: 'metricsGetOverallPerformance', variables }),
    ({ signal }) => fetchMetricsGetOverallPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetPsiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetPsiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetPsiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetPsiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetPsiByOrgIdResponse = Schemas.OEMetricDto[];

export type MetricsGetPsiByOrgIdVariables = {
  headers?: MetricsGetPsiByOrgIdHeaders;
  pathParams: MetricsGetPsiByOrgIdPathParams;
  queryParams?: MetricsGetPsiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetPsiByOrgId = (variables: MetricsGetPsiByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetPsiByOrgIdResponse,
    MetricsGetPsiByOrgIdError,
    undefined,
    MetricsGetPsiByOrgIdHeaders,
    MetricsGetPsiByOrgIdQueryParams,
    MetricsGetPsiByOrgIdPathParams
  >({ url: '/api/Metrics/Psi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetPsiByOrgId = <TData = MetricsGetPsiByOrgIdResponse>(
  variables: MetricsGetPsiByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetPsiByOrgIdResponse, MetricsGetPsiByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetPsiByOrgIdResponse, MetricsGetPsiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/Psi/{orgId}', operationId: 'metricsGetPsiByOrgId', variables }),
    ({ signal }) => fetchMetricsGetPsiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetPsiPiesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetPsiPiesByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConditions?: number;
  targetConditionIds?: string;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if not
   * sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * optional, but all 4 must be sent together. It will check db for a preset for the org if
   * not sent.
   *
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
};

export type MetricsGetPsiPiesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetPsiPiesByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetPsiPiesByOrgIdResponse = Schemas.PsiIndicator[];

export type MetricsGetPsiPiesByOrgIdVariables = {
  headers?: MetricsGetPsiPiesByOrgIdHeaders;
  pathParams: MetricsGetPsiPiesByOrgIdPathParams;
  queryParams?: MetricsGetPsiPiesByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetPsiPiesByOrgId = (variables: MetricsGetPsiPiesByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetPsiPiesByOrgIdResponse,
    MetricsGetPsiPiesByOrgIdError,
    undefined,
    MetricsGetPsiPiesByOrgIdHeaders,
    MetricsGetPsiPiesByOrgIdQueryParams,
    MetricsGetPsiPiesByOrgIdPathParams
  >({ url: '/api/Metrics/PsiPies/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetPsiPiesByOrgId = <TData = MetricsGetPsiPiesByOrgIdResponse>(
  variables: MetricsGetPsiPiesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetPsiPiesByOrgIdResponse, MetricsGetPsiPiesByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetPsiPiesByOrgIdResponse, MetricsGetPsiPiesByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/PsiPies/{orgId}', operationId: 'metricsGetPsiPiesByOrgId', variables }),
    ({ signal }) => fetchMetricsGetPsiPiesByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetRafByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetRafByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetRafByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetRafByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetRafByOrgIdResponse = Schemas.OEMetricDto[];

export type MetricsGetRafByOrgIdVariables = {
  headers?: MetricsGetRafByOrgIdHeaders;
  pathParams: MetricsGetRafByOrgIdPathParams;
  queryParams?: MetricsGetRafByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetRafByOrgId = (variables: MetricsGetRafByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    MetricsGetRafByOrgIdResponse,
    MetricsGetRafByOrgIdError,
    undefined,
    MetricsGetRafByOrgIdHeaders,
    MetricsGetRafByOrgIdQueryParams,
    MetricsGetRafByOrgIdPathParams
  >({ url: '/api/Metrics/Raf/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetRafByOrgId = <TData = MetricsGetRafByOrgIdResponse>(
  variables: MetricsGetRafByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetRafByOrgIdResponse, MetricsGetRafByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetRafByOrgIdResponse, MetricsGetRafByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/Raf/{orgId}', operationId: 'metricsGetRafByOrgId', variables }),
    ({ signal }) => fetchMetricsGetRafByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetSeverityCmiByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetSeverityCmiByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetSeverityCmiByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetSeverityCmiByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetSeverityCmiByOrgIdResponse = Schemas.SeverityCmiDto[];

export type MetricsGetSeverityCmiByOrgIdVariables = {
  headers?: MetricsGetSeverityCmiByOrgIdHeaders;
  pathParams: MetricsGetSeverityCmiByOrgIdPathParams;
  queryParams?: MetricsGetSeverityCmiByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetSeverityCmiByOrgId = (
  variables: MetricsGetSeverityCmiByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    MetricsGetSeverityCmiByOrgIdResponse,
    MetricsGetSeverityCmiByOrgIdError,
    undefined,
    MetricsGetSeverityCmiByOrgIdHeaders,
    MetricsGetSeverityCmiByOrgIdQueryParams,
    MetricsGetSeverityCmiByOrgIdPathParams
  >({ url: '/api/Metrics/SeverityCmi/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetSeverityCmiByOrgId = <TData = MetricsGetSeverityCmiByOrgIdResponse>(
  variables: MetricsGetSeverityCmiByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<MetricsGetSeverityCmiByOrgIdResponse, MetricsGetSeverityCmiByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<MetricsGetSeverityCmiByOrgIdResponse, MetricsGetSeverityCmiByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Metrics/SeverityCmi/{orgId}', operationId: 'metricsGetSeverityCmiByOrgId', variables }),
    ({ signal }) => fetchMetricsGetSeverityCmiByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetSeverityCmiConditionsByOrgIdPathParams = {
  /**
   * org hierarchy ID
   *
   * @format int32
   */
  orgId: number;
};

export type MetricsGetSeverityCmiConditionsByOrgIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
};

export type MetricsGetSeverityCmiConditionsByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetSeverityCmiConditionsByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetSeverityCmiConditionsByOrgIdVariables = {
  headers?: MetricsGetSeverityCmiConditionsByOrgIdHeaders;
  pathParams: MetricsGetSeverityCmiConditionsByOrgIdPathParams;
  queryParams?: MetricsGetSeverityCmiConditionsByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetSeverityCmiConditionsByOrgId = (
  variables: MetricsGetSeverityCmiConditionsByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.SeverityCmiImpactDto,
    MetricsGetSeverityCmiConditionsByOrgIdError,
    undefined,
    MetricsGetSeverityCmiConditionsByOrgIdHeaders,
    MetricsGetSeverityCmiConditionsByOrgIdQueryParams,
    MetricsGetSeverityCmiConditionsByOrgIdPathParams
  >({ url: '/api/Metrics/SeverityCmiConditions/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetSeverityCmiConditionsByOrgId = <TData = Schemas.SeverityCmiImpactDto>(
  variables: MetricsGetSeverityCmiConditionsByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SeverityCmiImpactDto, MetricsGetSeverityCmiConditionsByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.SeverityCmiImpactDto, MetricsGetSeverityCmiConditionsByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Metrics/SeverityCmiConditions/{orgId}',
      operationId: 'metricsGetSeverityCmiConditionsByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetSeverityCmiConditionsByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsGetSeverityCmiComparisonByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type MetricsGetSeverityCmiComparisonByOrgIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type MetricsGetSeverityCmiComparisonByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type MetricsGetSeverityCmiComparisonByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type MetricsGetSeverityCmiComparisonByOrgIdVariables = {
  headers?: MetricsGetSeverityCmiComparisonByOrgIdHeaders;
  pathParams: MetricsGetSeverityCmiComparisonByOrgIdPathParams;
  queryParams?: MetricsGetSeverityCmiComparisonByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchMetricsGetSeverityCmiComparisonByOrgId = (
  variables: MetricsGetSeverityCmiComparisonByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.SeverityCmiComparisonDto,
    MetricsGetSeverityCmiComparisonByOrgIdError,
    undefined,
    MetricsGetSeverityCmiComparisonByOrgIdHeaders,
    MetricsGetSeverityCmiComparisonByOrgIdQueryParams,
    MetricsGetSeverityCmiComparisonByOrgIdPathParams
  >({ url: '/api/Metrics/SeverityCmiComparison/{orgId}', method: 'get', ...variables, signal });

export const useMetricsGetSeverityCmiComparisonByOrgId = <TData = Schemas.SeverityCmiComparisonDto>(
  variables: MetricsGetSeverityCmiComparisonByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.SeverityCmiComparisonDto, MetricsGetSeverityCmiComparisonByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.SeverityCmiComparisonDto, MetricsGetSeverityCmiComparisonByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Metrics/SeverityCmiComparison/{orgId}',
      operationId: 'metricsGetSeverityCmiComparisonByOrgId',
      variables
    }),
    ({ signal }) => fetchMetricsGetSeverityCmiComparisonByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type MetricsPutConditionsRebuildSystemViewTargetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type MetricsPutConditionsRebuildSystemViewTargetConditionsError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type MetricsPutConditionsRebuildSystemViewTargetConditionsVariables = {
  headers?: MetricsPutConditionsRebuildSystemViewTargetConditionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchMetricsPutConditionsRebuildSystemViewTargetConditions = (
  variables: MetricsPutConditionsRebuildSystemViewTargetConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    MetricsPutConditionsRebuildSystemViewTargetConditionsError,
    undefined,
    MetricsPutConditionsRebuildSystemViewTargetConditionsHeaders,
    {},
    {}
  >({ url: '/api/Metrics/Conditions/RebuildSystemViewTargetConditions', method: 'put', ...variables, signal });

export const useMetricsPutConditionsRebuildSystemViewTargetConditions = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      MetricsPutConditionsRebuildSystemViewTargetConditionsError,
      MetricsPutConditionsRebuildSystemViewTargetConditionsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    MetricsPutConditionsRebuildSystemViewTargetConditionsError,
    MetricsPutConditionsRebuildSystemViewTargetConditionsVariables
  >(
    (variables: MetricsPutConditionsRebuildSystemViewTargetConditionsVariables) =>
      fetchMetricsPutConditionsRebuildSystemViewTargetConditions({ ...fetcherOptions, ...variables }),
    options
  );
};

export type DownloadGetMetricsPatientMixAnalysisReportByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type DownloadGetMetricsPatientMixAnalysisReportByOrgIdQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Historical date range start date
   *
   * @format date-time
   */
  historicalStart?: string;
  /**
   * Historical date range end date
   *
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * Current date range start date
   *
   * @format date-time
   */
  currentStart?: string;
  /**
   * Current date range end date
   *
   * @format date-time
   */
  currentEnd?: string;
};

export type DownloadGetMetricsPatientMixAnalysisReportByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsPatientMixAnalysisReportByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsPatientMixAnalysisReportByOrgIdVariables = {
  headers?: DownloadGetMetricsPatientMixAnalysisReportByOrgIdHeaders;
  pathParams: DownloadGetMetricsPatientMixAnalysisReportByOrgIdPathParams;
  queryParams?: DownloadGetMetricsPatientMixAnalysisReportByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsPatientMixAnalysisReportByOrgId = (
  variables: DownloadGetMetricsPatientMixAnalysisReportByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsPatientMixAnalysisReportByOrgIdError,
    undefined,
    DownloadGetMetricsPatientMixAnalysisReportByOrgIdHeaders,
    DownloadGetMetricsPatientMixAnalysisReportByOrgIdQueryParams,
    DownloadGetMetricsPatientMixAnalysisReportByOrgIdPathParams
  >({ url: '/api/Download/Metrics/PatientMixAnalysisReport/{orgId}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsPatientMixAnalysisReportByOrgId = <TData = undefined>(
  variables: DownloadGetMetricsPatientMixAnalysisReportByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsPatientMixAnalysisReportByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsPatientMixAnalysisReportByOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/PatientMixAnalysisReport/{orgId}',
      operationId: 'downloadGetMetricsPatientMixAnalysisReportByOrgId',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsPatientMixAnalysisReportByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams = {
  /**
   * First org selected
   *
   * @format int32
   */
  orgId: number;
  /**
   * Comparison org selected
   *
   * @format int32
   */
  compareToOrgId: number;
};

export type DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Comparison range start date
   *
   * @format date-time
   */
  rangeStart?: string;
  /**
   * Comparison range end date
   *
   * @format date-time
   */
  rangeEnd?: string;
};

export type DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables = {
  headers?: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders;
  pathParams: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams;
  queryParams?: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgId = (
  variables: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError,
    undefined,
    DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders,
    DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams,
    DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams
  >({ url: '/api/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgId = <TData = undefined>(
  variables: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}',
      operationId: 'downloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgId',
      variables
    }),
    ({ signal }) =>
      fetchDownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsCmiByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsCmiByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsCmiByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsCmiByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsCmiByIdVariables = {
  headers?: DownloadGetMetricsCmiByIdHeaders;
  pathParams: DownloadGetMetricsCmiByIdPathParams;
  queryParams?: DownloadGetMetricsCmiByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsCmiById = (variables: DownloadGetMetricsCmiByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DownloadGetMetricsCmiByIdError,
    undefined,
    DownloadGetMetricsCmiByIdHeaders,
    DownloadGetMetricsCmiByIdQueryParams,
    DownloadGetMetricsCmiByIdPathParams
  >({ url: '/api/Download/Metrics/Cmi/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsCmiById = <TData = undefined>(
  variables: DownloadGetMetricsCmiByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DownloadGetMetricsCmiByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsCmiByIdError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/Cmi/{id}', operationId: 'downloadGetMetricsCmiById', variables }),
    ({ signal }) => fetchDownloadGetMetricsCmiById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsConditionsByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsConditionsByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
  /**
   * @format int32
   * @default 0
   */
  conditionId?: number;
  /**
   * @format int32
   * @default 0
   */
  includeAllConditions?: number;
  /**
   * @format int32
   * @default 0
   */
  useSavedTargetConds?: number;
  targetConditionIds?: string;
  /**
   * @format int32
   * @default 1
   */
  shareDrg?: number;
  /**
   * @format int32
   * @default 1
   */
  shareHcc?: number;
  /**
   * @format int32
   * @default 1
   */
  shareMortality?: number;
  /**
   * @format int32
   * @default 1
   */
  shareReadmission?: number;
  /**
   * @format int32
   * @default 1
   */
  sharePsi?: number;
  mdcIds?: string;
  conditionTypeIds?: string;
  /**
   * @format int32
   * @default -1
   */
  adjPsi2Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi4Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi7Weight?: number;
  /**
   * @format int32
   * @default -1
   */
  adjPsi90Weight?: number;
};

export type DownloadGetMetricsConditionsByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsConditionsByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsConditionsByIdVariables = {
  headers?: DownloadGetMetricsConditionsByIdHeaders;
  pathParams: DownloadGetMetricsConditionsByIdPathParams;
  queryParams?: DownloadGetMetricsConditionsByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsConditionsById = (
  variables: DownloadGetMetricsConditionsByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsConditionsByIdError,
    undefined,
    DownloadGetMetricsConditionsByIdHeaders,
    DownloadGetMetricsConditionsByIdQueryParams,
    DownloadGetMetricsConditionsByIdPathParams
  >({ url: '/api/Download/Metrics/Conditions/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsConditionsById = <TData = undefined>(
  variables: DownloadGetMetricsConditionsByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsConditionsByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsConditionsByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/Conditions/{id}',
      operationId: 'downloadGetMetricsConditionsById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsConditionsById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsDocScoreByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsDocScoreByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsDocScoreByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsDocScoreByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsDocScoreByIdVariables = {
  headers?: DownloadGetMetricsDocScoreByIdHeaders;
  pathParams: DownloadGetMetricsDocScoreByIdPathParams;
  queryParams?: DownloadGetMetricsDocScoreByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsDocScoreById = (
  variables: DownloadGetMetricsDocScoreByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsDocScoreByIdError,
    undefined,
    DownloadGetMetricsDocScoreByIdHeaders,
    DownloadGetMetricsDocScoreByIdQueryParams,
    DownloadGetMetricsDocScoreByIdPathParams
  >({ url: '/api/Download/Metrics/DocScore/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsDocScoreById = <TData = undefined>(
  variables: DownloadGetMetricsDocScoreByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsDocScoreByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsDocScoreByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/DocScore/{id}',
      operationId: 'downloadGetMetricsDocScoreById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsDocScoreById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsDrgMixComparisonByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsDrgMixComparisonByIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type DownloadGetMetricsDrgMixComparisonByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsDrgMixComparisonByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsDrgMixComparisonByIdVariables = {
  headers?: DownloadGetMetricsDrgMixComparisonByIdHeaders;
  pathParams: DownloadGetMetricsDrgMixComparisonByIdPathParams;
  queryParams?: DownloadGetMetricsDrgMixComparisonByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsDrgMixComparisonById = (
  variables: DownloadGetMetricsDrgMixComparisonByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsDrgMixComparisonByIdError,
    undefined,
    DownloadGetMetricsDrgMixComparisonByIdHeaders,
    DownloadGetMetricsDrgMixComparisonByIdQueryParams,
    DownloadGetMetricsDrgMixComparisonByIdPathParams
  >({ url: '/api/Download/Metrics/DrgMixComparison/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsDrgMixComparisonById = <TData = undefined>(
  variables: DownloadGetMetricsDrgMixComparisonByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsDrgMixComparisonByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsDrgMixComparisonByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/DrgMixComparison/{id}',
      operationId: 'downloadGetMetricsDrgMixComparisonById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsDrgMixComparisonById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsElixhauserMortalityByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsElixhauserMortalityByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsElixhauserMortalityByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsElixhauserMortalityByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsElixhauserMortalityByIdVariables = {
  headers?: DownloadGetMetricsElixhauserMortalityByIdHeaders;
  pathParams: DownloadGetMetricsElixhauserMortalityByIdPathParams;
  queryParams?: DownloadGetMetricsElixhauserMortalityByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsElixhauserMortalityById = (
  variables: DownloadGetMetricsElixhauserMortalityByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsElixhauserMortalityByIdError,
    undefined,
    DownloadGetMetricsElixhauserMortalityByIdHeaders,
    DownloadGetMetricsElixhauserMortalityByIdQueryParams,
    DownloadGetMetricsElixhauserMortalityByIdPathParams
  >({ url: '/api/Download/Metrics/ElixhauserMortality/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsElixhauserMortalityById = <TData = undefined>(
  variables: DownloadGetMetricsElixhauserMortalityByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsElixhauserMortalityByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsElixhauserMortalityByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/ElixhauserMortality/{id}',
      operationId: 'downloadGetMetricsElixhauserMortalityById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsElixhauserMortalityById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsElixhauserReadmissionByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsElixhauserReadmissionByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsElixhauserReadmissionByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsElixhauserReadmissionByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsElixhauserReadmissionByIdVariables = {
  headers?: DownloadGetMetricsElixhauserReadmissionByIdHeaders;
  pathParams: DownloadGetMetricsElixhauserReadmissionByIdPathParams;
  queryParams?: DownloadGetMetricsElixhauserReadmissionByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsElixhauserReadmissionById = (
  variables: DownloadGetMetricsElixhauserReadmissionByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsElixhauserReadmissionByIdError,
    undefined,
    DownloadGetMetricsElixhauserReadmissionByIdHeaders,
    DownloadGetMetricsElixhauserReadmissionByIdQueryParams,
    DownloadGetMetricsElixhauserReadmissionByIdPathParams
  >({ url: '/api/Download/Metrics/ElixhauserReadmission/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsElixhauserReadmissionById = <TData = undefined>(
  variables: DownloadGetMetricsElixhauserReadmissionByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsElixhauserReadmissionByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsElixhauserReadmissionByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/ElixhauserReadmission/{id}',
      operationId: 'downloadGetMetricsElixhauserReadmissionById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsElixhauserReadmissionById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsLosByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsLosByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsLosByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsLosByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsLosByIdVariables = {
  headers?: DownloadGetMetricsLosByIdHeaders;
  pathParams: DownloadGetMetricsLosByIdPathParams;
  queryParams?: DownloadGetMetricsLosByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsLosById = (variables: DownloadGetMetricsLosByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DownloadGetMetricsLosByIdError,
    undefined,
    DownloadGetMetricsLosByIdHeaders,
    DownloadGetMetricsLosByIdQueryParams,
    DownloadGetMetricsLosByIdPathParams
  >({ url: '/api/Download/Metrics/Los/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsLosById = <TData = undefined>(
  variables: DownloadGetMetricsLosByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DownloadGetMetricsLosByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsLosByIdError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/Los/{id}', operationId: 'downloadGetMetricsLosById', variables }),
    ({ signal }) => fetchDownloadGetMetricsLosById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsPatientMixAnalysisByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsPatientMixAnalysisByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type DownloadGetMetricsPatientMixAnalysisByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsPatientMixAnalysisByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsPatientMixAnalysisByIdVariables = {
  headers?: DownloadGetMetricsPatientMixAnalysisByIdHeaders;
  pathParams: DownloadGetMetricsPatientMixAnalysisByIdPathParams;
  queryParams?: DownloadGetMetricsPatientMixAnalysisByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsPatientMixAnalysisById = (
  variables: DownloadGetMetricsPatientMixAnalysisByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsPatientMixAnalysisByIdError,
    undefined,
    DownloadGetMetricsPatientMixAnalysisByIdHeaders,
    DownloadGetMetricsPatientMixAnalysisByIdQueryParams,
    DownloadGetMetricsPatientMixAnalysisByIdPathParams
  >({ url: '/api/Download/Metrics/PatientMixAnalysis/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsPatientMixAnalysisById = <TData = undefined>(
  variables: DownloadGetMetricsPatientMixAnalysisByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsPatientMixAnalysisByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsPatientMixAnalysisByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/PatientMixAnalysis/{id}',
      operationId: 'downloadGetMetricsPatientMixAnalysisById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsPatientMixAnalysisById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsPsiByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsPsiByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsPsiByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsPsiByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsPsiByIdVariables = {
  headers?: DownloadGetMetricsPsiByIdHeaders;
  pathParams: DownloadGetMetricsPsiByIdPathParams;
  queryParams?: DownloadGetMetricsPsiByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsPsiById = (variables: DownloadGetMetricsPsiByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DownloadGetMetricsPsiByIdError,
    undefined,
    DownloadGetMetricsPsiByIdHeaders,
    DownloadGetMetricsPsiByIdQueryParams,
    DownloadGetMetricsPsiByIdPathParams
  >({ url: '/api/Download/Metrics/Psi/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsPsiById = <TData = undefined>(
  variables: DownloadGetMetricsPsiByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DownloadGetMetricsPsiByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsPsiByIdError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/Psi/{id}', operationId: 'downloadGetMetricsPsiById', variables }),
    ({ signal }) => fetchDownloadGetMetricsPsiById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsRafByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsRafByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsRafByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsRafByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsRafByIdVariables = {
  headers?: DownloadGetMetricsRafByIdHeaders;
  pathParams: DownloadGetMetricsRafByIdPathParams;
  queryParams?: DownloadGetMetricsRafByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsRafById = (variables: DownloadGetMetricsRafByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    DownloadGetMetricsRafByIdError,
    undefined,
    DownloadGetMetricsRafByIdHeaders,
    DownloadGetMetricsRafByIdQueryParams,
    DownloadGetMetricsRafByIdPathParams
  >({ url: '/api/Download/Metrics/Raf/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsRafById = <TData = undefined>(
  variables: DownloadGetMetricsRafByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, DownloadGetMetricsRafByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsRafByIdError, TData>(
    queryKeyFn({ path: '/api/Download/Metrics/Raf/{id}', operationId: 'downloadGetMetricsRafById', variables }),
    ({ signal }) => fetchDownloadGetMetricsRafById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsSeverityCmiByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsSeverityCmiByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  viewName?: string;
  sortColumn?: string;
  /**
   * @default false
   */
  sortDescending?: boolean;
};

export type DownloadGetMetricsSeverityCmiByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsSeverityCmiByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsSeverityCmiByIdVariables = {
  headers?: DownloadGetMetricsSeverityCmiByIdHeaders;
  pathParams: DownloadGetMetricsSeverityCmiByIdPathParams;
  queryParams?: DownloadGetMetricsSeverityCmiByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsSeverityCmiById = (
  variables: DownloadGetMetricsSeverityCmiByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsSeverityCmiByIdError,
    undefined,
    DownloadGetMetricsSeverityCmiByIdHeaders,
    DownloadGetMetricsSeverityCmiByIdQueryParams,
    DownloadGetMetricsSeverityCmiByIdPathParams
  >({ url: '/api/Download/Metrics/SeverityCmi/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsSeverityCmiById = <TData = undefined>(
  variables: DownloadGetMetricsSeverityCmiByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsSeverityCmiByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsSeverityCmiByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/SeverityCmi/{id}',
      operationId: 'downloadGetMetricsSeverityCmiById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsSeverityCmiById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type DownloadGetMetricsSeverityCmiConditionImpactByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type DownloadGetMetricsSeverityCmiConditionImpactByIdQueryParams = {
  /**
   * @format date-time
   */
  historicalStart?: string;
  /**
   * @format date-time
   */
  historicalEnd?: string;
  /**
   * @format date-time
   */
  currentStart?: string;
  /**
   * @format date-time
   */
  currentEnd?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type DownloadGetMetricsSeverityCmiConditionImpactByIdHeaders = {
  ['x-api-version']?: string;
};

export type DownloadGetMetricsSeverityCmiConditionImpactByIdError = Fetcher.ErrorWrapper<undefined>;

export type DownloadGetMetricsSeverityCmiConditionImpactByIdVariables = {
  headers?: DownloadGetMetricsSeverityCmiConditionImpactByIdHeaders;
  pathParams: DownloadGetMetricsSeverityCmiConditionImpactByIdPathParams;
  queryParams?: DownloadGetMetricsSeverityCmiConditionImpactByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchDownloadGetMetricsSeverityCmiConditionImpactById = (
  variables: DownloadGetMetricsSeverityCmiConditionImpactByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    DownloadGetMetricsSeverityCmiConditionImpactByIdError,
    undefined,
    DownloadGetMetricsSeverityCmiConditionImpactByIdHeaders,
    DownloadGetMetricsSeverityCmiConditionImpactByIdQueryParams,
    DownloadGetMetricsSeverityCmiConditionImpactByIdPathParams
  >({ url: '/api/Download/Metrics/SeverityCmiConditionImpact/{id}', method: 'get', ...variables, signal });

export const useDownloadGetMetricsSeverityCmiConditionImpactById = <TData = undefined>(
  variables: DownloadGetMetricsSeverityCmiConditionImpactByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, DownloadGetMetricsSeverityCmiConditionImpactByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, DownloadGetMetricsSeverityCmiConditionImpactByIdError, TData>(
    queryKeyFn({
      path: '/api/Download/Metrics/SeverityCmiConditionImpact/{id}',
      operationId: 'downloadGetMetricsSeverityCmiConditionImpactById',
      variables
    }),
    ({ signal }) => fetchDownloadGetMetricsSeverityCmiConditionImpactById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type OrgGetByOrgIdQueryParams = {
  /**
   * @default false
   */
  includeLineage?: boolean;
  /**
   * @default false
   */
  includeHiddenNodes?: boolean;
  /**
   * @default true
   */
  includeOtherMds?: boolean;
};

export type OrgGetByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetByOrgIdVariables = {
  headers?: OrgGetByOrgIdHeaders;
  pathParams: OrgGetByOrgIdPathParams;
  queryParams?: OrgGetByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetByOrgId = (variables: OrgGetByOrgIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.OrgDto,
    OrgGetByOrgIdError,
    undefined,
    OrgGetByOrgIdHeaders,
    OrgGetByOrgIdQueryParams,
    OrgGetByOrgIdPathParams
  >({ url: '/api/Org/{orgId}', method: 'get', ...variables, signal });

export const useOrgGetByOrgId = <TData = Schemas.OrgDto>(
  variables: OrgGetByOrgIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Schemas.OrgDto, OrgGetByOrgIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.OrgDto, OrgGetByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Org/{orgId}', operationId: 'orgGetByOrgId', variables }),
    ({ signal }) => fetchOrgGetByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetFundamentalGroupsHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetFundamentalGroupsError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetFundamentalGroupsResponse = Schemas.FundamentalSpecialty[];

export type OrgGetFundamentalGroupsVariables = {
  headers?: OrgGetFundamentalGroupsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetFundamentalGroups = (variables: OrgGetFundamentalGroupsVariables, signal?: AbortSignal) =>
  apiFetch<
    OrgGetFundamentalGroupsResponse,
    OrgGetFundamentalGroupsError,
    undefined,
    OrgGetFundamentalGroupsHeaders,
    {},
    {}
  >({ url: '/api/Org/FundamentalGroups', method: 'get', ...variables, signal });

export const useOrgGetFundamentalGroups = <TData = OrgGetFundamentalGroupsResponse>(
  variables: OrgGetFundamentalGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<OrgGetFundamentalGroupsResponse, OrgGetFundamentalGroupsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<OrgGetFundamentalGroupsResponse, OrgGetFundamentalGroupsError, TData>(
    queryKeyFn({ path: '/api/Org/FundamentalGroups', operationId: 'orgGetFundamentalGroups', variables }),
    ({ signal }) => fetchOrgGetFundamentalGroups({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetSystemViewByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type OrgGetSystemViewByIdHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetSystemViewByIdError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetSystemViewByIdVariables = {
  headers?: OrgGetSystemViewByIdHeaders;
  pathParams: OrgGetSystemViewByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgGetSystemViewById = (variables: OrgGetSystemViewByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    OrgGetSystemViewByIdError,
    undefined,
    OrgGetSystemViewByIdHeaders,
    {},
    OrgGetSystemViewByIdPathParams
  >({ url: '/api/Org/SystemView/{id}', method: 'get', ...variables, signal });

export const useOrgGetSystemViewById = <TData = undefined>(
  variables: OrgGetSystemViewByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, OrgGetSystemViewByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgGetSystemViewByIdError, TData>(
    queryKeyFn({ path: '/api/Org/SystemView/{id}', operationId: 'orgGetSystemViewById', variables }),
    ({ signal }) => fetchOrgGetSystemViewById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgGetQueryParams = {
  search?: string;
};

export type OrgGetHeaders = {
  ['x-api-version']?: string;
};

export type OrgGetError = Fetcher.ErrorWrapper<undefined>;

export type OrgGetVariables = {
  headers?: OrgGetHeaders;
  queryParams?: OrgGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchOrgGet = (variables: OrgGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, OrgGetError, undefined, OrgGetHeaders, OrgGetQueryParams, {}>({
    url: '/api/Org',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgGet = <TData = undefined>(
  variables: OrgGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, OrgGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgGetError, TData>(
    queryKeyFn({ path: '/api/Org', operationId: 'orgGet', variables }),
    ({ signal }) => fetchOrgGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsPutBulkUploadHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsPutBulkUploadError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsPutBulkUploadRequestBody = {
  /**
   * @format binary
   */
  BulkUploadFile?: Blob;
};

export type OrgChangeRequestsPutBulkUploadVariables = {
  body?: OrgChangeRequestsPutBulkUploadRequestBody;
  headers?: OrgChangeRequestsPutBulkUploadHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsPutBulkUpload = (
  variables: OrgChangeRequestsPutBulkUploadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    OrgChangeRequestsPutBulkUploadError,
    OrgChangeRequestsPutBulkUploadRequestBody,
    OrgChangeRequestsPutBulkUploadHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/BulkUpload', method: 'put', ...variables, signal });

export const useOrgChangeRequestsPutBulkUpload = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      OrgChangeRequestsPutBulkUploadError,
      OrgChangeRequestsPutBulkUploadVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    OrgChangeRequestsPutBulkUploadError,
    OrgChangeRequestsPutBulkUploadVariables
  >(
    (variables: OrgChangeRequestsPutBulkUploadVariables) =>
      fetchOrgChangeRequestsPutBulkUpload({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsGetHierarchyHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetHierarchyError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetHierarchyVariables = {
  headers?: OrgChangeRequestsGetHierarchyHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGetHierarchy = (
  variables: OrgChangeRequestsGetHierarchyVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, OrgChangeRequestsGetHierarchyError, undefined, OrgChangeRequestsGetHierarchyHeaders, {}, {}>({
    url: '/api/OrgChangeRequests/Hierarchy',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgChangeRequestsGetHierarchy = <TData = undefined>(
  variables: OrgChangeRequestsGetHierarchyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, OrgChangeRequestsGetHierarchyError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, OrgChangeRequestsGetHierarchyError, TData>(
    queryKeyFn({ path: '/api/OrgChangeRequests/Hierarchy', operationId: 'orgChangeRequestsGetHierarchy', variables }),
    ({ signal }) => fetchOrgChangeRequestsGetHierarchy({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsPostGroupHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsPostGroupError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type OrgChangeRequestsPostGroupVariables = {
  body: Schemas.AddGroupDto;
  headers?: OrgChangeRequestsPostGroupHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsPostGroup = (variables: OrgChangeRequestsPostGroupVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PhysicianGroupDto,
    OrgChangeRequestsPostGroupError,
    Schemas.AddGroupDto,
    OrgChangeRequestsPostGroupHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests/Group', method: 'post', ...variables, signal });

export const useOrgChangeRequestsPostGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PhysicianGroupDto,
      OrgChangeRequestsPostGroupError,
      OrgChangeRequestsPostGroupVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.PhysicianGroupDto,
    OrgChangeRequestsPostGroupError,
    OrgChangeRequestsPostGroupVariables
  >(
    (variables: OrgChangeRequestsPostGroupVariables) =>
      fetchOrgChangeRequestsPostGroup({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsGetHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetVariables = {
  headers?: OrgChangeRequestsGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGet = (variables: OrgChangeRequestsGetVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.OrgChangeRequestOrgChangeRequestQueue,
    OrgChangeRequestsGetError,
    undefined,
    OrgChangeRequestsGetHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests', method: 'get', ...variables, signal });

export const useOrgChangeRequestsGet = <TData = Schemas.OrgChangeRequestOrgChangeRequestQueue>(
  variables: OrgChangeRequestsGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrgChangeRequestOrgChangeRequestQueue, OrgChangeRequestsGetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.OrgChangeRequestOrgChangeRequestQueue, OrgChangeRequestsGetError, TData>(
    queryKeyFn({ path: '/api/OrgChangeRequests', operationId: 'orgChangeRequestsGet', variables }),
    ({ signal }) => fetchOrgChangeRequestsGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsPutHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsPutError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsPutVariables = {
  body?: Schemas.OrgChangeRequestOrgChangeRequestQueue;
  headers?: OrgChangeRequestsPutHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsPut = (variables: OrgChangeRequestsPutVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    OrgChangeRequestsPutError,
    Schemas.OrgChangeRequestOrgChangeRequestQueue,
    OrgChangeRequestsPutHeaders,
    {},
    {}
  >({ url: '/api/OrgChangeRequests', method: 'put', ...variables, signal });

export const useOrgChangeRequestsPut = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, OrgChangeRequestsPutError, OrgChangeRequestsPutVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, OrgChangeRequestsPutError, OrgChangeRequestsPutVariables>(
    (variables: OrgChangeRequestsPutVariables) => fetchOrgChangeRequestsPut({ ...fetcherOptions, ...variables }),
    options
  );
};

export type OrgChangeRequestsGetUserHierarchyHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetUserHierarchyError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetUserHierarchyVariables = {
  headers?: OrgChangeRequestsGetUserHierarchyHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGetUserHierarchy = (
  variables: OrgChangeRequestsGetUserHierarchyVariables,
  signal?: AbortSignal
) =>
  apiFetch<Blob, OrgChangeRequestsGetUserHierarchyError, undefined, OrgChangeRequestsGetUserHierarchyHeaders, {}, {}>({
    url: '/api/OrgChangeRequests/UserHierarchy',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgChangeRequestsGetUserHierarchy = <TData = Blob>(
  variables: OrgChangeRequestsGetUserHierarchyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, OrgChangeRequestsGetUserHierarchyError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsGetUserHierarchyError, TData>(
    queryKeyFn({
      path: '/api/OrgChangeRequests/UserHierarchy',
      operationId: 'orgChangeRequestsGetUserHierarchy',
      variables
    }),
    ({ signal }) => fetchOrgChangeRequestsGetUserHierarchy({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsGetTemplateHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetTemplateError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetTemplateVariables = {
  headers?: OrgChangeRequestsGetTemplateHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGetTemplate = (
  variables: OrgChangeRequestsGetTemplateVariables,
  signal?: AbortSignal
) =>
  apiFetch<Blob, OrgChangeRequestsGetTemplateError, undefined, OrgChangeRequestsGetTemplateHeaders, {}, {}>({
    url: '/api/OrgChangeRequests/Template',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgChangeRequestsGetTemplate = <TData = Blob>(
  variables: OrgChangeRequestsGetTemplateVariables,
  options?: Omit<reactQuery.UseQueryOptions<Blob, OrgChangeRequestsGetTemplateError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsGetTemplateError, TData>(
    queryKeyFn({ path: '/api/OrgChangeRequests/Template', operationId: 'orgChangeRequestsGetTemplate', variables }),
    ({ signal }) => fetchOrgChangeRequestsGetTemplate({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type OrgChangeRequestsGetHistoryHeaders = {
  ['x-api-version']?: string;
};

export type OrgChangeRequestsGetHistoryError = Fetcher.ErrorWrapper<undefined>;

export type OrgChangeRequestsGetHistoryVariables = {
  headers?: OrgChangeRequestsGetHistoryHeaders;
} & ApiContext['fetcherOptions'];

export const fetchOrgChangeRequestsGetHistory = (
  variables: OrgChangeRequestsGetHistoryVariables,
  signal?: AbortSignal
) =>
  apiFetch<Blob, OrgChangeRequestsGetHistoryError, undefined, OrgChangeRequestsGetHistoryHeaders, {}, {}>({
    url: '/api/OrgChangeRequests/History',
    method: 'get',
    ...variables,
    signal
  });

export const useOrgChangeRequestsGetHistory = <TData = Blob>(
  variables: OrgChangeRequestsGetHistoryVariables,
  options?: Omit<reactQuery.UseQueryOptions<Blob, OrgChangeRequestsGetHistoryError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, OrgChangeRequestsGetHistoryError, TData>(
    queryKeyFn({ path: '/api/OrgChangeRequests/History', operationId: 'orgChangeRequestsGetHistory', variables }),
    ({ signal }) => fetchOrgChangeRequestsGetHistory({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type PhysicianGroupsGetQueryParams = {
  /**
   * @format int32
   */
  hospitalId?: number;
  specialtyGroupName?: string;
  /**
   * @default true
   */
  excludeHiddenSpecialtyGroups?: boolean;
  /**
   * @default true
   */
  excludeOtherGroups?: boolean;
  /**
   * @default false
   */
  excludeOtherMDs?: boolean;
  /**
   * @default false
   */
  excludeHiddenProviders?: boolean;
  /**
   * @default false
   */
  mustHavePerfData?: boolean;
};

export type PhysicianGroupsGetHeaders = {
  ['x-api-version']?: string;
};

export type PhysicianGroupsGetError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type PhysicianGroupsGetVariables = {
  headers?: PhysicianGroupsGetHeaders;
  queryParams?: PhysicianGroupsGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchPhysicianGroupsGet = (variables: PhysicianGroupsGetVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.PhysicianGroupDto,
    PhysicianGroupsGetError,
    undefined,
    PhysicianGroupsGetHeaders,
    PhysicianGroupsGetQueryParams,
    {}
  >({ url: '/api/PhysicianGroups', method: 'get', ...variables, signal });

export const usePhysicianGroupsGet = <TData = Schemas.PhysicianGroupDto>(
  variables: PhysicianGroupsGetVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PhysicianGroupDto, PhysicianGroupsGetError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.PhysicianGroupDto, PhysicianGroupsGetError, TData>(
    queryKeyFn({ path: '/api/PhysicianGroups', operationId: 'physicianGroupsGet', variables }),
    ({ signal }) => fetchPhysicianGroupsGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ProviderPostHeaders = {
  ['x-api-version']?: string;
};

export type ProviderPostError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ProviderPostVariables = {
  body: Schemas.AddProviderDto;
  headers?: ProviderPostHeaders;
} & ApiContext['fetcherOptions'];

export const fetchProviderPost = (variables: ProviderPostVariables, signal?: AbortSignal) =>
  apiFetch<undefined, ProviderPostError, Schemas.AddProviderDto, ProviderPostHeaders, {}, {}>({
    url: '/api/Provider',
    method: 'post',
    ...variables,
    signal
  });

export const useProviderPost = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, ProviderPostError, ProviderPostVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ProviderPostError, ProviderPostVariables>(
    (variables: ProviderPostVariables) => fetchProviderPost({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ProviderGetQueryParams = {
  searchTerm?: string;
};

export type ProviderGetHeaders = {
  ['x-api-version']?: string;
};

export type ProviderGetError = Fetcher.ErrorWrapper<undefined>;

export type ProviderGetResponse = Schemas.DomainModelProviderOrgDetails[];

export type ProviderGetVariables = {
  headers?: ProviderGetHeaders;
  queryParams?: ProviderGetQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchProviderGet = (variables: ProviderGetVariables, signal?: AbortSignal) =>
  apiFetch<ProviderGetResponse, ProviderGetError, undefined, ProviderGetHeaders, ProviderGetQueryParams, {}>({
    url: '/api/Provider',
    method: 'get',
    ...variables,
    signal
  });

export const useProviderGet = <TData = ProviderGetResponse>(
  variables: ProviderGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<ProviderGetResponse, ProviderGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<ProviderGetResponse, ProviderGetError, TData>(
    queryKeyFn({ path: '/api/Provider', operationId: 'providerGet', variables }),
    ({ signal }) => fetchProviderGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetProviderByProviderOrgIdPathParams = {
  /**
   * @format int32
   */
  providerOrgId: number;
};

export type ReportsGetProviderByProviderOrgIdQueryParams = {
  /**
   * @default false
   */
  createNew?: boolean;
};

export type ReportsGetProviderByProviderOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetProviderByProviderOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetProviderByProviderOrgIdVariables = {
  headers?: ReportsGetProviderByProviderOrgIdHeaders;
  pathParams: ReportsGetProviderByProviderOrgIdPathParams;
  queryParams?: ReportsGetProviderByProviderOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetProviderByProviderOrgId = (
  variables: ReportsGetProviderByProviderOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetProviderByProviderOrgIdError,
    undefined,
    ReportsGetProviderByProviderOrgIdHeaders,
    ReportsGetProviderByProviderOrgIdQueryParams,
    ReportsGetProviderByProviderOrgIdPathParams
  >({ url: '/api/Reports/Provider/{providerOrgId}', method: 'get', ...variables, signal });

export const useReportsGetProviderByProviderOrgId = <TData = Blob>(
  variables: ReportsGetProviderByProviderOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetProviderByProviderOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetProviderByProviderOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Reports/Provider/{providerOrgId}',
      operationId: 'reportsGetProviderByProviderOrgId',
      variables
    }),
    ({ signal }) => fetchReportsGetProviderByProviderOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgId: number;
};

export type ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdVariables = {
  headers?: ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdHeaders;
  pathParams: ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgId = (
  variables: ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdError,
    undefined,
    ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdHeaders,
    {},
    ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdPathParams
  >({ url: '/api/Reports/Provider/Log/PhysicianGroup/{physicianGroupOrgId}', method: 'get', ...variables, signal });

export const useReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgId = <TData = undefined>(
  variables: ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Reports/Provider/Log/PhysicianGroup/{physicianGroupOrgId}',
      operationId: 'reportsGetProviderLogPhysicianGroupByPhysicianGroupOrgId',
      variables
    }),
    ({ signal }) =>
      fetchReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsPostProviderSendHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostProviderSendError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostProviderSendVariables = {
  body: Schemas.ProviderReportPhysicianDataDto;
  headers?: ReportsPostProviderSendHeaders;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostProviderSend = (variables: ReportsPostProviderSendVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ReportsPostProviderSendError,
    Schemas.ProviderReportPhysicianDataDto,
    ReportsPostProviderSendHeaders,
    {},
    {}
  >({ url: '/api/Reports/Provider/Send', method: 'post', ...variables, signal });

export const useReportsPostProviderSend = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ReportsPostProviderSendError, ReportsPostProviderSendVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ReportsPostProviderSendError, ReportsPostProviderSendVariables>(
    (variables: ReportsPostProviderSendVariables) => fetchReportsPostProviderSend({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsGetProviderGenerateQueryParams = {
  groupOrgId?: number[];
};

export type ReportsGetProviderGenerateHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetProviderGenerateError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetProviderGenerateVariables = {
  headers?: ReportsGetProviderGenerateHeaders;
  queryParams?: ReportsGetProviderGenerateQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetProviderGenerate = (variables: ReportsGetProviderGenerateVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ReportsGetProviderGenerateError,
    undefined,
    ReportsGetProviderGenerateHeaders,
    ReportsGetProviderGenerateQueryParams,
    {}
  >({ url: '/api/Reports/Provider/Generate', method: 'get', ...variables, signal });

export const useReportsGetProviderGenerate = <TData = undefined>(
  variables: ReportsGetProviderGenerateVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, ReportsGetProviderGenerateError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ReportsGetProviderGenerateError, TData>(
    queryKeyFn({ path: '/api/Reports/Provider/Generate', operationId: 'reportsGetProviderGenerate', variables }),
    ({ signal }) => fetchReportsGetProviderGenerate({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesGetHeaders = {
  ['x-api-version']?: string;
};

export type RolesGetError = Fetcher.ErrorWrapper<undefined>;

export type RolesGetVariables = {
  headers?: RolesGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchRolesGet = (variables: RolesGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesGetError, undefined, RolesGetHeaders, {}, {}>({
    url: '/api/Roles',
    method: 'get',
    ...variables,
    signal
  });

export const useRolesGet = <TData = undefined>(
  variables: RolesGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, RolesGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, RolesGetError, TData>(
    queryKeyFn({ path: '/api/Roles', operationId: 'rolesGet', variables }),
    ({ signal }) => fetchRolesGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesGetByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type RolesGetByIdHeaders = {
  ['x-api-version']?: string;
};

export type RolesGetByIdError = Fetcher.ErrorWrapper<undefined>;

export type RolesGetByIdVariables = {
  headers?: RolesGetByIdHeaders;
  pathParams: RolesGetByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRolesGetById = (variables: RolesGetByIdVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesGetByIdError, undefined, RolesGetByIdHeaders, {}, RolesGetByIdPathParams>({
    url: '/api/Roles/{id}',
    method: 'get',
    ...variables,
    signal
  });

export const useRolesGetById = <TData = undefined>(
  variables: RolesGetByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, RolesGetByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, RolesGetByIdError, TData>(
    queryKeyFn({ path: '/api/Roles/{id}', operationId: 'rolesGetById', variables }),
    ({ signal }) => fetchRolesGetById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type RolesPutByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type RolesPutByIdHeaders = {
  ['x-api-version']?: string;
};

export type RolesPutByIdError = Fetcher.ErrorWrapper<undefined>;

export type RolesPutByIdVariables = {
  body?: Schemas.RoleDto;
  headers?: RolesPutByIdHeaders;
  pathParams: RolesPutByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchRolesPutById = (variables: RolesPutByIdVariables, signal?: AbortSignal) =>
  apiFetch<undefined, RolesPutByIdError, Schemas.RoleDto, RolesPutByIdHeaders, {}, RolesPutByIdPathParams>({
    url: '/api/Roles/{id}',
    method: 'put',
    ...variables,
    signal
  });

export const useRolesPutById = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, RolesPutByIdError, RolesPutByIdVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, RolesPutByIdError, RolesPutByIdVariables>(
    (variables: RolesPutByIdVariables) => fetchRolesPutById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SafGetConditionsQueryParams = {
  /**
   * @format int32
   */
  orgId?: number;
};

export type SafGetConditionsHeaders = {
  ['x-api-version']?: string;
};

export type SafGetConditionsError = Fetcher.ErrorWrapper<undefined>;

export type SafGetConditionsVariables = {
  headers?: SafGetConditionsHeaders;
  queryParams?: SafGetConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetConditions = (variables: SafGetConditionsVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafGetConditionsError, undefined, SafGetConditionsHeaders, SafGetConditionsQueryParams, {}>({
    url: '/api/Saf/Conditions',
    method: 'get',
    ...variables,
    signal
  });

export const useSafGetConditions = <TData = undefined>(
  variables: SafGetConditionsVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetConditionsError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetConditionsError, TData>(
    queryKeyFn({ path: '/api/Saf/Conditions', operationId: 'safGetConditions', variables }),
    ({ signal }) => fetchSafGetConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetGraphCmiByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetGraphCmiByIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGetGraphCmiByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetGraphCmiByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetGraphCmiByIdVariables = {
  headers?: SafGetGraphCmiByIdHeaders;
  pathParams: SafGetGraphCmiByIdPathParams;
  queryParams?: SafGetGraphCmiByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetGraphCmiById = (variables: SafGetGraphCmiByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGetGraphCmiByIdError,
    undefined,
    SafGetGraphCmiByIdHeaders,
    SafGetGraphCmiByIdQueryParams,
    SafGetGraphCmiByIdPathParams
  >({ url: '/api/Saf/Graph/Cmi/{id}', method: 'get', ...variables, signal });

export const useSafGetGraphCmiById = <TData = undefined>(
  variables: SafGetGraphCmiByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetGraphCmiByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetGraphCmiByIdError, TData>(
    queryKeyFn({ path: '/api/Saf/Graph/Cmi/{id}', operationId: 'safGetGraphCmiById', variables }),
    ({ signal }) => fetchSafGetGraphCmiById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetGraphDocScoreByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetGraphDocScoreByIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGetGraphDocScoreByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetGraphDocScoreByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetGraphDocScoreByIdVariables = {
  headers?: SafGetGraphDocScoreByIdHeaders;
  pathParams: SafGetGraphDocScoreByIdPathParams;
  queryParams?: SafGetGraphDocScoreByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetGraphDocScoreById = (variables: SafGetGraphDocScoreByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGetGraphDocScoreByIdError,
    undefined,
    SafGetGraphDocScoreByIdHeaders,
    SafGetGraphDocScoreByIdQueryParams,
    SafGetGraphDocScoreByIdPathParams
  >({ url: '/api/Saf/Graph/DocScore/{id}', method: 'get', ...variables, signal });

export const useSafGetGraphDocScoreById = <TData = undefined>(
  variables: SafGetGraphDocScoreByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetGraphDocScoreByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetGraphDocScoreByIdError, TData>(
    queryKeyFn({ path: '/api/Saf/Graph/DocScore/{id}', operationId: 'safGetGraphDocScoreById', variables }),
    ({ signal }) => fetchSafGetGraphDocScoreById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetGraphHcupCmiByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetGraphHcupCmiByIdQueryParams = {
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGetGraphHcupCmiByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetGraphHcupCmiByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetGraphHcupCmiByIdVariables = {
  headers?: SafGetGraphHcupCmiByIdHeaders;
  pathParams: SafGetGraphHcupCmiByIdPathParams;
  queryParams?: SafGetGraphHcupCmiByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetGraphHcupCmiById = (variables: SafGetGraphHcupCmiByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SafGetGraphHcupCmiByIdError,
    undefined,
    SafGetGraphHcupCmiByIdHeaders,
    SafGetGraphHcupCmiByIdQueryParams,
    SafGetGraphHcupCmiByIdPathParams
  >({ url: '/api/Saf/Graph/HcupCmi/{id}', method: 'get', ...variables, signal });

export const useSafGetGraphHcupCmiById = <TData = undefined>(
  variables: SafGetGraphHcupCmiByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetGraphHcupCmiByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetGraphHcupCmiByIdError, TData>(
    queryKeyFn({ path: '/api/Saf/Graph/HcupCmi/{id}', operationId: 'safGetGraphHcupCmiById', variables }),
    ({ signal }) => fetchSafGetGraphHcupCmiById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetHistogramSafHistogramReportQueryParams = {
  /**
   * current2 or OtoE, whatever the toggle is set to on condition graphs.
   */
  ConditionGraphType?: string;
  /**
   * The list of IDs for all entities in the graph. The first one is the primary entity, all others are callouts.
   */
  EntityIds?: string;
  BedSizeTypes?: string;
  CommunityTypes?: string;
  ConditionNames?: string;
  DischargeTypes?: string;
  FundamentalSpecialtyIds?: string;
  HospitalTypes?: string;
  MetricTypes?: string;
  /**
   * @format int32
   */
  MinimumDischargeCount?: number;
  RegionTypes?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafGetHistogramSafHistogramReportHeaders = {
  ['x-api-version']?: string;
};

export type SafGetHistogramSafHistogramReportError = Fetcher.ErrorWrapper<undefined>;

export type SafGetHistogramSafHistogramReportVariables = {
  headers?: SafGetHistogramSafHistogramReportHeaders;
  queryParams?: SafGetHistogramSafHistogramReportQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetHistogramSafHistogramReport = (
  variables: SafGetHistogramSafHistogramReportVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetHistogramSafHistogramReportError,
    undefined,
    SafGetHistogramSafHistogramReportHeaders,
    SafGetHistogramSafHistogramReportQueryParams,
    {}
  >({ url: '/api/Saf/Histogram/SafHistogramReport', method: 'get', ...variables, signal });

export const useSafGetHistogramSafHistogramReport = <TData = undefined>(
  variables: SafGetHistogramSafHistogramReportVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetHistogramSafHistogramReportError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetHistogramSafHistogramReportError, TData>(
    queryKeyFn({
      path: '/api/Saf/Histogram/SafHistogramReport',
      operationId: 'safGetHistogramSafHistogramReport',
      variables
    }),
    ({ signal }) => fetchSafGetHistogramSafHistogramReport({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetHistogramFiltersHeaders = {
  ['x-api-version']?: string;
};

export type SafGetHistogramFiltersError = Fetcher.ErrorWrapper<undefined>;

export type SafGetHistogramFiltersVariables = {
  headers?: SafGetHistogramFiltersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSafGetHistogramFilters = (variables: SafGetHistogramFiltersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafGetHistogramFiltersError, undefined, SafGetHistogramFiltersHeaders, {}, {}>({
    url: '/api/Saf/Histogram/Filters',
    method: 'get',
    ...variables,
    signal
  });

export const useSafGetHistogramFilters = <TData = undefined>(
  variables: SafGetHistogramFiltersVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetHistogramFiltersError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetHistogramFiltersError, TData>(
    queryKeyFn({ path: '/api/Saf/Histogram/Filters', operationId: 'safGetHistogramFilters', variables }),
    ({ signal }) => fetchSafGetHistogramFilters({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetHistogramFiltersByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type SafGetHistogramFiltersByOrgIdQueryParams = {
  fundamentalSpecialtyIds?: string;
};

export type SafGetHistogramFiltersByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetHistogramFiltersByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetHistogramFiltersByOrgIdVariables = {
  headers?: SafGetHistogramFiltersByOrgIdHeaders;
  pathParams: SafGetHistogramFiltersByOrgIdPathParams;
  queryParams?: SafGetHistogramFiltersByOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetHistogramFiltersByOrgId = (
  variables: SafGetHistogramFiltersByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetHistogramFiltersByOrgIdError,
    undefined,
    SafGetHistogramFiltersByOrgIdHeaders,
    SafGetHistogramFiltersByOrgIdQueryParams,
    SafGetHistogramFiltersByOrgIdPathParams
  >({ url: '/api/Saf/Histogram/Filters/{orgId}', method: 'get', ...variables, signal });

export const useSafGetHistogramFiltersByOrgId = <TData = undefined>(
  variables: SafGetHistogramFiltersByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetHistogramFiltersByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetHistogramFiltersByOrgIdError, TData>(
    queryKeyFn({ path: '/api/Saf/Histogram/Filters/{orgId}', operationId: 'safGetHistogramFiltersByOrgId', variables }),
    ({ signal }) => fetchSafGetHistogramFiltersByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetHistogramQueryParams = {
  BedSizeTypes?: string;
  CommunityTypes?: string;
  ConditionNames?: string;
  DischargeTypes?: string;
  FundamentalSpecialtyIds?: string;
  HospitalTypes?: string;
  MetricTypes?: string;
  /**
   * @format int32
   */
  MinimumDischargeCount?: number;
  RegionTypes?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafGetHistogramHeaders = {
  ['x-api-version']?: string;
};

export type SafGetHistogramError = Fetcher.ErrorWrapper<undefined>;

export type SafGetHistogramResponse = Schemas.SAFHistogramDto[];

export type SafGetHistogramVariables = {
  headers?: SafGetHistogramHeaders;
  queryParams?: SafGetHistogramQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetHistogram = (variables: SafGetHistogramVariables, signal?: AbortSignal) =>
  apiFetch<
    SafGetHistogramResponse,
    SafGetHistogramError,
    undefined,
    SafGetHistogramHeaders,
    SafGetHistogramQueryParams,
    {}
  >({ url: '/api/Saf/Histogram', method: 'get', ...variables, signal });

export const useSafGetHistogram = <TData = SafGetHistogramResponse>(
  variables: SafGetHistogramVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SafGetHistogramResponse, SafGetHistogramError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafGetHistogramResponse, SafGetHistogramError, TData>(
    queryKeyFn({ path: '/api/Saf/Histogram', operationId: 'safGetHistogram', variables }),
    ({ signal }) => fetchSafGetHistogram({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetHistogramMetricTypesHeaders = {
  ['x-api-version']?: string;
};

export type SafGetHistogramMetricTypesError = Fetcher.ErrorWrapper<undefined>;

export type SafGetHistogramMetricTypesVariables = {
  headers?: SafGetHistogramMetricTypesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSafGetHistogramMetricTypes = (variables: SafGetHistogramMetricTypesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SafGetHistogramMetricTypesError, undefined, SafGetHistogramMetricTypesHeaders, {}, {}>({
    url: '/api/Saf/Histogram/MetricTypes',
    method: 'get',
    ...variables,
    signal
  });

export const useSafGetHistogramMetricTypes = <TData = undefined>(
  variables: SafGetHistogramMetricTypesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SafGetHistogramMetricTypesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetHistogramMetricTypesError, TData>(
    queryKeyFn({ path: '/api/Saf/Histogram/MetricTypes', operationId: 'safGetHistogramMetricTypes', variables }),
    ({ signal }) => fetchSafGetHistogramMetricTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetMetricsConditionsQueryParams = {
  ConditionNames?: string;
  EntityIds?: string;
  FundamentalSpecialtyIds?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafGetMetricsConditionsHeaders = {
  ['x-api-version']?: string;
};

export type SafGetMetricsConditionsError = Fetcher.ErrorWrapper<undefined>;

export type SafGetMetricsConditionsResponse = Schemas.DomainModelConditionsMetric[];

export type SafGetMetricsConditionsVariables = {
  headers?: SafGetMetricsConditionsHeaders;
  queryParams?: SafGetMetricsConditionsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetMetricsConditions = (variables: SafGetMetricsConditionsVariables, signal?: AbortSignal) =>
  apiFetch<
    SafGetMetricsConditionsResponse,
    SafGetMetricsConditionsError,
    undefined,
    SafGetMetricsConditionsHeaders,
    SafGetMetricsConditionsQueryParams,
    {}
  >({ url: '/api/Saf/Metrics/Conditions', method: 'get', ...variables, signal });

export const useSafGetMetricsConditions = <TData = SafGetMetricsConditionsResponse>(
  variables: SafGetMetricsConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<SafGetMetricsConditionsResponse, SafGetMetricsConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafGetMetricsConditionsResponse, SafGetMetricsConditionsError, TData>(
    queryKeyFn({ path: '/api/Saf/Metrics/Conditions', operationId: 'safGetMetricsConditions', variables }),
    ({ signal }) => fetchSafGetMetricsConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetMetricsQueryParams = {
  EntityIds?: string;
  FundamentalSpecialtyIds?: string;
  /**
   * @format int32
   */
  TimePeriodType?: number;
};

export type SafGetMetricsHeaders = {
  ['x-api-version']?: string;
};

export type SafGetMetricsError = Fetcher.ErrorWrapper<undefined>;

export type SafGetMetricsResponse = Schemas.OverallPerformanceDataDto[];

export type SafGetMetricsVariables = {
  headers?: SafGetMetricsHeaders;
  queryParams?: SafGetMetricsQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetMetrics = (variables: SafGetMetricsVariables, signal?: AbortSignal) =>
  apiFetch<SafGetMetricsResponse, SafGetMetricsError, undefined, SafGetMetricsHeaders, SafGetMetricsQueryParams, {}>({
    url: '/api/Saf/Metrics',
    method: 'get',
    ...variables,
    signal
  });

export const useSafGetMetrics = <TData = SafGetMetricsResponse>(
  variables: SafGetMetricsVariables,
  options?: Omit<reactQuery.UseQueryOptions<SafGetMetricsResponse, SafGetMetricsError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<SafGetMetricsResponse, SafGetMetricsError, TData>(
    queryKeyFn({ path: '/api/Saf/Metrics', operationId: 'safGetMetrics', variables }),
    ({ signal }) => fetchSafGetMetrics({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetMetricsDrgMixComparisonByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetMetricsDrgMixComparisonByIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGetMetricsDrgMixComparisonByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetMetricsDrgMixComparisonByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetMetricsDrgMixComparisonByIdVariables = {
  headers?: SafGetMetricsDrgMixComparisonByIdHeaders;
  pathParams: SafGetMetricsDrgMixComparisonByIdPathParams;
  queryParams?: SafGetMetricsDrgMixComparisonByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetMetricsDrgMixComparisonById = (
  variables: SafGetMetricsDrgMixComparisonByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetMetricsDrgMixComparisonByIdError,
    undefined,
    SafGetMetricsDrgMixComparisonByIdHeaders,
    SafGetMetricsDrgMixComparisonByIdQueryParams,
    SafGetMetricsDrgMixComparisonByIdPathParams
  >({ url: '/api/Saf/Metrics/DrgMixComparison/{id}', method: 'get', ...variables, signal });

export const useSafGetMetricsDrgMixComparisonById = <TData = undefined>(
  variables: SafGetMetricsDrgMixComparisonByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetMetricsDrgMixComparisonByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetMetricsDrgMixComparisonByIdError, TData>(
    queryKeyFn({
      path: '/api/Saf/Metrics/DrgMixComparison/{id}',
      operationId: 'safGetMetricsDrgMixComparisonById',
      variables
    }),
    ({ signal }) => fetchSafGetMetricsDrgMixComparisonById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetMetricsSeverityCmiComparisonByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetMetricsSeverityCmiComparisonByIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
};

export type SafGetMetricsSeverityCmiComparisonByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetMetricsSeverityCmiComparisonByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetMetricsSeverityCmiComparisonByIdVariables = {
  headers?: SafGetMetricsSeverityCmiComparisonByIdHeaders;
  pathParams: SafGetMetricsSeverityCmiComparisonByIdPathParams;
  queryParams?: SafGetMetricsSeverityCmiComparisonByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetMetricsSeverityCmiComparisonById = (
  variables: SafGetMetricsSeverityCmiComparisonByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetMetricsSeverityCmiComparisonByIdError,
    undefined,
    SafGetMetricsSeverityCmiComparisonByIdHeaders,
    SafGetMetricsSeverityCmiComparisonByIdQueryParams,
    SafGetMetricsSeverityCmiComparisonByIdPathParams
  >({ url: '/api/Saf/Metrics/SeverityCmiComparison/{id}', method: 'get', ...variables, signal });

export const useSafGetMetricsSeverityCmiComparisonById = <TData = undefined>(
  variables: SafGetMetricsSeverityCmiComparisonByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetMetricsSeverityCmiComparisonByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetMetricsSeverityCmiComparisonByIdError, TData>(
    queryKeyFn({
      path: '/api/Saf/Metrics/SeverityCmiComparison/{id}',
      operationId: 'safGetMetricsSeverityCmiComparisonById',
      variables
    }),
    ({ signal }) => fetchSafGetMetricsSeverityCmiComparisonById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams = {
  /**
   * First org selected
   *
   * @format int32
   */
  orgId: number;
  /**
   * Comparison org selected
   *
   * @format int32
   */
  compareToOrgId: number;
};

export type SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams = {
  /**
   * Full date range for graphs start date
   *
   * @format date-time
   */
  periodStart?: string;
  /**
   * Full date range for graphs end date
   *
   * @format date-time
   */
  periodEnd?: string;
  /**
   * Comparison range start date
   *
   * @format date-time
   */
  rangeStart?: string;
  /**
   * Comparison range end date
   *
   * @format date-time
   */
  rangeEnd?: string;
};

export type SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables = {
  headers?: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders;
  pathParams: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams;
  queryParams?: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgId = (
  variables: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError,
    undefined,
    SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdHeaders,
    SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdQueryParams,
    SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdPathParams
  >({
    url: '/api/Saf/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}',
    method: 'get',
    ...variables,
    signal
  });

export const useSafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgId = <TData = undefined>(
  variables: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Saf/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}',
      operationId: 'safGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgId',
      variables
    }),
    ({ signal }) =>
      fetchSafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SafGetDownloadMetricsDrgMixComparisonByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SafGetDownloadMetricsDrgMixComparisonByIdQueryParams = {
  /**
   * @format int32
   */
  compareTo?: number;
  /**
   * @format date-time
   */
  startDate?: string;
  /**
   * @format date-time
   */
  endDate?: string;
  csvColumns?: string;
  sortPositiveColumn?: string;
  sortPositiveAscending?: boolean;
  sortNegativeColumn?: string;
  sortNegativeAscending?: boolean;
  sortInsignificantColumn?: string;
  sortInsignificantAscending?: boolean;
};

export type SafGetDownloadMetricsDrgMixComparisonByIdHeaders = {
  ['x-api-version']?: string;
};

export type SafGetDownloadMetricsDrgMixComparisonByIdError = Fetcher.ErrorWrapper<undefined>;

export type SafGetDownloadMetricsDrgMixComparisonByIdVariables = {
  headers?: SafGetDownloadMetricsDrgMixComparisonByIdHeaders;
  pathParams: SafGetDownloadMetricsDrgMixComparisonByIdPathParams;
  queryParams?: SafGetDownloadMetricsDrgMixComparisonByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSafGetDownloadMetricsDrgMixComparisonById = (
  variables: SafGetDownloadMetricsDrgMixComparisonByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SafGetDownloadMetricsDrgMixComparisonByIdError,
    undefined,
    SafGetDownloadMetricsDrgMixComparisonByIdHeaders,
    SafGetDownloadMetricsDrgMixComparisonByIdQueryParams,
    SafGetDownloadMetricsDrgMixComparisonByIdPathParams
  >({ url: '/api/Saf/Download/Metrics/DrgMixComparison/{id}', method: 'get', ...variables, signal });

export const useSafGetDownloadMetricsDrgMixComparisonById = <TData = undefined>(
  variables: SafGetDownloadMetricsDrgMixComparisonByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SafGetDownloadMetricsDrgMixComparisonByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SafGetDownloadMetricsDrgMixComparisonByIdError, TData>(
    queryKeyFn({
      path: '/api/Saf/Download/Metrics/DrgMixComparison/{id}',
      operationId: 'safGetDownloadMetricsDrgMixComparisonById',
      variables
    }),
    ({ signal }) => fetchSafGetDownloadMetricsDrgMixComparisonById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SAFGetOrgDefaultDatesByOrgIdPathParams = {
  /**
   * @format int32
   */
  orgId: number;
};

export type SAFGetOrgDefaultDatesByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type SAFGetOrgDefaultDatesByOrgIdError = Fetcher.ErrorWrapper<undefined>;

export type SAFGetOrgDefaultDatesByOrgIdVariables = {
  headers?: SAFGetOrgDefaultDatesByOrgIdHeaders;
  pathParams: SAFGetOrgDefaultDatesByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSAFGetOrgDefaultDatesByOrgId = (
  variables: SAFGetOrgDefaultDatesByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SAFGetOrgDefaultDatesByOrgIdError,
    undefined,
    SAFGetOrgDefaultDatesByOrgIdHeaders,
    {},
    SAFGetOrgDefaultDatesByOrgIdPathParams
  >({ url: '/api/SAF/Org/DefaultDates/{orgId}', method: 'get', ...variables, signal });

export const useSAFGetOrgDefaultDatesByOrgId = <TData = undefined>(
  variables: SAFGetOrgDefaultDatesByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SAFGetOrgDefaultDatesByOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SAFGetOrgDefaultDatesByOrgIdError, TData>(
    queryKeyFn({ path: '/api/SAF/Org/DefaultDates/{orgId}', operationId: 'sAFGetOrgDefaultDatesByOrgId', variables }),
    ({ signal }) => fetchSAFGetOrgDefaultDatesByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SAFGetOrgByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SAFGetOrgByIdQueryParams = {
  /**
   * @default false
   */
  includeLineage?: boolean;
};

export type SAFGetOrgByIdHeaders = {
  ['x-api-version']?: string;
};

export type SAFGetOrgByIdError = Fetcher.ErrorWrapper<undefined>;

export type SAFGetOrgByIdVariables = {
  headers?: SAFGetOrgByIdHeaders;
  pathParams: SAFGetOrgByIdPathParams;
  queryParams?: SAFGetOrgByIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSAFGetOrgById = (variables: SAFGetOrgByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SAFGetOrgByIdError,
    undefined,
    SAFGetOrgByIdHeaders,
    SAFGetOrgByIdQueryParams,
    SAFGetOrgByIdPathParams
  >({ url: '/api/SAF/Org/{id}', method: 'get', ...variables, signal });

export const useSAFGetOrgById = <TData = undefined>(
  variables: SAFGetOrgByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SAFGetOrgByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SAFGetOrgByIdError, TData>(
    queryKeyFn({ path: '/api/SAF/Org/{id}', operationId: 'sAFGetOrgById', variables }),
    ({ signal }) => fetchSAFGetOrgById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SAFGetOrgQueryParams = {
  search?: string;
};

export type SAFGetOrgHeaders = {
  ['x-api-version']?: string;
};

export type SAFGetOrgError = Fetcher.ErrorWrapper<undefined>;

export type SAFGetOrgVariables = {
  headers?: SAFGetOrgHeaders;
  queryParams?: SAFGetOrgQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSAFGetOrg = (variables: SAFGetOrgVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SAFGetOrgError, undefined, SAFGetOrgHeaders, SAFGetOrgQueryParams, {}>({
    url: '/api/SAF/Org',
    method: 'get',
    ...variables,
    signal
  });

export const useSAFGetOrg = <TData = undefined>(
  variables: SAFGetOrgVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SAFGetOrgError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SAFGetOrgError, TData>(
    queryKeyFn({ path: '/api/SAF/Org', operationId: 'sAFGetOrg', variables }),
    ({ signal }) => fetchSAFGetOrg({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchedulePostMainExecuteHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostMainExecuteError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostMainExecuteVariables = {
  body?: Schemas.PostKeyDto;
  headers?: SchedulePostMainExecuteHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostMainExecute = (variables: SchedulePostMainExecuteVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchedulePostMainExecuteError, Schemas.PostKeyDto, SchedulePostMainExecuteHeaders, {}, {}>({
    url: '/api/Schedule/Main/Execute',
    method: 'post',
    ...variables,
    signal
  });

export const useSchedulePostMainExecute = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SchedulePostMainExecuteError, SchedulePostMainExecuteVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SchedulePostMainExecuteError, SchedulePostMainExecuteVariables>(
    (variables: SchedulePostMainExecuteVariables) => fetchSchedulePostMainExecute({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchedulePostImprovementGoalsActivateSmartStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostImprovementGoalsActivateSmartStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostImprovementGoalsActivateSmartStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: SchedulePostImprovementGoalsActivateSmartStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostImprovementGoalsActivateSmartStrategies = (
  variables: SchedulePostImprovementGoalsActivateSmartStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SchedulePostImprovementGoalsActivateSmartStrategiesError,
    Schemas.PostKeyDto,
    SchedulePostImprovementGoalsActivateSmartStrategiesHeaders,
    {},
    {}
  >({ url: '/api/Schedule/ImprovementGoals/ActivateSmartStrategies', method: 'post', ...variables, signal });

export const useSchedulePostImprovementGoalsActivateSmartStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchedulePostImprovementGoalsActivateSmartStrategiesError,
      SchedulePostImprovementGoalsActivateSmartStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SchedulePostImprovementGoalsActivateSmartStrategiesError,
    SchedulePostImprovementGoalsActivateSmartStrategiesVariables
  >(
    (variables: SchedulePostImprovementGoalsActivateSmartStrategiesVariables) =>
      fetchSchedulePostImprovementGoalsActivateSmartStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchedulePostImprovementGoalsExpiringSmartStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostImprovementGoalsExpiringSmartStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostImprovementGoalsExpiringSmartStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: SchedulePostImprovementGoalsExpiringSmartStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostImprovementGoalsExpiringSmartStrategies = (
  variables: SchedulePostImprovementGoalsExpiringSmartStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SchedulePostImprovementGoalsExpiringSmartStrategiesError,
    Schemas.PostKeyDto,
    SchedulePostImprovementGoalsExpiringSmartStrategiesHeaders,
    {},
    {}
  >({ url: '/api/Schedule/ImprovementGoals/ExpiringSmartStrategies', method: 'post', ...variables, signal });

export const useSchedulePostImprovementGoalsExpiringSmartStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchedulePostImprovementGoalsExpiringSmartStrategiesError,
      SchedulePostImprovementGoalsExpiringSmartStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SchedulePostImprovementGoalsExpiringSmartStrategiesError,
    SchedulePostImprovementGoalsExpiringSmartStrategiesVariables
  >(
    (variables: SchedulePostImprovementGoalsExpiringSmartStrategiesVariables) =>
      fetchSchedulePostImprovementGoalsExpiringSmartStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchedulePostImprovementGoalsAutomaticallyRenewStrategiesHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostImprovementGoalsAutomaticallyRenewStrategiesError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostImprovementGoalsAutomaticallyRenewStrategiesVariables = {
  body?: Schemas.PostKeyDto;
  headers?: SchedulePostImprovementGoalsAutomaticallyRenewStrategiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostImprovementGoalsAutomaticallyRenewStrategies = (
  variables: SchedulePostImprovementGoalsAutomaticallyRenewStrategiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SchedulePostImprovementGoalsAutomaticallyRenewStrategiesError,
    Schemas.PostKeyDto,
    SchedulePostImprovementGoalsAutomaticallyRenewStrategiesHeaders,
    {},
    {}
  >({ url: '/api/Schedule/ImprovementGoals/AutomaticallyRenewStrategies', method: 'post', ...variables, signal });

export const useSchedulePostImprovementGoalsAutomaticallyRenewStrategies = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchedulePostImprovementGoalsAutomaticallyRenewStrategiesError,
      SchedulePostImprovementGoalsAutomaticallyRenewStrategiesVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SchedulePostImprovementGoalsAutomaticallyRenewStrategiesError,
    SchedulePostImprovementGoalsAutomaticallyRenewStrategiesVariables
  >(
    (variables: SchedulePostImprovementGoalsAutomaticallyRenewStrategiesVariables) =>
      fetchSchedulePostImprovementGoalsAutomaticallyRenewStrategies({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchedulePostReportsHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostReportsError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostReportsVariables = {
  body?: Schemas.ScheduleReportDto;
  headers?: SchedulePostReportsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostReports = (variables: SchedulePostReportsVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchedulePostReportsError, Schemas.ScheduleReportDto, SchedulePostReportsHeaders, {}, {}>({
    url: '/api/Schedule/Reports',
    method: 'post',
    ...variables,
    signal
  });

export const useSchedulePostReports = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SchedulePostReportsError, SchedulePostReportsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SchedulePostReportsError, SchedulePostReportsVariables>(
    (variables: SchedulePostReportsVariables) => fetchSchedulePostReports({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleGetReportsHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleGetReportsError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleGetReportsVariables = {
  headers?: ScheduleGetReportsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleGetReports = (variables: ScheduleGetReportsVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.ScheduleReports, ScheduleGetReportsError, undefined, ScheduleGetReportsHeaders, {}, {}>({
    url: '/api/Schedule/Reports',
    method: 'get',
    ...variables,
    signal
  });

export const useScheduleGetReports = <TData = Schemas.ScheduleReports>(
  variables: ScheduleGetReportsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleReports, ScheduleGetReportsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleReports, ScheduleGetReportsError, TData>(
    queryKeyFn({ path: '/api/Schedule/Reports', operationId: 'scheduleGetReports', variables }),
    ({ signal }) => fetchScheduleGetReports({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleDeleteReportsByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleDeleteReportsByIdHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleDeleteReportsByIdError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleDeleteReportsByIdVariables = {
  headers?: ScheduleDeleteReportsByIdHeaders;
  pathParams: ScheduleDeleteReportsByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleDeleteReportsById = (variables: ScheduleDeleteReportsByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ScheduleDeleteReportsByIdError,
    undefined,
    ScheduleDeleteReportsByIdHeaders,
    {},
    ScheduleDeleteReportsByIdPathParams
  >({ url: '/api/Schedule/Reports/{id}', method: 'delete', ...variables, signal });

export const useScheduleDeleteReportsById = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ScheduleDeleteReportsByIdError, ScheduleDeleteReportsByIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ScheduleDeleteReportsByIdError, ScheduleDeleteReportsByIdVariables>(
    (variables: ScheduleDeleteReportsByIdVariables) =>
      fetchScheduleDeleteReportsById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleGetReportsByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleGetReportsByIdHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleGetReportsByIdError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleGetReportsByIdVariables = {
  headers?: ScheduleGetReportsByIdHeaders;
  pathParams: ScheduleGetReportsByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleGetReportsById = (variables: ScheduleGetReportsByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.ScheduleReportDto,
    ScheduleGetReportsByIdError,
    undefined,
    ScheduleGetReportsByIdHeaders,
    {},
    ScheduleGetReportsByIdPathParams
  >({ url: '/api/Schedule/Reports/{id}', method: 'get', ...variables, signal });

export const useScheduleGetReportsById = <TData = Schemas.ScheduleReportDto>(
  variables: ScheduleGetReportsByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleReportDto, ScheduleGetReportsByIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleReportDto, ScheduleGetReportsByIdError, TData>(
    queryKeyFn({ path: '/api/Schedule/Reports/{id}', operationId: 'scheduleGetReportsById', variables }),
    ({ signal }) => fetchScheduleGetReportsById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchedulePutReportsByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type SchedulePutReportsByIdHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePutReportsByIdError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePutReportsByIdVariables = {
  body?: Schemas.ScheduleReportDto;
  headers?: SchedulePutReportsByIdHeaders;
  pathParams: SchedulePutReportsByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePutReportsById = (variables: SchedulePutReportsByIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    SchedulePutReportsByIdError,
    Schemas.ScheduleReportDto,
    SchedulePutReportsByIdHeaders,
    {},
    SchedulePutReportsByIdPathParams
  >({ url: '/api/Schedule/Reports/{id}', method: 'put', ...variables, signal });

export const useSchedulePutReportsById = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, SchedulePutReportsByIdError, SchedulePutReportsByIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, SchedulePutReportsByIdError, SchedulePutReportsByIdVariables>(
    (variables: SchedulePutReportsByIdVariables) => fetchSchedulePutReportsById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ScheduleGetReportsDownloadByIdPathParams = {
  /**
   * @format int32
   */
  id: number;
};

export type ScheduleGetReportsDownloadByIdHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleGetReportsDownloadByIdError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleGetReportsDownloadByIdVariables = {
  headers?: ScheduleGetReportsDownloadByIdHeaders;
  pathParams: ScheduleGetReportsDownloadByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleGetReportsDownloadById = (
  variables: ScheduleGetReportsDownloadByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ScheduleGetReportsDownloadByIdError,
    undefined,
    ScheduleGetReportsDownloadByIdHeaders,
    {},
    ScheduleGetReportsDownloadByIdPathParams
  >({ url: '/api/Schedule/Reports/Download/{id}', method: 'get', ...variables, signal });

export const useScheduleGetReportsDownloadById = <TData = Blob>(
  variables: ScheduleGetReportsDownloadByIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<Blob, ScheduleGetReportsDownloadByIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ScheduleGetReportsDownloadByIdError, TData>(
    queryKeyFn({
      path: '/api/Schedule/Reports/Download/{id}',
      operationId: 'scheduleGetReportsDownloadById',
      variables
    }),
    ({ signal }) => fetchScheduleGetReportsDownloadById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleGetReportsReportTypesQueryParams = {
  /**
   * optional, only return ones that are schedulable, defaults to true
   *
   * @default true
   */
  schedulable?: boolean;
};

export type ScheduleGetReportsReportTypesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleGetReportsReportTypesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleGetReportsReportTypesVariables = {
  headers?: ScheduleGetReportsReportTypesHeaders;
  queryParams?: ScheduleGetReportsReportTypesQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchScheduleGetReportsReportTypes = (
  variables: ScheduleGetReportsReportTypesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ReportTypes,
    ScheduleGetReportsReportTypesError,
    undefined,
    ScheduleGetReportsReportTypesHeaders,
    ScheduleGetReportsReportTypesQueryParams,
    {}
  >({ url: '/api/Schedule/Reports/ReportTypes', method: 'get', ...variables, signal });

export const useScheduleGetReportsReportTypes = <TData = Schemas.ReportTypes>(
  variables: ScheduleGetReportsReportTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ReportTypes, ScheduleGetReportsReportTypesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ReportTypes, ScheduleGetReportsReportTypesError, TData>(
    queryKeyFn({ path: '/api/Schedule/Reports/ReportTypes', operationId: 'scheduleGetReportsReportTypes', variables }),
    ({ signal }) => fetchScheduleGetReportsReportTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ScheduleGetReportsFrequenciesHeaders = {
  ['x-api-version']?: string;
};

export type ScheduleGetReportsFrequenciesError = Fetcher.ErrorWrapper<undefined>;

export type ScheduleGetReportsFrequenciesVariables = {
  headers?: ScheduleGetReportsFrequenciesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchScheduleGetReportsFrequencies = (
  variables: ScheduleGetReportsFrequenciesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.ScheduleFrequencies,
    ScheduleGetReportsFrequenciesError,
    undefined,
    ScheduleGetReportsFrequenciesHeaders,
    {},
    {}
  >({ url: '/api/Schedule/Reports/Frequencies', method: 'get', ...variables, signal });

export const useScheduleGetReportsFrequencies = <TData = Schemas.ScheduleFrequencies>(
  variables: ScheduleGetReportsFrequenciesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ScheduleFrequencies, ScheduleGetReportsFrequenciesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.ScheduleFrequencies, ScheduleGetReportsFrequenciesError, TData>(
    queryKeyFn({ path: '/api/Schedule/Reports/Frequencies', operationId: 'scheduleGetReportsFrequencies', variables }),
    ({ signal }) => fetchScheduleGetReportsFrequencies({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchedulePostReportsRunReportJobsHeaders = {
  ['x-api-version']?: string;
};

export type SchedulePostReportsRunReportJobsError = Fetcher.ErrorWrapper<undefined>;

export type SchedulePostReportsRunReportJobsVariables = {
  body?: Schemas.PostKeyDto;
  headers?: SchedulePostReportsRunReportJobsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchedulePostReportsRunReportJobs = (
  variables: SchedulePostReportsRunReportJobsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SchedulePostReportsRunReportJobsError,
    Schemas.PostKeyDto,
    SchedulePostReportsRunReportJobsHeaders,
    {},
    {}
  >({ url: '/api/Schedule/Reports/RunReportJobs', method: 'post', ...variables, signal });

export const useSchedulePostReportsRunReportJobs = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SchedulePostReportsRunReportJobsError,
      SchedulePostReportsRunReportJobsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SchedulePostReportsRunReportJobsError,
    SchedulePostReportsRunReportJobsVariables
  >(
    (variables: SchedulePostReportsRunReportJobsVariables) =>
      fetchSchedulePostReportsRunReportJobs({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SchemaGetAuthorizedUserHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetAuthorizedUserError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetAuthorizedUserVariables = {
  headers?: SchemaGetAuthorizedUserHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetAuthorizedUser = (variables: SchemaGetAuthorizedUserVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetAuthorizedUserError, undefined, SchemaGetAuthorizedUserHeaders, {}, {}>({
    url: '/api/Schema/AuthorizedUser',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetAuthorizedUser = <TData = undefined>(
  variables: SchemaGetAuthorizedUserVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetAuthorizedUserError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetAuthorizedUserError, TData>(
    queryKeyFn({ path: '/api/Schema/AuthorizedUser', operationId: 'schemaGetAuthorizedUser', variables }),
    ({ signal }) => fetchSchemaGetAuthorizedUser({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetCmiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetCmiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetCmiVariables = {
  headers?: SchemaGetCmiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetCmi = (variables: SchemaGetCmiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetCmiError, undefined, SchemaGetCmiHeaders, {}, {}>({
    url: '/api/Schema/Cmi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetCmi = <TData = undefined>(
  variables: SchemaGetCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetCmiError, TData>(
    queryKeyFn({ path: '/api/Schema/Cmi', operationId: 'schemaGetCmi', variables }),
    ({ signal }) => fetchSchemaGetCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetConditionHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetConditionError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetConditionVariables = {
  headers?: SchemaGetConditionHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetCondition = (variables: SchemaGetConditionVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetConditionError, undefined, SchemaGetConditionHeaders, {}, {}>({
    url: '/api/Schema/Condition',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetCondition = <TData = undefined>(
  variables: SchemaGetConditionVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetConditionError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetConditionError, TData>(
    queryKeyFn({ path: '/api/Schema/Condition', operationId: 'schemaGetCondition', variables }),
    ({ signal }) => fetchSchemaGetCondition({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetDefaultDatesHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetDefaultDatesError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetDefaultDatesVariables = {
  headers?: SchemaGetDefaultDatesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetDefaultDates = (variables: SchemaGetDefaultDatesVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetDefaultDatesError, undefined, SchemaGetDefaultDatesHeaders, {}, {}>({
    url: '/api/Schema/DefaultDates',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetDefaultDates = <TData = undefined>(
  variables: SchemaGetDefaultDatesVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetDefaultDatesError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetDefaultDatesError, TData>(
    queryKeyFn({ path: '/api/Schema/DefaultDates', operationId: 'schemaGetDefaultDates', variables }),
    ({ signal }) => fetchSchemaGetDefaultDates({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetDocScoreHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetDocScoreError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetDocScoreVariables = {
  headers?: SchemaGetDocScoreHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetDocScore = (variables: SchemaGetDocScoreVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetDocScoreError, undefined, SchemaGetDocScoreHeaders, {}, {}>({
    url: '/api/Schema/DocScore',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetDocScore = <TData = undefined>(
  variables: SchemaGetDocScoreVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetDocScoreError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetDocScoreError, TData>(
    queryKeyFn({ path: '/api/Schema/DocScore', operationId: 'schemaGetDocScore', variables }),
    ({ signal }) => fetchSchemaGetDocScore({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetElixhauserMortalityHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetElixhauserMortalityError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetElixhauserMortalityVariables = {
  headers?: SchemaGetElixhauserMortalityHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetElixhauserMortality = (
  variables: SchemaGetElixhauserMortalityVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetElixhauserMortalityError, undefined, SchemaGetElixhauserMortalityHeaders, {}, {}>({
    url: '/api/Schema/ElixhauserMortality',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetElixhauserMortality = <TData = undefined>(
  variables: SchemaGetElixhauserMortalityVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SchemaGetElixhauserMortalityError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetElixhauserMortalityError, TData>(
    queryKeyFn({ path: '/api/Schema/ElixhauserMortality', operationId: 'schemaGetElixhauserMortality', variables }),
    ({ signal }) => fetchSchemaGetElixhauserMortality({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetElixhauserReadmissionHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetElixhauserReadmissionError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetElixhauserReadmissionVariables = {
  headers?: SchemaGetElixhauserReadmissionHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetElixhauserReadmission = (
  variables: SchemaGetElixhauserReadmissionVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetElixhauserReadmissionError, undefined, SchemaGetElixhauserReadmissionHeaders, {}, {}>({
    url: '/api/Schema/ElixhauserReadmission',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetElixhauserReadmission = <TData = undefined>(
  variables: SchemaGetElixhauserReadmissionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, SchemaGetElixhauserReadmissionError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetElixhauserReadmissionError, TData>(
    queryKeyFn({ path: '/api/Schema/ElixhauserReadmission', operationId: 'schemaGetElixhauserReadmission', variables }),
    ({ signal }) => fetchSchemaGetElixhauserReadmission({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetLosHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetLosError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetLosVariables = {
  headers?: SchemaGetLosHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetLos = (variables: SchemaGetLosVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetLosError, undefined, SchemaGetLosHeaders, {}, {}>({
    url: '/api/Schema/Los',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetLos = <TData = undefined>(
  variables: SchemaGetLosVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetLosError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetLosError, TData>(
    queryKeyFn({ path: '/api/Schema/Los', operationId: 'schemaGetLos', variables }),
    ({ signal }) => fetchSchemaGetLos({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetOverallPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetOverallPerformanceError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetOverallPerformanceVariables = {
  headers?: SchemaGetOverallPerformanceHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetOverallPerformance = (
  variables: SchemaGetOverallPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<undefined, SchemaGetOverallPerformanceError, undefined, SchemaGetOverallPerformanceHeaders, {}, {}>({
    url: '/api/Schema/OverallPerformance',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetOverallPerformance = <TData = undefined>(
  variables: SchemaGetOverallPerformanceVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetOverallPerformanceError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetOverallPerformanceError, TData>(
    queryKeyFn({ path: '/api/Schema/OverallPerformance', operationId: 'schemaGetOverallPerformance', variables }),
    ({ signal }) => fetchSchemaGetOverallPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetPsiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetPsiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetPsiVariables = {
  headers?: SchemaGetPsiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetPsi = (variables: SchemaGetPsiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetPsiError, undefined, SchemaGetPsiHeaders, {}, {}>({
    url: '/api/Schema/Psi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetPsi = <TData = undefined>(
  variables: SchemaGetPsiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetPsiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetPsiError, TData>(
    queryKeyFn({ path: '/api/Schema/Psi', operationId: 'schemaGetPsi', variables }),
    ({ signal }) => fetchSchemaGetPsi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetRafHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetRafError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetRafVariables = {
  headers?: SchemaGetRafHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetRaf = (variables: SchemaGetRafVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetRafError, undefined, SchemaGetRafHeaders, {}, {}>({
    url: '/api/Schema/Raf',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetRaf = <TData = undefined>(
  variables: SchemaGetRafVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetRafError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetRafError, TData>(
    queryKeyFn({ path: '/api/Schema/Raf', operationId: 'schemaGetRaf', variables }),
    ({ signal }) => fetchSchemaGetRaf({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetSeverityCmiHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetSeverityCmiError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetSeverityCmiVariables = {
  headers?: SchemaGetSeverityCmiHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetSeverityCmi = (variables: SchemaGetSeverityCmiVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetSeverityCmiError, undefined, SchemaGetSeverityCmiHeaders, {}, {}>({
    url: '/api/Schema/SeverityCmi',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetSeverityCmi = <TData = undefined>(
  variables: SchemaGetSeverityCmiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetSeverityCmiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetSeverityCmiError, TData>(
    queryKeyFn({ path: '/api/Schema/SeverityCmi', operationId: 'schemaGetSeverityCmi', variables }),
    ({ signal }) => fetchSchemaGetSeverityCmi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SchemaGetUserTestDtoHeaders = {
  ['x-api-version']?: string;
};

export type SchemaGetUserTestDtoError = Fetcher.ErrorWrapper<undefined>;

export type SchemaGetUserTestDtoVariables = {
  headers?: SchemaGetUserTestDtoHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSchemaGetUserTestDto = (variables: SchemaGetUserTestDtoVariables, signal?: AbortSignal) =>
  apiFetch<undefined, SchemaGetUserTestDtoError, undefined, SchemaGetUserTestDtoHeaders, {}, {}>({
    url: '/api/Schema/UserTestDto',
    method: 'get',
    ...variables,
    signal
  });

export const useSchemaGetUserTestDto = <TData = undefined>(
  variables: SchemaGetUserTestDtoVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, SchemaGetUserTestDtoError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, SchemaGetUserTestDtoError, TData>(
    queryKeyFn({ path: '/api/Schema/UserTestDto', operationId: 'schemaGetUserTestDto', variables }),
    ({ signal }) => fetchSchemaGetUserTestDto({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsDeleteByUserIdHistogramFiltersByIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsDeleteByUserIdHistogramFiltersByIdHeaders = {
  ['x-api-version']?: string;
};

export type SettingsDeleteByUserIdHistogramFiltersByIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsDeleteByUserIdHistogramFiltersByIdVariables = {
  headers?: SettingsDeleteByUserIdHistogramFiltersByIdHeaders;
  pathParams: SettingsDeleteByUserIdHistogramFiltersByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsDeleteByUserIdHistogramFiltersById = (
  variables: SettingsDeleteByUserIdHistogramFiltersByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SettingsDeleteByUserIdHistogramFiltersByIdError,
    undefined,
    SettingsDeleteByUserIdHistogramFiltersByIdHeaders,
    {},
    SettingsDeleteByUserIdHistogramFiltersByIdPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters/{id}', method: 'delete', ...variables, signal });

export const useSettingsDeleteByUserIdHistogramFiltersById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SettingsDeleteByUserIdHistogramFiltersByIdError,
      SettingsDeleteByUserIdHistogramFiltersByIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SettingsDeleteByUserIdHistogramFiltersByIdError,
    SettingsDeleteByUserIdHistogramFiltersByIdVariables
  >(
    (variables: SettingsDeleteByUserIdHistogramFiltersByIdVariables) =>
      fetchSettingsDeleteByUserIdHistogramFiltersById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsGetByUserIdHistogramFiltersByIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsGetByUserIdHistogramFiltersByIdHeaders = {
  ['x-api-version']?: string;
};

export type SettingsGetByUserIdHistogramFiltersByIdError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsGetByUserIdHistogramFiltersByIdResponse = Schemas.HistogramFilterDto[];

export type SettingsGetByUserIdHistogramFiltersByIdVariables = {
  headers?: SettingsGetByUserIdHistogramFiltersByIdHeaders;
  pathParams: SettingsGetByUserIdHistogramFiltersByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsGetByUserIdHistogramFiltersById = (
  variables: SettingsGetByUserIdHistogramFiltersByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    SettingsGetByUserIdHistogramFiltersByIdResponse,
    SettingsGetByUserIdHistogramFiltersByIdError,
    undefined,
    SettingsGetByUserIdHistogramFiltersByIdHeaders,
    {},
    SettingsGetByUserIdHistogramFiltersByIdPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters/{id}', method: 'get', ...variables, signal });

export const useSettingsGetByUserIdHistogramFiltersById = <TData = SettingsGetByUserIdHistogramFiltersByIdResponse>(
  variables: SettingsGetByUserIdHistogramFiltersByIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      SettingsGetByUserIdHistogramFiltersByIdResponse,
      SettingsGetByUserIdHistogramFiltersByIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    SettingsGetByUserIdHistogramFiltersByIdResponse,
    SettingsGetByUserIdHistogramFiltersByIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Settings/{userId}/Histogram/Filters/{id}',
      operationId: 'settingsGetByUserIdHistogramFiltersById',
      variables
    }),
    ({ signal }) => fetchSettingsGetByUserIdHistogramFiltersById({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsPutByUserIdHistogramFiltersByIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsPutByUserIdHistogramFiltersByIdHeaders = {
  ['x-api-version']?: string;
};

export type SettingsPutByUserIdHistogramFiltersByIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsPutByUserIdHistogramFiltersByIdVariables = {
  body: Schemas.HistogramNewFilterRequestDto;
  headers?: SettingsPutByUserIdHistogramFiltersByIdHeaders;
  pathParams: SettingsPutByUserIdHistogramFiltersByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsPutByUserIdHistogramFiltersById = (
  variables: SettingsPutByUserIdHistogramFiltersByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SettingsPutByUserIdHistogramFiltersByIdError,
    Schemas.HistogramNewFilterRequestDto,
    SettingsPutByUserIdHistogramFiltersByIdHeaders,
    {},
    SettingsPutByUserIdHistogramFiltersByIdPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters/{id}', method: 'put', ...variables, signal });

export const useSettingsPutByUserIdHistogramFiltersById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SettingsPutByUserIdHistogramFiltersByIdError,
      SettingsPutByUserIdHistogramFiltersByIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SettingsPutByUserIdHistogramFiltersByIdError,
    SettingsPutByUserIdHistogramFiltersByIdVariables
  >(
    (variables: SettingsPutByUserIdHistogramFiltersByIdVariables) =>
      fetchSettingsPutByUserIdHistogramFiltersById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsPatchByUserIdHistogramFiltersByIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
  /**
   * @format int32
   */
  id: number;
};

export type SettingsPatchByUserIdHistogramFiltersByIdHeaders = {
  ['x-api-version']?: string;
};

export type SettingsPatchByUserIdHistogramFiltersByIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsPatchByUserIdHistogramFiltersByIdVariables = {
  body?: Schemas.HistogramSavedFilter;
  headers?: SettingsPatchByUserIdHistogramFiltersByIdHeaders;
  pathParams: SettingsPatchByUserIdHistogramFiltersByIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsPatchByUserIdHistogramFiltersById = (
  variables: SettingsPatchByUserIdHistogramFiltersByIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SettingsPatchByUserIdHistogramFiltersByIdError,
    Schemas.HistogramSavedFilter,
    SettingsPatchByUserIdHistogramFiltersByIdHeaders,
    {},
    SettingsPatchByUserIdHistogramFiltersByIdPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters/{id}', method: 'patch', ...variables, signal });

export const useSettingsPatchByUserIdHistogramFiltersById = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SettingsPatchByUserIdHistogramFiltersByIdError,
      SettingsPatchByUserIdHistogramFiltersByIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SettingsPatchByUserIdHistogramFiltersByIdError,
    SettingsPatchByUserIdHistogramFiltersByIdVariables
  >(
    (variables: SettingsPatchByUserIdHistogramFiltersByIdVariables) =>
      fetchSettingsPatchByUserIdHistogramFiltersById({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsPostByUserIdHistogramFiltersDuplicatePathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsPostByUserIdHistogramFiltersDuplicateQueryParams = {
  /**
   * @format int32
   */
  sourceId: number;
  newName: string;
};

export type SettingsPostByUserIdHistogramFiltersDuplicateHeaders = {
  ['x-api-version']?: string;
};

export type SettingsPostByUserIdHistogramFiltersDuplicateError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsPostByUserIdHistogramFiltersDuplicateVariables = {
  headers?: SettingsPostByUserIdHistogramFiltersDuplicateHeaders;
  pathParams: SettingsPostByUserIdHistogramFiltersDuplicatePathParams;
  queryParams: SettingsPostByUserIdHistogramFiltersDuplicateQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsPostByUserIdHistogramFiltersDuplicate = (
  variables: SettingsPostByUserIdHistogramFiltersDuplicateVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    SettingsPostByUserIdHistogramFiltersDuplicateError,
    undefined,
    SettingsPostByUserIdHistogramFiltersDuplicateHeaders,
    SettingsPostByUserIdHistogramFiltersDuplicateQueryParams,
    SettingsPostByUserIdHistogramFiltersDuplicatePathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters/Duplicate', method: 'post', ...variables, signal });

export const useSettingsPostByUserIdHistogramFiltersDuplicate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      SettingsPostByUserIdHistogramFiltersDuplicateError,
      SettingsPostByUserIdHistogramFiltersDuplicateVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    SettingsPostByUserIdHistogramFiltersDuplicateError,
    SettingsPostByUserIdHistogramFiltersDuplicateVariables
  >(
    (variables: SettingsPostByUserIdHistogramFiltersDuplicateVariables) =>
      fetchSettingsPostByUserIdHistogramFiltersDuplicate({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SettingsGetByUserIdHistogramFiltersPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsGetByUserIdHistogramFiltersHeaders = {
  ['x-api-version']?: string;
};

export type SettingsGetByUserIdHistogramFiltersError = Fetcher.ErrorWrapper<
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsGetByUserIdHistogramFiltersResponse = Schemas.HistogramSavedFiltersDto[];

export type SettingsGetByUserIdHistogramFiltersVariables = {
  headers?: SettingsGetByUserIdHistogramFiltersHeaders;
  pathParams: SettingsGetByUserIdHistogramFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsGetByUserIdHistogramFilters = (
  variables: SettingsGetByUserIdHistogramFiltersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    SettingsGetByUserIdHistogramFiltersResponse,
    SettingsGetByUserIdHistogramFiltersError,
    undefined,
    SettingsGetByUserIdHistogramFiltersHeaders,
    {},
    SettingsGetByUserIdHistogramFiltersPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters', method: 'get', ...variables, signal });

export const useSettingsGetByUserIdHistogramFilters = <TData = SettingsGetByUserIdHistogramFiltersResponse>(
  variables: SettingsGetByUserIdHistogramFiltersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      SettingsGetByUserIdHistogramFiltersResponse,
      SettingsGetByUserIdHistogramFiltersError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    SettingsGetByUserIdHistogramFiltersResponse,
    SettingsGetByUserIdHistogramFiltersError,
    TData
  >(
    queryKeyFn({
      path: '/api/Settings/{userId}/Histogram/Filters',
      operationId: 'settingsGetByUserIdHistogramFilters',
      variables
    }),
    ({ signal }) => fetchSettingsGetByUserIdHistogramFilters({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type SettingsPostByUserIdHistogramFiltersPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type SettingsPostByUserIdHistogramFiltersHeaders = {
  ['x-api-version']?: string;
};

export type SettingsPostByUserIdHistogramFiltersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 401;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 403;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type SettingsPostByUserIdHistogramFiltersVariables = {
  body: Schemas.HistogramNewFilterRequestDto;
  headers?: SettingsPostByUserIdHistogramFiltersHeaders;
  pathParams: SettingsPostByUserIdHistogramFiltersPathParams;
} & ApiContext['fetcherOptions'];

export const fetchSettingsPostByUserIdHistogramFilters = (
  variables: SettingsPostByUserIdHistogramFiltersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.HistogramSavedFilter,
    SettingsPostByUserIdHistogramFiltersError,
    Schemas.HistogramNewFilterRequestDto,
    SettingsPostByUserIdHistogramFiltersHeaders,
    {},
    SettingsPostByUserIdHistogramFiltersPathParams
  >({ url: '/api/Settings/{userId}/Histogram/Filters', method: 'post', ...variables, signal });

export const useSettingsPostByUserIdHistogramFilters = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.HistogramSavedFilter,
      SettingsPostByUserIdHistogramFiltersError,
      SettingsPostByUserIdHistogramFiltersVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.HistogramSavedFilter,
    SettingsPostByUserIdHistogramFiltersError,
    SettingsPostByUserIdHistogramFiltersVariables
  >(
    (variables: SettingsPostByUserIdHistogramFiltersVariables) =>
      fetchSettingsPostByUserIdHistogramFilters({ ...fetcherOptions, ...variables }),
    options
  );
};

export type SignupPostHeaders = {
  ['x-api-version']?: string;
};

export type SignupPostError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type SignupPostVariables = {
  body?: Schemas.SignupRequestDto;
  headers?: SignupPostHeaders;
} & ApiContext['fetcherOptions'];

export const fetchSignupPost = (variables: SignupPostVariables, signal?: AbortSignal) =>
  apiFetch<Schemas.SignupResponseDto, SignupPostError, Schemas.SignupRequestDto, SignupPostHeaders, {}, {}>({
    url: '/api/Signup',
    method: 'post',
    ...variables,
    signal
  });

export const useSignupPost = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.SignupResponseDto, SignupPostError, SignupPostVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<Schemas.SignupResponseDto, SignupPostError, SignupPostVariables>(
    (variables: SignupPostVariables) => fetchSignupPost({ ...fetcherOptions, ...variables }),
    options
  );
};

export type ReportsGetSystemExecutiveSummaryQueryParams = {
  /**
   * required
   *
   * @format date-time
   */
  reportDate?: string;
  /**
   * optional, org hierarchy ID of hospital
   *
   * @format int32
   */
  hospitalOrgId?: number;
  /**
   * optional, org hierarchy ID of physician group, hospital required if this is sent
   *
   * @format int32
   */
  physicianGroupOrgId?: number;
};

export type ReportsGetSystemExecutiveSummaryHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetSystemExecutiveSummaryError = Fetcher.ErrorWrapper<undefined>;

export type ReportsGetSystemExecutiveSummaryVariables = {
  headers?: ReportsGetSystemExecutiveSummaryHeaders;
  queryParams?: ReportsGetSystemExecutiveSummaryQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetSystemExecutiveSummary = (
  variables: ReportsGetSystemExecutiveSummaryVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    ReportsGetSystemExecutiveSummaryError,
    undefined,
    ReportsGetSystemExecutiveSummaryHeaders,
    ReportsGetSystemExecutiveSummaryQueryParams,
    {}
  >({ url: '/api/Reports/System/ExecutiveSummary', method: 'get', ...variables, signal });

export const useReportsGetSystemExecutiveSummary = <TData = undefined>(
  variables: ReportsGetSystemExecutiveSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, ReportsGetSystemExecutiveSummaryError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, ReportsGetSystemExecutiveSummaryError, TData>(
    queryKeyFn({
      path: '/api/Reports/System/ExecutiveSummary',
      operationId: 'reportsGetSystemExecutiveSummary',
      variables
    }),
    ({ signal }) => fetchReportsGetSystemExecutiveSummary({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsPostSystemMonthlyHeaders = {
  ['x-api-version']?: string;
};

export type ReportsPostSystemMonthlyError = Fetcher.ErrorWrapper<undefined>;

export type ReportsPostSystemMonthlyVariables = {
  body?: Schemas.RequestMonthlyReportRequest;
  headers?: ReportsPostSystemMonthlyHeaders;
} & ApiContext['fetcherOptions'];

export const fetchReportsPostSystemMonthly = (variables: ReportsPostSystemMonthlyVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    ReportsPostSystemMonthlyError,
    Schemas.RequestMonthlyReportRequest,
    ReportsPostSystemMonthlyHeaders,
    {},
    {}
  >({ url: '/api/Reports/System/Monthly', method: 'post', ...variables, signal });

export const useReportsPostSystemMonthly = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ReportsPostSystemMonthlyError, ReportsPostSystemMonthlyVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, ReportsPostSystemMonthlyError, ReportsPostSystemMonthlyVariables>(
    (variables: ReportsPostSystemMonthlyVariables) =>
      fetchReportsPostSystemMonthly({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TenantSettingsGetAuthConfigHeaders = {
  ['x-api-version']?: string;
};

export type TenantSettingsGetAuthConfigError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TenantSettingsGetAuthConfigVariables = {
  headers?: TenantSettingsGetAuthConfigHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTenantSettingsGetAuthConfig = (
  variables: TenantSettingsGetAuthConfigVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.DomainModelAuth0TenantConfig,
    TenantSettingsGetAuthConfigError,
    undefined,
    TenantSettingsGetAuthConfigHeaders,
    {},
    {}
  >({ url: '/api/TenantSettings/AuthConfig', method: 'get', ...variables, signal });

export const useTenantSettingsGetAuthConfig = <TData = Schemas.DomainModelAuth0TenantConfig>(
  variables: TenantSettingsGetAuthConfigVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.DomainModelAuth0TenantConfig, TenantSettingsGetAuthConfigError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.DomainModelAuth0TenantConfig, TenantSettingsGetAuthConfigError, TData>(
    queryKeyFn({ path: '/api/TenantSettings/AuthConfig', operationId: 'tenantSettingsGetAuthConfig', variables }),
    ({ signal }) => fetchTenantSettingsGetAuthConfig({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse =
  Schemas.TrainingProviderConditionTrainingSummary[];

export type TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables = {
  body?: Schemas.TrainingAssignTrainingRequest;
  headers?: TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders;
  pathParams: TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgId = (
  variables: TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
    Schemas.TrainingAssignTrainingRequest,
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders,
    {},
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams
  >({
    url: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
    method: 'put',
    ...variables,
    signal
  });

export const useTrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
      TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
      TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
    TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables
  >(
    (variables: TrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables) =>
      fetchTrainingPutAssignmentByPhysicianGroupOrgIdByProviderOrgId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse =
  Schemas.TrainingProviderConditionTrainingSummary[];

export type TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables = {
  headers?: TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders;
  pathParams: TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgId = (
  variables: TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
    undefined,
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdHeaders,
    {},
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdPathParams
  >({
    url: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgId = <
  TData = TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse
>(
  variables: TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
      TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdResponse,
    TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}',
      operationId: 'trainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgId',
      variables
    }),
    ({ signal }) =>
      fetchTrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
  /**
   * @format int32
   */
  providerOrgHierarchyId: number;
  /**
   * @format int32
   */
  conditionTrainingId: number;
};

export type TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdError =
  Fetcher.ErrorWrapper<{
    status: 400;
    payload: Schemas.MvcProblemDetails;
  }>;

export type TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdVariables = {
  headers?: TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdHeaders;
  pathParams: TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingId = (
  variables: TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdError,
    undefined,
    TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdHeaders,
    {},
    TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdPathParams
  >({
    url: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}/{conditionTrainingId}',
    method: 'delete',
    ...variables,
    signal
  });

export const useTrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdError,
      TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdError,
    TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdVariables
  >(
    (variables: TrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingIdVariables) =>
      fetchTrainingDeleteAssignmentByPhysicianGroupOrgIdByProviderOrgIdByConditionTrainingId({
        ...fetcherOptions,
        ...variables
      }),
    options
  );
};

export type TrainingGetAssignmentDefaultCreditMonthsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetAssignmentDefaultCreditMonthsError = Fetcher.ErrorWrapper<undefined>;

export type TrainingGetAssignmentDefaultCreditMonthsVariables = {
  headers?: TrainingGetAssignmentDefaultCreditMonthsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetAssignmentDefaultCreditMonths = (
  variables: TrainingGetAssignmentDefaultCreditMonthsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    number,
    TrainingGetAssignmentDefaultCreditMonthsError,
    undefined,
    TrainingGetAssignmentDefaultCreditMonthsHeaders,
    {},
    {}
  >({ url: '/api/Training/Assignment/DefaultCreditMonths', method: 'get', ...variables, signal });

export const useTrainingGetAssignmentDefaultCreditMonths = <TData = number>(
  variables: TrainingGetAssignmentDefaultCreditMonthsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<number, TrainingGetAssignmentDefaultCreditMonthsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<number, TrainingGetAssignmentDefaultCreditMonthsError, TData>(
    queryKeyFn({
      path: '/api/Training/Assignment/DefaultCreditMonths',
      operationId: 'trainingGetAssignmentDefaultCreditMonths',
      variables
    }),
    ({ signal }) => fetchTrainingGetAssignmentDefaultCreditMonths({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetAssignmentByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetAssignmentByPhysicianGroupOrgIdResponse = Schemas.TrainingProviderTrainingSummary[];

export type TrainingGetAssignmentByPhysicianGroupOrgIdVariables = {
  headers?: TrainingGetAssignmentByPhysicianGroupOrgIdHeaders;
  pathParams: TrainingGetAssignmentByPhysicianGroupOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetAssignmentByPhysicianGroupOrgId = (
  variables: TrainingGetAssignmentByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingGetAssignmentByPhysicianGroupOrgIdResponse,
    TrainingGetAssignmentByPhysicianGroupOrgIdError,
    undefined,
    TrainingGetAssignmentByPhysicianGroupOrgIdHeaders,
    {},
    TrainingGetAssignmentByPhysicianGroupOrgIdPathParams
  >({ url: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}', method: 'get', ...variables, signal });

export const useTrainingGetAssignmentByPhysicianGroupOrgId = <
  TData = TrainingGetAssignmentByPhysicianGroupOrgIdResponse
>(
  variables: TrainingGetAssignmentByPhysicianGroupOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      TrainingGetAssignmentByPhysicianGroupOrgIdResponse,
      TrainingGetAssignmentByPhysicianGroupOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    TrainingGetAssignmentByPhysicianGroupOrgIdResponse,
    TrainingGetAssignmentByPhysicianGroupOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}',
      operationId: 'trainingGetAssignmentByPhysicianGroupOrgId',
      variables
    }),
    ({ signal }) => fetchTrainingGetAssignmentByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutAssignmentByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutAssignmentByPhysicianGroupOrgIdResponse = Schemas.TrainingProviderTrainingSummary[];

export type TrainingPutAssignmentByPhysicianGroupOrgIdVariables = {
  body?: Schemas.TrainingAssignGroupTrainingRequest;
  headers?: TrainingPutAssignmentByPhysicianGroupOrgIdHeaders;
  pathParams: TrainingPutAssignmentByPhysicianGroupOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutAssignmentByPhysicianGroupOrgId = (
  variables: TrainingPutAssignmentByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    TrainingPutAssignmentByPhysicianGroupOrgIdResponse,
    TrainingPutAssignmentByPhysicianGroupOrgIdError,
    Schemas.TrainingAssignGroupTrainingRequest,
    TrainingPutAssignmentByPhysicianGroupOrgIdHeaders,
    {},
    TrainingPutAssignmentByPhysicianGroupOrgIdPathParams
  >({ url: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}', method: 'put', ...variables, signal });

export const useTrainingPutAssignmentByPhysicianGroupOrgId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      TrainingPutAssignmentByPhysicianGroupOrgIdResponse,
      TrainingPutAssignmentByPhysicianGroupOrgIdError,
      TrainingPutAssignmentByPhysicianGroupOrgIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    TrainingPutAssignmentByPhysicianGroupOrgIdResponse,
    TrainingPutAssignmentByPhysicianGroupOrgIdError,
    TrainingPutAssignmentByPhysicianGroupOrgIdVariables
  >(
    (variables: TrainingPutAssignmentByPhysicianGroupOrgIdVariables) =>
      fetchTrainingPutAssignmentByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPostAssignmentRemindersHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostAssignmentRemindersError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingPostAssignmentRemindersVariables = {
  body?: Schemas.TrainingSendReminderRequest;
  headers?: TrainingPostAssignmentRemindersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostAssignmentReminders = (
  variables: TrainingPostAssignmentRemindersVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostAssignmentRemindersError,
    Schemas.TrainingSendReminderRequest,
    TrainingPostAssignmentRemindersHeaders,
    {},
    {}
  >({ url: '/api/Training/Assignment/Reminders', method: 'post', ...variables, signal });

export const useTrainingPostAssignmentReminders = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPostAssignmentRemindersError,
      TrainingPostAssignmentRemindersVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPostAssignmentRemindersError,
    TrainingPostAssignmentRemindersVariables
  >(
    (variables: TrainingPostAssignmentRemindersVariables) =>
      fetchTrainingPostAssignmentReminders({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetConditionPerformanceQueryParams = {
  /**
   * OrgId for Admins
   *
   * @format int32
   */
  orgId?: number;
  /**
   * Number of conditions
   *
   * @format int32
   * @default 2147483647
   */
  count?: number;
  /**
   * Filter only conditions with training content
   *
   * @default false
   */
  withTraining?: boolean;
};

export type TrainingGetConditionPerformanceHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetConditionPerformanceError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetConditionPerformanceVariables = {
  headers?: TrainingGetConditionPerformanceHeaders;
  queryParams?: TrainingGetConditionPerformanceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetConditionPerformance = (
  variables: TrainingGetConditionPerformanceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingConditionMetricConditionsMetricDto,
    TrainingGetConditionPerformanceError,
    undefined,
    TrainingGetConditionPerformanceHeaders,
    TrainingGetConditionPerformanceQueryParams,
    {}
  >({ url: '/api/Training/ConditionPerformance', method: 'get', ...variables, signal });

export const useTrainingGetConditionPerformance = <TData = Schemas.TrainingConditionMetricConditionsMetricDto>(
  variables: TrainingGetConditionPerformanceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingConditionMetricConditionsMetricDto,
      TrainingGetConditionPerformanceError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingConditionMetricConditionsMetricDto,
    TrainingGetConditionPerformanceError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/ConditionPerformance',
      operationId: 'trainingGetConditionPerformance',
      variables
    }),
    ({ signal }) => fetchTrainingGetConditionPerformance({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPostContentPocketCardsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostContentPocketCardsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostContentPocketCardsVariables = {
  body?: Schemas.TrainingPocketCard;
  headers?: TrainingPostContentPocketCardsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostContentPocketCards = (
  variables: TrainingPostContentPocketCardsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostContentPocketCardsError,
    Schemas.TrainingPocketCard,
    TrainingPostContentPocketCardsHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/PocketCards', method: 'post', ...variables, signal });

export const useTrainingPostContentPocketCards = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPostContentPocketCardsError,
      TrainingPostContentPocketCardsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPostContentPocketCardsError,
    TrainingPostContentPocketCardsVariables
  >(
    (variables: TrainingPostContentPocketCardsVariables) =>
      fetchTrainingPostContentPocketCards({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPostContentQuestionsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostContentQuestionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostContentQuestionsVariables = {
  body?: Schemas.TrainingQuestion;
  headers?: TrainingPostContentQuestionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostContentQuestions = (
  variables: TrainingPostContentQuestionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostContentQuestionsError,
    Schemas.TrainingQuestion,
    TrainingPostContentQuestionsHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/Questions', method: 'post', ...variables, signal });

export const useTrainingPostContentQuestions = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, TrainingPostContentQuestionsError, TrainingPostContentQuestionsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, TrainingPostContentQuestionsError, TrainingPostContentQuestionsVariables>(
    (variables: TrainingPostContentQuestionsVariables) =>
      fetchTrainingPostContentQuestions({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPostContentSpecialtyBasketsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostContentSpecialtyBasketsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostContentSpecialtyBasketsVariables = {
  body: Schemas.TrainingSpecialtyBasket;
  headers?: TrainingPostContentSpecialtyBasketsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostContentSpecialtyBaskets = (
  variables: TrainingPostContentSpecialtyBasketsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostContentSpecialtyBasketsError,
    Schemas.TrainingSpecialtyBasket,
    TrainingPostContentSpecialtyBasketsHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/SpecialtyBaskets', method: 'post', ...variables, signal });

export const useTrainingPostContentSpecialtyBaskets = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPostContentSpecialtyBasketsError,
      TrainingPostContentSpecialtyBasketsVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPostContentSpecialtyBasketsError,
    TrainingPostContentSpecialtyBasketsVariables
  >(
    (variables: TrainingPostContentSpecialtyBasketsVariables) =>
      fetchTrainingPostContentSpecialtyBaskets({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentSpecialtyBasketsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentSpecialtyBasketsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentSpecialtyBasketsVariables = {
  headers?: TrainingGetContentSpecialtyBasketsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentSpecialtyBaskets = (
  variables: TrainingGetContentSpecialtyBasketsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBaskets,
    TrainingGetContentSpecialtyBasketsError,
    undefined,
    TrainingGetContentSpecialtyBasketsHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/SpecialtyBaskets', method: 'get', ...variables, signal });

export const useTrainingGetContentSpecialtyBaskets = <TData = Schemas.TrainingSpecialtyBaskets>(
  variables: TrainingGetContentSpecialtyBasketsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingSpecialtyBaskets, TrainingGetContentSpecialtyBasketsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingSpecialtyBaskets, TrainingGetContentSpecialtyBasketsError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/SpecialtyBaskets',
      operationId: 'trainingGetContentSpecialtyBaskets',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentSpecialtyBaskets({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPostContentTrainingVideosHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostContentTrainingVideosError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostContentTrainingVideosVariables = {
  body?: Schemas.TrainingVideo;
  headers?: TrainingPostContentTrainingVideosHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostContentTrainingVideos = (
  variables: TrainingPostContentTrainingVideosVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostContentTrainingVideosError,
    Schemas.TrainingVideo,
    TrainingPostContentTrainingVideosHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/TrainingVideos', method: 'post', ...variables, signal });

export const useTrainingPostContentTrainingVideos = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPostContentTrainingVideosError,
      TrainingPostContentTrainingVideosVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPostContentTrainingVideosError,
    TrainingPostContentTrainingVideosVariables
  >(
    (variables: TrainingPostContentTrainingVideosVariables) =>
      fetchTrainingPostContentTrainingVideos({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentConditionsByConditionIdPathParams = {
  /**
   * @format int32
   */
  conditionId: number;
};

export type TrainingGetContentConditionsByConditionIdQueryParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId?: number;
  /**
   * optional query param for mobile-specific response behavior
   *
   * @default false
   */
  mobile?: boolean;
};

export type TrainingGetContentConditionsByConditionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentConditionsByConditionIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingGetContentConditionsByConditionIdVariables = {
  headers?: TrainingGetContentConditionsByConditionIdHeaders;
  pathParams: TrainingGetContentConditionsByConditionIdPathParams;
  queryParams?: TrainingGetContentConditionsByConditionIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentConditionsByConditionId = (
  variables: TrainingGetContentConditionsByConditionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingConditionReference,
    TrainingGetContentConditionsByConditionIdError,
    undefined,
    TrainingGetContentConditionsByConditionIdHeaders,
    TrainingGetContentConditionsByConditionIdQueryParams,
    TrainingGetContentConditionsByConditionIdPathParams
  >({ url: '/api/Training/Content/Conditions/{conditionId}', method: 'get', ...variables, signal });

export const useTrainingGetContentConditionsByConditionId = <TData = Schemas.TrainingConditionReference>(
  variables: TrainingGetContentConditionsByConditionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingConditionReference,
      TrainingGetContentConditionsByConditionIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingConditionReference, TrainingGetContentConditionsByConditionIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/Conditions/{conditionId}',
      operationId: 'trainingGetContentConditionsByConditionId',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentConditionsByConditionId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetContentContentConditionsHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentContentConditionsError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentContentConditionsVariables = {
  headers?: TrainingGetContentContentConditionsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentContentConditions = (
  variables: TrainingGetContentContentConditionsVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingContentConditions,
    TrainingGetContentContentConditionsError,
    undefined,
    TrainingGetContentContentConditionsHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/ContentConditions', method: 'get', ...variables, signal });

export const useTrainingGetContentContentConditions = <TData = Schemas.TrainingContentConditions>(
  variables: TrainingGetContentContentConditionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingContentConditions, TrainingGetContentContentConditionsError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingContentConditions, TrainingGetContentContentConditionsError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/ContentConditions',
      operationId: 'trainingGetContentContentConditions',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentContentConditions({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetContentContentConditionsWithTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentContentConditionsWithTrainingContentError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentContentConditionsWithTrainingContentVariables = {
  headers?: TrainingGetContentContentConditionsWithTrainingContentHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentContentConditionsWithTrainingContent = (
  variables: TrainingGetContentContentConditionsWithTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingContentConditions,
    TrainingGetContentContentConditionsWithTrainingContentError,
    undefined,
    TrainingGetContentContentConditionsWithTrainingContentHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/ContentConditionsWithTrainingContent', method: 'get', ...variables, signal });

export const useTrainingGetContentContentConditionsWithTrainingContent = <TData = Schemas.TrainingContentConditions>(
  variables: TrainingGetContentContentConditionsWithTrainingContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingContentConditions,
      TrainingGetContentContentConditionsWithTrainingContentError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingContentConditions,
    TrainingGetContentContentConditionsWithTrainingContentError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Content/ContentConditionsWithTrainingContent',
      operationId: 'trainingGetContentContentConditionsWithTrainingContent',
      variables
    }),
    ({ signal }) =>
      fetchTrainingGetContentContentConditionsWithTrainingContent({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetContentFundamentalSpecialtiesHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentFundamentalSpecialtiesError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentFundamentalSpecialtiesVariables = {
  headers?: TrainingGetContentFundamentalSpecialtiesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentFundamentalSpecialties = (
  variables: TrainingGetContentFundamentalSpecialtiesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingFundamentalSpecialties,
    TrainingGetContentFundamentalSpecialtiesError,
    undefined,
    TrainingGetContentFundamentalSpecialtiesHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/FundamentalSpecialties', method: 'get', ...variables, signal });

export const useTrainingGetContentFundamentalSpecialties = <TData = Schemas.TrainingFundamentalSpecialties>(
  variables: TrainingGetContentFundamentalSpecialtiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingFundamentalSpecialties,
      TrainingGetContentFundamentalSpecialtiesError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingFundamentalSpecialties,
    TrainingGetContentFundamentalSpecialtiesError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Content/FundamentalSpecialties',
      operationId: 'trainingGetContentFundamentalSpecialties',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentFundamentalSpecialties({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetContentPocketCardsByPocketCardIdPathParams = {
  /**
   * @format int32
   */
  pocketCardId: number;
};

export type TrainingGetContentPocketCardsByPocketCardIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentPocketCardsByPocketCardIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentPocketCardsByPocketCardIdVariables = {
  headers?: TrainingGetContentPocketCardsByPocketCardIdHeaders;
  pathParams: TrainingGetContentPocketCardsByPocketCardIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentPocketCardsByPocketCardId = (
  variables: TrainingGetContentPocketCardsByPocketCardIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingPocketCard,
    TrainingGetContentPocketCardsByPocketCardIdError,
    undefined,
    TrainingGetContentPocketCardsByPocketCardIdHeaders,
    {},
    TrainingGetContentPocketCardsByPocketCardIdPathParams
  >({ url: '/api/Training/Content/PocketCards/{pocketCardId}', method: 'get', ...variables, signal });

export const useTrainingGetContentPocketCardsByPocketCardId = <TData = Schemas.TrainingPocketCard>(
  variables: TrainingGetContentPocketCardsByPocketCardIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingPocketCard, TrainingGetContentPocketCardsByPocketCardIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingPocketCard, TrainingGetContentPocketCardsByPocketCardIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/PocketCards/{pocketCardId}',
      operationId: 'trainingGetContentPocketCardsByPocketCardId',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentPocketCardsByPocketCardId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutContentPocketCardsByPocketCardIdPathParams = {
  pocketCardId: string;
};

export type TrainingPutContentPocketCardsByPocketCardIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutContentPocketCardsByPocketCardIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutContentPocketCardsByPocketCardIdVariables = {
  body?: Schemas.TrainingPocketCard;
  headers?: TrainingPutContentPocketCardsByPocketCardIdHeaders;
  pathParams: TrainingPutContentPocketCardsByPocketCardIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutContentPocketCardsByPocketCardId = (
  variables: TrainingPutContentPocketCardsByPocketCardIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutContentPocketCardsByPocketCardIdError,
    Schemas.TrainingPocketCard,
    TrainingPutContentPocketCardsByPocketCardIdHeaders,
    {},
    TrainingPutContentPocketCardsByPocketCardIdPathParams
  >({ url: '/api/Training/Content/PocketCards/{pocketCardId}', method: 'put', ...variables, signal });

export const useTrainingPutContentPocketCardsByPocketCardId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutContentPocketCardsByPocketCardIdError,
      TrainingPutContentPocketCardsByPocketCardIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutContentPocketCardsByPocketCardIdError,
    TrainingPutContentPocketCardsByPocketCardIdVariables
  >(
    (variables: TrainingPutContentPocketCardsByPocketCardIdVariables) =>
      fetchTrainingPutContentPocketCardsByPocketCardId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentQuestionsByQuestionIdPathParams = {
  /**
   * @format int32
   */
  questionId: number;
};

export type TrainingGetContentQuestionsByQuestionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentQuestionsByQuestionIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentQuestionsByQuestionIdVariables = {
  headers?: TrainingGetContentQuestionsByQuestionIdHeaders;
  pathParams: TrainingGetContentQuestionsByQuestionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentQuestionsByQuestionId = (
  variables: TrainingGetContentQuestionsByQuestionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingQuestion,
    TrainingGetContentQuestionsByQuestionIdError,
    undefined,
    TrainingGetContentQuestionsByQuestionIdHeaders,
    {},
    TrainingGetContentQuestionsByQuestionIdPathParams
  >({ url: '/api/Training/Content/Questions/{questionId}', method: 'get', ...variables, signal });

export const useTrainingGetContentQuestionsByQuestionId = <TData = Schemas.TrainingQuestion>(
  variables: TrainingGetContentQuestionsByQuestionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingQuestion, TrainingGetContentQuestionsByQuestionIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingQuestion, TrainingGetContentQuestionsByQuestionIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/Questions/{questionId}',
      operationId: 'trainingGetContentQuestionsByQuestionId',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentQuestionsByQuestionId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutContentQuestionsByQuestionIdPathParams = {
  questionId: string;
};

export type TrainingPutContentQuestionsByQuestionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutContentQuestionsByQuestionIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutContentQuestionsByQuestionIdVariables = {
  body?: Schemas.TrainingQuestion;
  headers?: TrainingPutContentQuestionsByQuestionIdHeaders;
  pathParams: TrainingPutContentQuestionsByQuestionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutContentQuestionsByQuestionId = (
  variables: TrainingPutContentQuestionsByQuestionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutContentQuestionsByQuestionIdError,
    Schemas.TrainingQuestion,
    TrainingPutContentQuestionsByQuestionIdHeaders,
    {},
    TrainingPutContentQuestionsByQuestionIdPathParams
  >({ url: '/api/Training/Content/Questions/{questionId}', method: 'put', ...variables, signal });

export const useTrainingPutContentQuestionsByQuestionId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutContentQuestionsByQuestionIdError,
      TrainingPutContentQuestionsByQuestionIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutContentQuestionsByQuestionIdError,
    TrainingPutContentQuestionsByQuestionIdVariables
  >(
    (variables: TrainingPutContentQuestionsByQuestionIdVariables) =>
      fetchTrainingPutContentQuestionsByQuestionId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdPathParams = {
  /**
   * @format int32
   */
  specialtyBasketId: number;
};

export type TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdVariables = {
  headers?: TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdHeaders;
  pathParams: TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentSpecialtyBasketsBySpecialtyBasketId = (
  variables: TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBasket,
    TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdError,
    undefined,
    TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdHeaders,
    {},
    TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdPathParams
  >({ url: '/api/Training/Content/SpecialtyBaskets/{specialtyBasketId}', method: 'get', ...variables, signal });

export const useTrainingGetContentSpecialtyBasketsBySpecialtyBasketId = <TData = Schemas.TrainingSpecialtyBasket>(
  variables: TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingSpecialtyBasket,
      TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingSpecialtyBasket,
    TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Content/SpecialtyBaskets/{specialtyBasketId}',
      operationId: 'trainingGetContentSpecialtyBasketsBySpecialtyBasketId',
      variables
    }),
    ({ signal }) =>
      fetchTrainingGetContentSpecialtyBasketsBySpecialtyBasketId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdPathParams = {
  specialtyBasketId: string;
};

export type TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdVariables = {
  body: Schemas.TrainingSpecialtyBasket;
  headers?: TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdHeaders;
  pathParams: TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutContentSpecialtyBasketsBySpecialtyBasketId = (
  variables: TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdError,
    Schemas.TrainingSpecialtyBasket,
    TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdHeaders,
    {},
    TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdPathParams
  >({ url: '/api/Training/Content/SpecialtyBaskets/{specialtyBasketId}', method: 'put', ...variables, signal });

export const useTrainingPutContentSpecialtyBasketsBySpecialtyBasketId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdError,
      TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdError,
    TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdVariables
  >(
    (variables: TrainingPutContentSpecialtyBasketsBySpecialtyBasketIdVariables) =>
      fetchTrainingPutContentSpecialtyBasketsBySpecialtyBasketId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdPathParams = {
  /**
   * @format int32
   */
  contentConditionId: number;
};

export type TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdVariables = {
  headers?: TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdHeaders;
  pathParams: TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentSpecialtyBasketsForContentConditionByContentConditionId = (
  variables: TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingSpecialtyBaskets,
    TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdError,
    undefined,
    TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdHeaders,
    {},
    TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdPathParams
  >({
    url: '/api/Training/Content/SpecialtyBasketsForContentCondition/{contentConditionId}',
    method: 'get',
    ...variables,
    signal
  });

export const useTrainingGetContentSpecialtyBasketsForContentConditionByContentConditionId = <
  TData = Schemas.TrainingSpecialtyBaskets
>(
  variables: TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingSpecialtyBaskets,
      TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingSpecialtyBaskets,
    TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Content/SpecialtyBasketsForContentCondition/{contentConditionId}',
      operationId: 'trainingGetContentSpecialtyBasketsForContentConditionByContentConditionId',
      variables
    }),
    ({ signal }) =>
      fetchTrainingGetContentSpecialtyBasketsForContentConditionByContentConditionId(
        { ...fetcherOptions, ...variables },
        signal
      ),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetContentByContentConditionIdPathParams = {
  /**
   * @format int32
   */
  contentConditionId: number;
};

export type TrainingGetContentByContentConditionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentByContentConditionIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingGetContentByContentConditionIdVariables = {
  headers?: TrainingGetContentByContentConditionIdHeaders;
  pathParams: TrainingGetContentByContentConditionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentByContentConditionId = (
  variables: TrainingGetContentByContentConditionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingContent,
    TrainingGetContentByContentConditionIdError,
    undefined,
    TrainingGetContentByContentConditionIdHeaders,
    {},
    TrainingGetContentByContentConditionIdPathParams
  >({ url: '/api/Training/Content/{contentConditionId}', method: 'get', ...variables, signal });

export const useTrainingGetContentByContentConditionId = <TData = Schemas.TrainingTrainingContent>(
  variables: TrainingGetContentByContentConditionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingTrainingContent, TrainingGetContentByContentConditionIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingTrainingContent, TrainingGetContentByContentConditionIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/{contentConditionId}',
      operationId: 'trainingGetContentByContentConditionId',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentByContentConditionId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutContentByContentConditionIdPathParams = {
  contentConditionId: string;
};

export type TrainingPutContentByContentConditionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutContentByContentConditionIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutContentByContentConditionIdVariables = {
  body?: Schemas.TrainingTrainingContent;
  headers?: TrainingPutContentByContentConditionIdHeaders;
  pathParams: TrainingPutContentByContentConditionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutContentByContentConditionId = (
  variables: TrainingPutContentByContentConditionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutContentByContentConditionIdError,
    Schemas.TrainingTrainingContent,
    TrainingPutContentByContentConditionIdHeaders,
    {},
    TrainingPutContentByContentConditionIdPathParams
  >({ url: '/api/Training/Content/{contentConditionId}', method: 'put', ...variables, signal });

export const useTrainingPutContentByContentConditionId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutContentByContentConditionIdError,
      TrainingPutContentByContentConditionIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutContentByContentConditionIdError,
    TrainingPutContentByContentConditionIdVariables
  >(
    (variables: TrainingPutContentByContentConditionIdVariables) =>
      fetchTrainingPutContentByContentConditionId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentTrainingVideosByTrainingVideoIdPathParams = {
  /**
   * @format int32
   */
  trainingVideoId: number;
};

export type TrainingGetContentTrainingVideosByTrainingVideoIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentTrainingVideosByTrainingVideoIdError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetContentTrainingVideosByTrainingVideoIdVariables = {
  headers?: TrainingGetContentTrainingVideosByTrainingVideoIdHeaders;
  pathParams: TrainingGetContentTrainingVideosByTrainingVideoIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentTrainingVideosByTrainingVideoId = (
  variables: TrainingGetContentTrainingVideosByTrainingVideoIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingVideo,
    TrainingGetContentTrainingVideosByTrainingVideoIdError,
    undefined,
    TrainingGetContentTrainingVideosByTrainingVideoIdHeaders,
    {},
    TrainingGetContentTrainingVideosByTrainingVideoIdPathParams
  >({ url: '/api/Training/Content/TrainingVideos/{trainingVideoId}', method: 'get', ...variables, signal });

export const useTrainingGetContentTrainingVideosByTrainingVideoId = <TData = Schemas.TrainingVideo>(
  variables: TrainingGetContentTrainingVideosByTrainingVideoIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingVideo, TrainingGetContentTrainingVideosByTrainingVideoIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingVideo, TrainingGetContentTrainingVideosByTrainingVideoIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/TrainingVideos/{trainingVideoId}',
      operationId: 'trainingGetContentTrainingVideosByTrainingVideoId',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentTrainingVideosByTrainingVideoId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutContentTrainingVideosByTrainingVideoIdPathParams = {
  trainingVideoId: string;
};

export type TrainingPutContentTrainingVideosByTrainingVideoIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutContentTrainingVideosByTrainingVideoIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPutContentTrainingVideosByTrainingVideoIdVariables = {
  body?: Schemas.TrainingVideo;
  headers?: TrainingPutContentTrainingVideosByTrainingVideoIdHeaders;
  pathParams: TrainingPutContentTrainingVideosByTrainingVideoIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutContentTrainingVideosByTrainingVideoId = (
  variables: TrainingPutContentTrainingVideosByTrainingVideoIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutContentTrainingVideosByTrainingVideoIdError,
    Schemas.TrainingVideo,
    TrainingPutContentTrainingVideosByTrainingVideoIdHeaders,
    {},
    TrainingPutContentTrainingVideosByTrainingVideoIdPathParams
  >({ url: '/api/Training/Content/TrainingVideos/{trainingVideoId}', method: 'put', ...variables, signal });

export const useTrainingPutContentTrainingVideosByTrainingVideoId = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutContentTrainingVideosByTrainingVideoIdError,
      TrainingPutContentTrainingVideosByTrainingVideoIdVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutContentTrainingVideosByTrainingVideoIdError,
    TrainingPutContentTrainingVideosByTrainingVideoIdVariables
  >(
    (variables: TrainingPutContentTrainingVideosByTrainingVideoIdVariables) =>
      fetchTrainingPutContentTrainingVideosByTrainingVideoId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetContentVideoLinkTypesHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetContentVideoLinkTypesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingGetContentVideoLinkTypesVariables = {
  headers?: TrainingGetContentVideoLinkTypesHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetContentVideoLinkTypes = (
  variables: TrainingGetContentVideoLinkTypesVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingVideoLinkTypes,
    TrainingGetContentVideoLinkTypesError,
    undefined,
    TrainingGetContentVideoLinkTypesHeaders,
    {},
    {}
  >({ url: '/api/Training/Content/VideoLinkTypes', method: 'get', ...variables, signal });

export const useTrainingGetContentVideoLinkTypes = <TData = Schemas.TrainingVideoLinkTypes>(
  variables: TrainingGetContentVideoLinkTypesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingVideoLinkTypes, TrainingGetContentVideoLinkTypesError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingVideoLinkTypes, TrainingGetContentVideoLinkTypesError, TData>(
    queryKeyFn({
      path: '/api/Training/Content/VideoLinkTypes',
      operationId: 'trainingGetContentVideoLinkTypes',
      variables
    }),
    ({ signal }) => fetchTrainingGetContentVideoLinkTypes({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetManagementApprovalTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetManagementApprovalTrainingContentError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetManagementApprovalTrainingContentVariables = {
  headers?: TrainingGetManagementApprovalTrainingContentHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetManagementApprovalTrainingContent = (
  variables: TrainingGetManagementApprovalTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingGetManagementApprovalTrainingContentError,
    undefined,
    TrainingGetManagementApprovalTrainingContentHeaders,
    {},
    {}
  >({ url: '/api/Training/Management/ApprovalTrainingContent', method: 'get', ...variables, signal });

export const useTrainingGetManagementApprovalTrainingContent = <
  TData = Schemas.TrainingTrainingApprovalContentConditions
>(
  variables: TrainingGetManagementApprovalTrainingContentVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingTrainingApprovalContentConditions,
      TrainingGetManagementApprovalTrainingContentError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingGetManagementApprovalTrainingContentError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Management/ApprovalTrainingContent',
      operationId: 'trainingGetManagementApprovalTrainingContent',
      variables
    }),
    ({ signal }) => fetchTrainingGetManagementApprovalTrainingContent({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPutManagementApprovalTrainingContentHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutManagementApprovalTrainingContentError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingPutManagementApprovalTrainingContentVariables = {
  body?: Schemas.TrainingTrainingApprovalContentCondition;
  headers?: TrainingPutManagementApprovalTrainingContentHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPutManagementApprovalTrainingContent = (
  variables: TrainingPutManagementApprovalTrainingContentVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPutManagementApprovalTrainingContentError,
    Schemas.TrainingTrainingApprovalContentCondition,
    TrainingPutManagementApprovalTrainingContentHeaders,
    {},
    {}
  >({ url: '/api/Training/Management/ApprovalTrainingContent', method: 'put', ...variables, signal });

export const useTrainingPutManagementApprovalTrainingContent = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPutManagementApprovalTrainingContentError,
      TrainingPutManagementApprovalTrainingContentVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPutManagementApprovalTrainingContentError,
    TrainingPutManagementApprovalTrainingContentVariables
  >(
    (variables: TrainingPutManagementApprovalTrainingContentVariables) =>
      fetchTrainingPutManagementApprovalTrainingContent({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingGetManagementApprovaltrainingcontentByOrgIdPathParams = {
  /**
   * Send org ID to get list of targeted conditions
   *
   * @format int32
   */
  orgId: number;
};

export type TrainingGetManagementApprovaltrainingcontentByOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetManagementApprovaltrainingcontentByOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetManagementApprovaltrainingcontentByOrgIdVariables = {
  headers?: TrainingGetManagementApprovaltrainingcontentByOrgIdHeaders;
  pathParams: TrainingGetManagementApprovaltrainingcontentByOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetManagementApprovaltrainingcontentByOrgId = (
  variables: TrainingGetManagementApprovaltrainingcontentByOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingGetManagementApprovaltrainingcontentByOrgIdError,
    undefined,
    TrainingGetManagementApprovaltrainingcontentByOrgIdHeaders,
    {},
    TrainingGetManagementApprovaltrainingcontentByOrgIdPathParams
  >({ url: '/api/Training/Management/approvaltrainingcontent/{orgId}', method: 'get', ...variables, signal });

export const useTrainingGetManagementApprovaltrainingcontentByOrgId = <
  TData = Schemas.TrainingTrainingApprovalContentConditions
>(
  variables: TrainingGetManagementApprovaltrainingcontentByOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingTrainingApprovalContentConditions,
      TrainingGetManagementApprovaltrainingcontentByOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    Schemas.TrainingTrainingApprovalContentConditions,
    TrainingGetManagementApprovaltrainingcontentByOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Training/Management/approvaltrainingcontent/{orgId}',
      operationId: 'trainingGetManagementApprovaltrainingcontentByOrgId',
      variables
    }),
    ({ signal }) =>
      fetchTrainingGetManagementApprovaltrainingcontentByOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetManagementContentByConditionIdPathParams = {
  /**
   * @format int32
   */
  conditionId: number;
};

export type TrainingGetManagementContentByConditionIdHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetManagementContentByConditionIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetManagementContentByConditionIdVariables = {
  headers?: TrainingGetManagementContentByConditionIdHeaders;
  pathParams: TrainingGetManagementContentByConditionIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetManagementContentByConditionId = (
  variables: TrainingGetManagementContentByConditionIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTrainingApprovals,
    TrainingGetManagementContentByConditionIdError,
    undefined,
    TrainingGetManagementContentByConditionIdHeaders,
    {},
    TrainingGetManagementContentByConditionIdPathParams
  >({ url: '/api/Training/Management/Content/{conditionId}', method: 'get', ...variables, signal });

export const useTrainingGetManagementContentByConditionId = <TData = Schemas.TrainingTrainingApprovals>(
  variables: TrainingGetManagementContentByConditionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.TrainingTrainingApprovals,
      TrainingGetManagementContentByConditionIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingTrainingApprovals, TrainingGetManagementContentByConditionIdError, TData>(
    queryKeyFn({
      path: '/api/Training/Management/Content/{conditionId}',
      operationId: 'trainingGetManagementContentByConditionId',
      variables
    }),
    ({ signal }) => fetchTrainingGetManagementContentByConditionId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadVariables = {
  headers?: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadHeaders;
  pathParams: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownload = (
  variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadError,
    undefined,
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadHeaders,
    {},
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadPathParams
  >({
    url: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}/Download',
    method: 'get',
    ...variables,
    signal
  });

export const useReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownload = <TData = Blob>(
  variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadError, TData>(
    queryKeyFn({
      path: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}/Download',
      operationId: 'reportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownload',
      variables
    }),
    ({ signal }) =>
      fetchReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingHospitalByHospitalOrgIdDownloadPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type ReportsGetTrainingHospitalByHospitalOrgIdDownloadHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingHospitalByHospitalOrgIdDownloadError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ReportsGetTrainingHospitalByHospitalOrgIdDownloadVariables = {
  headers?: ReportsGetTrainingHospitalByHospitalOrgIdDownloadHeaders;
  pathParams: ReportsGetTrainingHospitalByHospitalOrgIdDownloadPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingHospitalByHospitalOrgIdDownload = (
  variables: ReportsGetTrainingHospitalByHospitalOrgIdDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetTrainingHospitalByHospitalOrgIdDownloadError,
    undefined,
    ReportsGetTrainingHospitalByHospitalOrgIdDownloadHeaders,
    {},
    ReportsGetTrainingHospitalByHospitalOrgIdDownloadPathParams
  >({ url: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}/Download', method: 'get', ...variables, signal });

export const useReportsGetTrainingHospitalByHospitalOrgIdDownload = <TData = Blob>(
  variables: ReportsGetTrainingHospitalByHospitalOrgIdDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetTrainingHospitalByHospitalOrgIdDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetTrainingHospitalByHospitalOrgIdDownloadError, TData>(
    queryKeyFn({
      path: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}/Download',
      operationId: 'reportsGetTrainingHospitalByHospitalOrgIdDownload',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingHospitalByHospitalOrgIdDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadPathParams = {
  /**
   * @format int32
   */
  physicianOrgHierarchyId: number;
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadVariables = {
  headers?: ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadHeaders;
  pathParams: ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingPhysicianByPhysicianOrgIdDownload = (
  variables: ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadError,
    undefined,
    ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadHeaders,
    {},
    ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadPathParams
  >({ url: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}/Download', method: 'get', ...variables, signal });

export const useReportsGetTrainingPhysicianByPhysicianOrgIdDownload = <TData = Blob>(
  variables: ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadError, TData>(
    queryKeyFn({
      path: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}/Download',
      operationId: 'reportsGetTrainingPhysicianByPhysicianOrgIdDownload',
      variables
    }),
    ({ signal }) =>
      fetchReportsGetTrainingPhysicianByPhysicianOrgIdDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingSystemBySystemOrgIdDownloadPathParams = {
  /**
   * @format int32
   */
  systemOrgHierarchyId: number;
};

export type ReportsGetTrainingSystemBySystemOrgIdDownloadHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingSystemBySystemOrgIdDownloadError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ReportsGetTrainingSystemBySystemOrgIdDownloadVariables = {
  headers?: ReportsGetTrainingSystemBySystemOrgIdDownloadHeaders;
  pathParams: ReportsGetTrainingSystemBySystemOrgIdDownloadPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingSystemBySystemOrgIdDownload = (
  variables: ReportsGetTrainingSystemBySystemOrgIdDownloadVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetTrainingSystemBySystemOrgIdDownloadError,
    undefined,
    ReportsGetTrainingSystemBySystemOrgIdDownloadHeaders,
    {},
    ReportsGetTrainingSystemBySystemOrgIdDownloadPathParams
  >({ url: '/api/Reports/Training/System/{systemOrgHierarchyId}/Download', method: 'get', ...variables, signal });

export const useReportsGetTrainingSystemBySystemOrgIdDownload = <TData = Blob>(
  variables: ReportsGetTrainingSystemBySystemOrgIdDownloadVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetTrainingSystemBySystemOrgIdDownloadError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetTrainingSystemBySystemOrgIdDownloadError, TData>(
    queryKeyFn({
      path: '/api/Reports/Training/System/{systemOrgHierarchyId}/Download',
      operationId: 'reportsGetTrainingSystemBySystemOrgIdDownload',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingSystemBySystemOrgIdDownload({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdResponse = Schemas.TrainingProviderTrainingSummary[];

export type ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdVariables = {
  headers?: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdHeaders;
  pathParams: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingPhysicianGroupByPhysicianGroupOrgId = (
  variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdResponse,
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdError,
    undefined,
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdHeaders,
    {},
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdPathParams
  >({
    url: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}',
    method: 'get',
    ...variables,
    signal
  });

export const useReportsGetTrainingPhysicianGroupByPhysicianGroupOrgId = <
  TData = ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdResponse
>(
  variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdResponse,
      ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdResponse,
    ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}',
      operationId: 'reportsGetTrainingPhysicianGroupByPhysicianGroupOrgId',
      variables
    }),
    ({ signal }) =>
      fetchReportsGetTrainingPhysicianGroupByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingHospitalByHospitalOrgIdPathParams = {
  /**
   * @format int32
   */
  hospitalOrgHierarchyId: number;
};

export type ReportsGetTrainingHospitalByHospitalOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingHospitalByHospitalOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ReportsGetTrainingHospitalByHospitalOrgIdResponse = Schemas.TrainingGroupReportTrainingSummary[];

export type ReportsGetTrainingHospitalByHospitalOrgIdVariables = {
  headers?: ReportsGetTrainingHospitalByHospitalOrgIdHeaders;
  pathParams: ReportsGetTrainingHospitalByHospitalOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingHospitalByHospitalOrgId = (
  variables: ReportsGetTrainingHospitalByHospitalOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    ReportsGetTrainingHospitalByHospitalOrgIdResponse,
    ReportsGetTrainingHospitalByHospitalOrgIdError,
    undefined,
    ReportsGetTrainingHospitalByHospitalOrgIdHeaders,
    {},
    ReportsGetTrainingHospitalByHospitalOrgIdPathParams
  >({ url: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}', method: 'get', ...variables, signal });

export const useReportsGetTrainingHospitalByHospitalOrgId = <TData = ReportsGetTrainingHospitalByHospitalOrgIdResponse>(
  variables: ReportsGetTrainingHospitalByHospitalOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ReportsGetTrainingHospitalByHospitalOrgIdResponse,
      ReportsGetTrainingHospitalByHospitalOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ReportsGetTrainingHospitalByHospitalOrgIdResponse,
    ReportsGetTrainingHospitalByHospitalOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}',
      operationId: 'reportsGetTrainingHospitalByHospitalOrgId',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingHospitalByHospitalOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianOrgHierarchyId: number;
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingPhysicianByPhysicianOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type ReportsGetTrainingPhysicianByPhysicianOrgIdResponse = Schemas.TrainingProviderConditionTrainingSummary[];

export type ReportsGetTrainingPhysicianByPhysicianOrgIdVariables = {
  headers?: ReportsGetTrainingPhysicianByPhysicianOrgIdHeaders;
  pathParams: ReportsGetTrainingPhysicianByPhysicianOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingPhysicianByPhysicianOrgId = (
  variables: ReportsGetTrainingPhysicianByPhysicianOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    ReportsGetTrainingPhysicianByPhysicianOrgIdResponse,
    ReportsGetTrainingPhysicianByPhysicianOrgIdError,
    undefined,
    ReportsGetTrainingPhysicianByPhysicianOrgIdHeaders,
    {},
    ReportsGetTrainingPhysicianByPhysicianOrgIdPathParams
  >({ url: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}', method: 'get', ...variables, signal });

export const useReportsGetTrainingPhysicianByPhysicianOrgId = <
  TData = ReportsGetTrainingPhysicianByPhysicianOrgIdResponse
>(
  variables: ReportsGetTrainingPhysicianByPhysicianOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ReportsGetTrainingPhysicianByPhysicianOrgIdResponse,
      ReportsGetTrainingPhysicianByPhysicianOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ReportsGetTrainingPhysicianByPhysicianOrgIdResponse,
    ReportsGetTrainingPhysicianByPhysicianOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}',
      operationId: 'reportsGetTrainingPhysicianByPhysicianOrgId',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingPhysicianByPhysicianOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingSystemBySystemOrgIdPathParams = {
  /**
   * @format int32
   */
  systemOrgHierarchyId: number;
};

export type ReportsGetTrainingSystemBySystemOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingSystemBySystemOrgIdError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type ReportsGetTrainingSystemBySystemOrgIdResponse = Schemas.TrainingGroupReportTrainingSummary[];

export type ReportsGetTrainingSystemBySystemOrgIdVariables = {
  headers?: ReportsGetTrainingSystemBySystemOrgIdHeaders;
  pathParams: ReportsGetTrainingSystemBySystemOrgIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingSystemBySystemOrgId = (
  variables: ReportsGetTrainingSystemBySystemOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    ReportsGetTrainingSystemBySystemOrgIdResponse,
    ReportsGetTrainingSystemBySystemOrgIdError,
    undefined,
    ReportsGetTrainingSystemBySystemOrgIdHeaders,
    {},
    ReportsGetTrainingSystemBySystemOrgIdPathParams
  >({ url: '/api/Reports/Training/System/{systemOrgHierarchyId}', method: 'get', ...variables, signal });

export const useReportsGetTrainingSystemBySystemOrgId = <TData = ReportsGetTrainingSystemBySystemOrgIdResponse>(
  variables: ReportsGetTrainingSystemBySystemOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      ReportsGetTrainingSystemBySystemOrgIdResponse,
      ReportsGetTrainingSystemBySystemOrgIdError,
      TData
    >,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<
    ReportsGetTrainingSystemBySystemOrgIdResponse,
    ReportsGetTrainingSystemBySystemOrgIdError,
    TData
  >(
    queryKeyFn({
      path: '/api/Reports/Training/System/{systemOrgHierarchyId}',
      operationId: 'reportsGetTrainingSystemBySystemOrgId',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingSystemBySystemOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type ReportsGetTrainingConditionsByPhysicianGroupOrgIdPathParams = {
  /**
   * @format int32
   */
  physicianGroupOrgHierarchyId: number;
};

export type ReportsGetTrainingConditionsByPhysicianGroupOrgIdQueryParams = {
  /**
   * comma delmited list of condition IDs. If left blank, it will get all the targeted conditions for the group.
   */
  conditionIds?: string;
};

export type ReportsGetTrainingConditionsByPhysicianGroupOrgIdHeaders = {
  ['x-api-version']?: string;
};

export type ReportsGetTrainingConditionsByPhysicianGroupOrgIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type ReportsGetTrainingConditionsByPhysicianGroupOrgIdVariables = {
  headers?: ReportsGetTrainingConditionsByPhysicianGroupOrgIdHeaders;
  pathParams: ReportsGetTrainingConditionsByPhysicianGroupOrgIdPathParams;
  queryParams?: ReportsGetTrainingConditionsByPhysicianGroupOrgIdQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchReportsGetTrainingConditionsByPhysicianGroupOrgId = (
  variables: ReportsGetTrainingConditionsByPhysicianGroupOrgIdVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Blob,
    ReportsGetTrainingConditionsByPhysicianGroupOrgIdError,
    undefined,
    ReportsGetTrainingConditionsByPhysicianGroupOrgIdHeaders,
    ReportsGetTrainingConditionsByPhysicianGroupOrgIdQueryParams,
    ReportsGetTrainingConditionsByPhysicianGroupOrgIdPathParams
  >({ url: '/api/Reports/Training/conditions/{physicianGroupOrgHierarchyId}', method: 'get', ...variables, signal });

export const useReportsGetTrainingConditionsByPhysicianGroupOrgId = <TData = Blob>(
  variables: ReportsGetTrainingConditionsByPhysicianGroupOrgIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Blob, ReportsGetTrainingConditionsByPhysicianGroupOrgIdError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Blob, ReportsGetTrainingConditionsByPhysicianGroupOrgIdError, TData>(
    queryKeyFn({
      path: '/api/Reports/Training/conditions/{physicianGroupOrgHierarchyId}',
      operationId: 'reportsGetTrainingConditionsByPhysicianGroupOrgId',
      variables
    }),
    ({ signal }) => fetchReportsGetTrainingConditionsByPhysicianGroupOrgId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetTestNextSequenceQueryParams = {
  /**
   * Org Hierarchy Id - for admin
   *
   * @format int32
   */
  orgId?: number;
  /**
   * optional query param for mobile-specific response behavior
   *
   * @default false
   */
  mobile?: boolean;
};

export type TrainingGetTestNextSequenceHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetTestNextSequenceError = Fetcher.ErrorWrapper<undefined>;

export type TrainingGetTestNextSequenceVariables = {
  headers?: TrainingGetTestNextSequenceHeaders;
  queryParams?: TrainingGetTestNextSequenceQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchTrainingGetTestNextSequence = (
  variables: TrainingGetTestNextSequenceVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    Schemas.TrainingTestContent,
    TrainingGetTestNextSequenceError,
    undefined,
    TrainingGetTestNextSequenceHeaders,
    TrainingGetTestNextSequenceQueryParams,
    {}
  >({ url: '/api/Training/Test/NextSequence', method: 'get', ...variables, signal });

export const useTrainingGetTestNextSequence = <TData = Schemas.TrainingTestContent>(
  variables: TrainingGetTestNextSequenceVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingTestContent, TrainingGetTestNextSequenceError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingTestContent, TrainingGetTestNextSequenceError, TData>(
    queryKeyFn({ path: '/api/Training/Test/NextSequence', operationId: 'trainingGetTestNextSequence', variables }),
    ({ signal }) => fetchTrainingGetTestNextSequence({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingGetTestProgressHeaders = {
  ['x-api-version']?: string;
};

export type TrainingGetTestProgressError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingGetTestProgressVariables = {
  headers?: TrainingGetTestProgressHeaders;
} & ApiContext['fetcherOptions'];

/**
 * Can be used by dashboard to preview training tile.
 */
export const fetchTrainingGetTestProgress = (variables: TrainingGetTestProgressVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingProviderTrainingSummary,
    TrainingGetTestProgressError,
    undefined,
    TrainingGetTestProgressHeaders,
    {},
    {}
  >({ url: '/api/Training/Test/Progress', method: 'get', ...variables, signal });

/**
 * Can be used by dashboard to preview training tile.
 */
export const useTrainingGetTestProgress = <TData = Schemas.TrainingProviderTrainingSummary>(
  variables: TrainingGetTestProgressVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.TrainingProviderTrainingSummary, TrainingGetTestProgressError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<Schemas.TrainingProviderTrainingSummary, TrainingGetTestProgressError, TData>(
    queryKeyFn({ path: '/api/Training/Test/Progress', operationId: 'trainingGetTestProgress', variables }),
    ({ signal }) => fetchTrainingGetTestProgress({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type TrainingPostTestLogAnswerHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostTestLogAnswerError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingPostTestLogAnswerVariables = {
  body: Schemas.TrainingLogAnswerRequest;
  headers?: TrainingPostTestLogAnswerHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostTestLogAnswer = (variables: TrainingPostTestLogAnswerVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingLogAnswerRespose,
    TrainingPostTestLogAnswerError,
    Schemas.TrainingLogAnswerRequest,
    TrainingPostTestLogAnswerHeaders,
    {},
    {}
  >({ url: '/api/Training/Test/Log/Answer', method: 'post', ...variables, signal });

export const useTrainingPostTestLogAnswer = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TrainingLogAnswerRespose,
      TrainingPostTestLogAnswerError,
      TrainingPostTestLogAnswerVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.TrainingLogAnswerRespose,
    TrainingPostTestLogAnswerError,
    TrainingPostTestLogAnswerVariables
  >(
    (variables: TrainingPostTestLogAnswerVariables) =>
      fetchTrainingPostTestLogAnswer({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPostTestLogVideoHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostTestLogVideoError = Fetcher.ErrorWrapper<{
  status: 404;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostTestLogVideoVariables = {
  body: Schemas.TrainingLogVideoRequest;
  headers?: TrainingPostTestLogVideoHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostTestLogVideo = (variables: TrainingPostTestLogVideoVariables, signal?: AbortSignal) =>
  apiFetch<
    string,
    TrainingPostTestLogVideoError,
    Schemas.TrainingLogVideoRequest,
    TrainingPostTestLogVideoHeaders,
    {},
    {}
  >({ url: '/api/Training/Test/Log/Video', method: 'post', ...variables, signal });

export const useTrainingPostTestLogVideo = (
  options?: Omit<
    reactQuery.UseMutationOptions<string, TrainingPostTestLogVideoError, TrainingPostTestLogVideoVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<string, TrainingPostTestLogVideoError, TrainingPostTestLogVideoVariables>(
    (variables: TrainingPostTestLogVideoVariables) =>
      fetchTrainingPostTestLogVideo({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPutTestRetakeQueryParams = {
  /**
   * Org Hierarchy Id
   *
   * @format int32
   */
  orgId?: number;
};

export type TrainingPutTestRetakeHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPutTestRetakeError = Fetcher.ErrorWrapper<
  | {
      status: 404;
      payload: Schemas.MvcProblemDetails;
    }
  | {
      status: 409;
      payload: Schemas.MvcProblemDetails;
    }
>;

export type TrainingPutTestRetakeVariables = {
  headers?: TrainingPutTestRetakeHeaders;
  queryParams?: TrainingPutTestRetakeQueryParams;
} & ApiContext['fetcherOptions'];

/**
 * Can be used by dashboard to preview training tile.
 */
export const fetchTrainingPutTestRetake = (variables: TrainingPutTestRetakeVariables, signal?: AbortSignal) =>
  apiFetch<
    Schemas.TrainingProviderTrainingSummary,
    TrainingPutTestRetakeError,
    undefined,
    TrainingPutTestRetakeHeaders,
    TrainingPutTestRetakeQueryParams,
    {}
  >({ url: '/api/Training/Test/Retake', method: 'put', ...variables, signal });

/**
 * Can be used by dashboard to preview training tile.
 */
export const useTrainingPutTestRetake = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TrainingProviderTrainingSummary,
      TrainingPutTestRetakeError,
      TrainingPutTestRetakeVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    Schemas.TrainingProviderTrainingSummary,
    TrainingPutTestRetakeError,
    TrainingPutTestRetakeVariables
  >(
    (variables: TrainingPutTestRetakeVariables) => fetchTrainingPutTestRetake({ ...fetcherOptions, ...variables }),
    options
  );
};

export type TrainingPostTestSendTrainingCompletionEmailHeaders = {
  ['x-api-version']?: string;
};

export type TrainingPostTestSendTrainingCompletionEmailError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.MvcProblemDetails;
}>;

export type TrainingPostTestSendTrainingCompletionEmailVariables = {
  body?: Schemas.TrainingTrainingCompletionEmailRequest;
  headers?: TrainingPostTestSendTrainingCompletionEmailHeaders;
} & ApiContext['fetcherOptions'];

export const fetchTrainingPostTestSendTrainingCompletionEmail = (
  variables: TrainingPostTestSendTrainingCompletionEmailVariables,
  signal?: AbortSignal
) =>
  apiFetch<
    undefined,
    TrainingPostTestSendTrainingCompletionEmailError,
    Schemas.TrainingTrainingCompletionEmailRequest,
    TrainingPostTestSendTrainingCompletionEmailHeaders,
    {},
    {}
  >({ url: '/api/Training/Test/SendTrainingCompletionEmail', method: 'post', ...variables, signal });

export const useTrainingPostTestSendTrainingCompletionEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      TrainingPostTestSendTrainingCompletionEmailError,
      TrainingPostTestSendTrainingCompletionEmailVariables
    >,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<
    undefined,
    TrainingPostTestSendTrainingCompletionEmailError,
    TrainingPostTestSendTrainingCompletionEmailVariables
  >(
    (variables: TrainingPostTestSendTrainingCompletionEmailVariables) =>
      fetchTrainingPostTestSendTrainingCompletionEmail({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostVariables = {
  body: Schemas.AddUserDto;
  headers?: UsersPostHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPost = (variables: UsersPostVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersPostError, Schemas.AddUserDto, UsersPostHeaders, {}, {}>({
    url: '/api/Users',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersPost = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersPostError, UsersPostVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostError, UsersPostVariables>(
    (variables: UsersPostVariables) => fetchUsersPost({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetVariables = {
  headers?: UsersGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersGet = (variables: UsersGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersGetError, undefined, UsersGetHeaders, {}, {}>({
    url: '/api/Users',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersGet = <TData = undefined>(
  variables: UsersGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetError, TData>(
    queryKeyFn({ path: '/api/Users', operationId: 'usersGet', variables }),
    ({ signal }) => fetchUsersGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersDeleteByUserIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersDeleteByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type UsersDeleteByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type UsersDeleteByUserIdVariables = {
  headers?: UsersDeleteByUserIdHeaders;
  pathParams: UsersDeleteByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersDeleteByUserId = (variables: UsersDeleteByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersDeleteByUserIdError,
    undefined,
    UsersDeleteByUserIdHeaders,
    {},
    UsersDeleteByUserIdPathParams
  >({ url: '/api/Users/{userId}', method: 'delete', ...variables, signal });

export const useUsersDeleteByUserId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersDeleteByUserIdError, UsersDeleteByUserIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersDeleteByUserIdError, UsersDeleteByUserIdVariables>(
    (variables: UsersDeleteByUserIdVariables) => fetchUsersDeleteByUserId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetByUserIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersGetByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetByUserIdVariables = {
  headers?: UsersGetByUserIdHeaders;
  pathParams: UsersGetByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetByUserId = (variables: UsersGetByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersGetByUserIdError, undefined, UsersGetByUserIdHeaders, {}, UsersGetByUserIdPathParams>({
    url: '/api/Users/{userId}',
    method: 'get',
    ...variables,
    signal
  });

export const useUsersGetByUserId = <TData = undefined>(
  variables: UsersGetByUserIdVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetByUserIdError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetByUserIdError, TData>(
    queryKeyFn({ path: '/api/Users/{userId}', operationId: 'usersGetByUserId', variables }),
    ({ signal }) => fetchUsersGetByUserId({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersPutByUserIdPathParams = {
  /**
   * @format int32
   */
  userId: number;
};

export type UsersPutByUserIdHeaders = {
  ['x-api-version']?: string;
};

export type UsersPutByUserIdError = Fetcher.ErrorWrapper<undefined>;

export type UsersPutByUserIdVariables = {
  body: Schemas.UserUpdateDto;
  headers?: UsersPutByUserIdHeaders;
  pathParams: UsersPutByUserIdPathParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersPutByUserId = (variables: UsersPutByUserIdVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersPutByUserIdError,
    Schemas.UserUpdateDto,
    UsersPutByUserIdHeaders,
    {},
    UsersPutByUserIdPathParams
  >({ url: '/api/Users/{userId}', method: 'put', ...variables, signal });

export const useUsersPutByUserId = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPutByUserIdError, UsersPutByUserIdVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPutByUserIdError, UsersPutByUserIdVariables>(
    (variables: UsersPutByUserIdVariables) => fetchUsersPutByUserId({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersDeleteDeleteUsersHeaders = {
  ['x-api-version']?: string;
};

export type UsersDeleteDeleteUsersError = Fetcher.ErrorWrapper<undefined>;

export type UsersDeleteDeleteUsersRequestBody = number[];

export type UsersDeleteDeleteUsersVariables = {
  body?: UsersDeleteDeleteUsersRequestBody;
  headers?: UsersDeleteDeleteUsersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersDeleteDeleteUsers = (variables: UsersDeleteDeleteUsersVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersDeleteDeleteUsersError,
    UsersDeleteDeleteUsersRequestBody,
    UsersDeleteDeleteUsersHeaders,
    {},
    {}
  >({ url: '/api/Users/DeleteUsers', method: 'delete', ...variables, signal });

export const useUsersDeleteDeleteUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersDeleteDeleteUsersError, UsersDeleteDeleteUsersVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersDeleteDeleteUsersError, UsersDeleteDeleteUsersVariables>(
    (variables: UsersDeleteDeleteUsersVariables) => fetchUsersDeleteDeleteUsers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetIsNpiTakenQueryParams = {
  npi?: string;
};

export type UsersGetIsNpiTakenHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetIsNpiTakenError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetIsNpiTakenVariables = {
  headers?: UsersGetIsNpiTakenHeaders;
  queryParams?: UsersGetIsNpiTakenQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetIsNpiTaken = (variables: UsersGetIsNpiTakenVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersGetIsNpiTakenError, undefined, UsersGetIsNpiTakenHeaders, UsersGetIsNpiTakenQueryParams, {}>(
    { url: '/api/Users/IsNpiTaken', method: 'get', ...variables, signal }
  );

export const useUsersGetIsNpiTaken = <TData = undefined>(
  variables: UsersGetIsNpiTakenVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetIsNpiTakenError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetIsNpiTakenError, TData>(
    queryKeyFn({ path: '/api/Users/IsNpiTaken', operationId: 'usersGetIsNpiTaken', variables }),
    ({ signal }) => fetchUsersGetIsNpiTaken({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersPostBlockUsersHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostBlockUsersError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostBlockUsersVariables = {
  body?: Schemas.UserBlockRequestDto;
  headers?: UsersPostBlockUsersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostBlockUsers = (variables: UsersPostBlockUsersVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersPostBlockUsersError, Schemas.UserBlockRequestDto, UsersPostBlockUsersHeaders, {}, {}>({
    url: '/api/Users/BlockUsers',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersPostBlockUsers = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPostBlockUsersError, UsersPostBlockUsersVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostBlockUsersError, UsersPostBlockUsersVariables>(
    (variables: UsersPostBlockUsersVariables) => fetchUsersPostBlockUsers({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostPassResReqHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostPassResReqError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostPassResReqVariables = {
  body: Schemas.ResetPasswordDto;
  headers?: UsersPostPassResReqHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostPassResReq = (variables: UsersPostPassResReqVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersPostPassResReqError, Schemas.ResetPasswordDto, UsersPostPassResReqHeaders, {}, {}>({
    url: '/api/Users/PassResReq',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersPostPassResReq = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPostPassResReqError, UsersPostPassResReqVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostPassResReqError, UsersPostPassResReqVariables>(
    (variables: UsersPostPassResReqVariables) => fetchUsersPostPassResReq({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostResetDashboardsHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostResetDashboardsError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostResetDashboardsRequestBody = number[];

export type UsersPostResetDashboardsVariables = {
  body?: UsersPostResetDashboardsRequestBody;
  headers?: UsersPostResetDashboardsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostResetDashboards = (variables: UsersPostResetDashboardsVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersPostResetDashboardsError,
    UsersPostResetDashboardsRequestBody,
    UsersPostResetDashboardsHeaders,
    {},
    {}
  >({ url: '/api/Users/ResetDashboards', method: 'post', ...variables, signal });

export const useUsersPostResetDashboards = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPostResetDashboardsError, UsersPostResetDashboardsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostResetDashboardsError, UsersPostResetDashboardsVariables>(
    (variables: UsersPostResetDashboardsVariables) =>
      fetchUsersPostResetDashboards({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostResetPasswordsHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostResetPasswordsError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostResetPasswordsRequestBody = number[];

export type UsersPostResetPasswordsVariables = {
  body?: UsersPostResetPasswordsRequestBody;
  headers?: UsersPostResetPasswordsHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostResetPasswords = (variables: UsersPostResetPasswordsVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersPostResetPasswordsError,
    UsersPostResetPasswordsRequestBody,
    UsersPostResetPasswordsHeaders,
    {},
    {}
  >({ url: '/api/Users/ResetPasswords', method: 'post', ...variables, signal });

export const useUsersPostResetPasswords = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPostResetPasswordsError, UsersPostResetPasswordsVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostResetPasswordsError, UsersPostResetPasswordsVariables>(
    (variables: UsersPostResetPasswordsVariables) => fetchUsersPostResetPasswords({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostSendEmailHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostSendEmailError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostSendEmailRequestBody = number[];

export type UsersPostSendEmailVariables = {
  body?: UsersPostSendEmailRequestBody;
  headers?: UsersPostSendEmailHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostSendEmail = (variables: UsersPostSendEmailVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersPostSendEmailError, UsersPostSendEmailRequestBody, UsersPostSendEmailHeaders, {}, {}>({
    url: '/api/Users/SendEmail',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersPostSendEmail = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, UsersPostSendEmailError, UsersPostSendEmailVariables>,
    'mutationFn'
  >
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostSendEmailError, UsersPostSendEmailVariables>(
    (variables: UsersPostSendEmailVariables) => fetchUsersPostSendEmail({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersPostUploadHeaders = {
  ['x-api-version']?: string;
};

export type UsersPostUploadError = Fetcher.ErrorWrapper<undefined>;

export type UsersPostUploadRequestBody = {
  /**
   * @format binary
   */
  UserUploadFile: Blob;
};

export type UsersPostUploadVariables = {
  body: UsersPostUploadRequestBody;
  headers?: UsersPostUploadHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersPostUpload = (variables: UsersPostUploadVariables, signal?: AbortSignal) =>
  apiFetch<undefined, UsersPostUploadError, UsersPostUploadRequestBody, UsersPostUploadHeaders, {}, {}>({
    url: '/api/Users/Upload',
    method: 'post',
    ...variables,
    signal
  });

export const useUsersPostUpload = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, UsersPostUploadError, UsersPostUploadVariables>, 'mutationFn'>
) => {
  const { fetcherOptions } = useApiContext();
  return reactQuery.useMutation<undefined, UsersPostUploadError, UsersPostUploadVariables>(
    (variables: UsersPostUploadVariables) => fetchUsersPostUpload({ ...fetcherOptions, ...variables }),
    options
  );
};

export type UsersGetValidateNpiQueryParams = {
  npi?: string;
};

export type UsersGetValidateNpiHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetValidateNpiError = Fetcher.ErrorWrapper<undefined>;

export type UsersGetValidateNpiVariables = {
  headers?: UsersGetValidateNpiHeaders;
  queryParams?: UsersGetValidateNpiQueryParams;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetValidateNpi = (variables: UsersGetValidateNpiVariables, signal?: AbortSignal) =>
  apiFetch<
    undefined,
    UsersGetValidateNpiError,
    undefined,
    UsersGetValidateNpiHeaders,
    UsersGetValidateNpiQueryParams,
    {}
  >({ url: '/api/Users/ValidateNpi', method: 'get', ...variables, signal });

export const useUsersGetValidateNpi = <TData = undefined>(
  variables: UsersGetValidateNpiVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, UsersGetValidateNpiError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, UsersGetValidateNpiError, TData>(
    queryKeyFn({ path: '/api/Users/ValidateNpi', operationId: 'usersGetValidateNpi', variables }),
    ({ signal }) => fetchUsersGetValidateNpi({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type UsersGetClinicalLeadersHeaders = {
  ['x-api-version']?: string;
};

export type UsersGetClinicalLeadersError = Fetcher.ErrorWrapper<{
  status: 409;
  payload: Schemas.MvcProblemDetails;
}>;

export type UsersGetClinicalLeadersResponse = Schemas.UserAccountDto[];

export type UsersGetClinicalLeadersVariables = {
  headers?: UsersGetClinicalLeadersHeaders;
} & ApiContext['fetcherOptions'];

export const fetchUsersGetClinicalLeaders = (variables: UsersGetClinicalLeadersVariables, signal?: AbortSignal) =>
  apiFetch<
    UsersGetClinicalLeadersResponse,
    UsersGetClinicalLeadersError,
    undefined,
    UsersGetClinicalLeadersHeaders,
    {},
    {}
  >({ url: '/api/Users/ClinicalLeaders', method: 'get', ...variables, signal });

export const useUsersGetClinicalLeaders = <TData = UsersGetClinicalLeadersResponse>(
  variables: UsersGetClinicalLeadersVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<UsersGetClinicalLeadersResponse, UsersGetClinicalLeadersError, TData>,
    'queryKey' | 'queryFn'
  >
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<UsersGetClinicalLeadersResponse, UsersGetClinicalLeadersError, TData>(
    queryKeyFn({ path: '/api/Users/ClinicalLeaders', operationId: 'usersGetClinicalLeaders', variables }),
    ({ signal }) => fetchUsersGetClinicalLeaders({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type VersionGetHeaders = {
  ['x-api-version']?: string;
};

export type VersionGetError = Fetcher.ErrorWrapper<undefined>;

export type VersionGetVariables = {
  headers?: VersionGetHeaders;
} & ApiContext['fetcherOptions'];

export const fetchVersionGet = (variables: VersionGetVariables, signal?: AbortSignal) =>
  apiFetch<undefined, VersionGetError, undefined, VersionGetHeaders, {}, {}>({
    url: '/api/Version',
    method: 'get',
    ...variables,
    signal
  });

export const useVersionGet = <TData = undefined>(
  variables: VersionGetVariables,
  options?: Omit<reactQuery.UseQueryOptions<undefined, VersionGetError, TData>, 'queryKey' | 'queryFn'>
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
  return reactQuery.useQuery<undefined, VersionGetError, TData>(
    queryKeyFn({ path: '/api/Version', operationId: 'versionGet', variables }),
    ({ signal }) => fetchVersionGet({ ...fetcherOptions, ...variables }, signal),
    {
      ...options,
      ...queryOptions
    }
  );
};

export type QueryOperation =
  | {
      path: '/api/Auth/Identities';
      operationId: 'authGetIdentities';
      variables: AuthGetIdentitiesVariables;
    }
  | {
      path: '/api/Auth/User';
      operationId: 'authGetUser';
      variables: AuthGetUserVariables;
    }
  | {
      path: '/api/CIUniversity/Groups';
      operationId: 'cIUniversityGetGroups';
      variables: CIUniversityGetGroupsVariables;
    }
  | {
      path: '/api/CmiAnalysis/WidgetDates/{orgId}';
      operationId: 'cmiAnalysisGetWidgetDatesByOrgId';
      variables: CmiAnalysisGetWidgetDatesByOrgIdVariables;
    }
  | {
      path: '/api/CmiAnalysis/Dates/{orgId}';
      operationId: 'cmiAnalysisGetDatesByOrgId';
      variables: CmiAnalysisGetDatesByOrgIdVariables;
    }
  | {
      path: '/api/Conditions';
      operationId: 'conditionsGet';
      variables: ConditionsGetVariables;
    }
  | {
      path: '/api/Conditions/Icd10Codes/{id}';
      operationId: 'conditionsGetIcd10CodesById';
      variables: ConditionsGetIcd10CodesByIdVariables;
    }
  | {
      path: '/api/Conditions/Icd10Codes/Download/{id}';
      operationId: 'conditionsGetIcd10CodesDownloadById';
      variables: ConditionsGetIcd10CodesDownloadByIdVariables;
    }
  | {
      path: '/api/Dashboard/{userId}';
      operationId: 'dashboardGetByUserId';
      variables: DashboardGetByUserIdVariables;
    }
  | {
      path: '/api/DefaultDates/{orgId}';
      operationId: 'defaultDatesGetByOrgId';
      variables: DefaultDatesGetByOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal/History';
      operationId: 'docScoreGoalGetHistory';
      variables: DocScoreGoalGetHistoryVariables;
    }
  | {
      path: '/api/DocScoreGoal/History/Download';
      operationId: 'docScoreGoalGetHistoryDownload';
      variables: DocScoreGoalGetHistoryDownloadVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}';
      operationId: 'docScoreGoalGetByHospitalOrgId';
      variables: DocScoreGoalGetByHospitalOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}';
      operationId: 'docScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgId';
      variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal';
      operationId: 'docScoreGoalGet';
      variables: DocScoreGoalGetVariables;
    }
  | {
      path: '/api/DocScoreGoal/SystemsSpecialtyGroup';
      operationId: 'docScoreGoalGetSystemsSpecialtyGroup';
      variables: DocScoreGoalGetSystemsSpecialtyGroupVariables;
    }
  | {
      path: '/api/DocScoreGoal/{hospitalOrgHierarchyId}/{physicianGroupOrgHierarchyId}/History';
      operationId: 'docScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistory';
      variables: DocScoreGoalGetByHospitalOrgIdByPhysicianGroupOrgIdHistoryVariables;
    }
  | {
      path: '/api/DocScoreGoal/SmartGoalsModal/{orgId}';
      operationId: 'docScoreGoalGetSmartGoalsModalByOrgId';
      variables: DocScoreGoalGetSmartGoalsModalByOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal/SmartGoalsWidget/{orgId}';
      operationId: 'docScoreGoalGetSmartGoalsWidgetByOrgId';
      variables: DocScoreGoalGetSmartGoalsWidgetByOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal/TargetedConditionsTable/{orgId}';
      operationId: 'docScoreGoalGetTargetedConditionsTableByOrgId';
      variables: DocScoreGoalGetTargetedConditionsTableByOrgIdVariables;
    }
  | {
      path: '/api/DocScoreGoal/TargetedConditionsWidget/{orgId}';
      operationId: 'docScoreGoalGetTargetedConditionsWidgetByOrgId';
      variables: DocScoreGoalGetTargetedConditionsWidgetByOrgIdVariables;
    }
  | {
      path: '/api/Features';
      operationId: 'featuresGet';
      variables: FeaturesGetVariables;
    }
  | {
      path: '/api/Features/{id}';
      operationId: 'featuresGetById';
      variables: FeaturesGetByIdVariables;
    }
  | {
      path: '/api/FinancialTracker';
      operationId: 'financialTrackerGet';
      variables: FinancialTrackerGetVariables;
    }
  | {
      path: '/api/Graph/Cmi/{orgId}';
      operationId: 'graphGetCmiByOrgId';
      variables: GraphGetCmiByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Conditions/{orgId}';
      operationId: 'graphGetConditionsByOrgId';
      variables: GraphGetConditionsByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Conditions';
      operationId: 'graphGetConditions';
      variables: GraphGetConditionsVariables;
    }
  | {
      path: '/api/Graph/ConditionsSmartModal/{orgId}';
      operationId: 'graphGetConditionsSmartModalByOrgId';
      variables: GraphGetConditionsSmartModalByOrgIdVariables;
    }
  | {
      path: '/api/Graph/DocScore/{orgId}';
      operationId: 'graphGetDocScoreByOrgId';
      variables: GraphGetDocScoreByOrgIdVariables;
    }
  | {
      path: '/api/Graph/DocScore';
      operationId: 'graphGetDocScore';
      variables: GraphGetDocScoreVariables;
    }
  | {
      path: '/api/Graph/ElixhauserMortality/{orgId}';
      operationId: 'graphGetElixhauserMortalityByOrgId';
      variables: GraphGetElixhauserMortalityByOrgIdVariables;
    }
  | {
      path: '/api/Graph/ElixhauserReadmission/{orgId}';
      operationId: 'graphGetElixhauserReadmissionByOrgId';
      variables: GraphGetElixhauserReadmissionByOrgIdVariables;
    }
  | {
      path: '/api/Graph/HcupCmi/{orgId}';
      operationId: 'graphGetHcupCmiByOrgId';
      variables: GraphGetHcupCmiByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Los/{orgId}';
      operationId: 'graphGetLosByOrgId';
      variables: GraphGetLosByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Psi/{orgId}';
      operationId: 'graphGetPsiByOrgId';
      variables: GraphGetPsiByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Raf/{orgId}';
      operationId: 'graphGetRafByOrgId';
      variables: GraphGetRafByOrgIdVariables;
    }
  | {
      path: '/api/Graph/SeverityCmi/{orgId}';
      operationId: 'graphGetSeverityCmiByOrgId';
      variables: GraphGetSeverityCmiByOrgIdVariables;
    }
  | {
      path: '/api/Graph/Speedometer';
      operationId: 'graphGetSpeedometer';
      variables: GraphGetSpeedometerVariables;
    }
  | {
      path: '/api/Download/Graph/{orgId}';
      operationId: 'downloadGetGraphByOrgId';
      variables: DownloadGetGraphByOrgIdVariables;
    }
  | {
      path: '/api/Download/Graph/Conditions/{orgId}';
      operationId: 'downloadGetGraphConditionsByOrgId';
      variables: DownloadGetGraphConditionsByOrgIdVariables;
    }
  | {
      path: '/api/Reports/Group/PhysicianGroup/{id}';
      operationId: 'reportsGetGroupPhysicianGroupById';
      variables: ReportsGetGroupPhysicianGroupByIdVariables;
    }
  | {
      path: '/api/Reports/Group/{reportId}';
      operationId: 'reportsGetGroupByReportId';
      variables: ReportsGetGroupByReportIdVariables;
    }
  | {
      path: '/api/Health';
      operationId: 'healthGet';
      variables: HealthGetVariables;
    }
  | {
      path: '/api/Hospitals';
      operationId: 'hospitalsGet';
      variables: HospitalsGetVariables;
    }
  | {
      path: '/api/Hospitals/HospitalsWithSystem';
      operationId: 'hospitalsGetHospitalsWithSystem';
      variables: HospitalsGetHospitalsWithSystemVariables;
    }
  | {
      path: '/api/Hospitals/{id}';
      operationId: 'hospitalsGetById';
      variables: HospitalsGetByIdVariables;
    }
  | {
      path: '/api/Mdc';
      operationId: 'mdcGet';
      variables: MdcGetVariables;
    }
  | {
      path: '/api/Mdc/{mdcId}';
      operationId: 'mdcGetByMdcId';
      variables: MdcGetByMdcIdVariables;
    }
  | {
      path: '/api/Metrics/Cmi/{orgId}';
      operationId: 'metricsGetCmiByOrgId';
      variables: MetricsGetCmiByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Conditions/{orgId}';
      operationId: 'metricsGetConditionsByOrgId';
      variables: MetricsGetConditionsByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Conditions';
      operationId: 'metricsGetConditions';
      variables: MetricsGetConditionsVariables;
    }
  | {
      path: '/api/Metrics/DocScore/{orgId}';
      operationId: 'metricsGetDocScoreByOrgId';
      variables: MetricsGetDocScoreByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/DrgMix/{orgId}';
      operationId: 'metricsGetDrgMixByOrgId';
      variables: MetricsGetDrgMixByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/DrgMixComparison/{orgId}';
      operationId: 'metricsGetDrgMixComparisonByOrgId';
      variables: MetricsGetDrgMixComparisonByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/ElixhauserMortality/{orgId}';
      operationId: 'metricsGetElixhauserMortalityByOrgId';
      variables: MetricsGetElixhauserMortalityByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/ElixhauserReadmission/{orgId}';
      operationId: 'metricsGetElixhauserReadmissionByOrgId';
      variables: MetricsGetElixhauserReadmissionByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Los/{orgId}';
      operationId: 'metricsGetLosByOrgId';
      variables: MetricsGetLosByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Conditions/Shares/{orgId}';
      operationId: 'metricsGetConditionsSharesByOrgId';
      variables: MetricsGetConditionsSharesByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Conditions/Shares';
      operationId: 'metricsGetConditionsShares';
      variables: MetricsGetConditionsSharesVariables;
    }
  | {
      path: '/api/Metrics/OverallPerformance';
      operationId: 'metricsGetOverallPerformance';
      variables: MetricsGetOverallPerformanceVariables;
    }
  | {
      path: '/api/Metrics/Psi/{orgId}';
      operationId: 'metricsGetPsiByOrgId';
      variables: MetricsGetPsiByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/PsiPies/{orgId}';
      operationId: 'metricsGetPsiPiesByOrgId';
      variables: MetricsGetPsiPiesByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/Raf/{orgId}';
      operationId: 'metricsGetRafByOrgId';
      variables: MetricsGetRafByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/SeverityCmi/{orgId}';
      operationId: 'metricsGetSeverityCmiByOrgId';
      variables: MetricsGetSeverityCmiByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/SeverityCmiConditions/{orgId}';
      operationId: 'metricsGetSeverityCmiConditionsByOrgId';
      variables: MetricsGetSeverityCmiConditionsByOrgIdVariables;
    }
  | {
      path: '/api/Metrics/SeverityCmiComparison/{orgId}';
      operationId: 'metricsGetSeverityCmiComparisonByOrgId';
      variables: MetricsGetSeverityCmiComparisonByOrgIdVariables;
    }
  | {
      path: '/api/Download/Metrics/PatientMixAnalysisReport/{orgId}';
      operationId: 'downloadGetMetricsPatientMixAnalysisReportByOrgId';
      variables: DownloadGetMetricsPatientMixAnalysisReportByOrgIdVariables;
    }
  | {
      path: '/api/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}';
      operationId: 'downloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgId';
      variables: DownloadGetMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables;
    }
  | {
      path: '/api/Download/Metrics/Cmi/{id}';
      operationId: 'downloadGetMetricsCmiById';
      variables: DownloadGetMetricsCmiByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/Conditions/{id}';
      operationId: 'downloadGetMetricsConditionsById';
      variables: DownloadGetMetricsConditionsByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/DocScore/{id}';
      operationId: 'downloadGetMetricsDocScoreById';
      variables: DownloadGetMetricsDocScoreByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/DrgMixComparison/{id}';
      operationId: 'downloadGetMetricsDrgMixComparisonById';
      variables: DownloadGetMetricsDrgMixComparisonByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/ElixhauserMortality/{id}';
      operationId: 'downloadGetMetricsElixhauserMortalityById';
      variables: DownloadGetMetricsElixhauserMortalityByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/ElixhauserReadmission/{id}';
      operationId: 'downloadGetMetricsElixhauserReadmissionById';
      variables: DownloadGetMetricsElixhauserReadmissionByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/Los/{id}';
      operationId: 'downloadGetMetricsLosById';
      variables: DownloadGetMetricsLosByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/PatientMixAnalysis/{id}';
      operationId: 'downloadGetMetricsPatientMixAnalysisById';
      variables: DownloadGetMetricsPatientMixAnalysisByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/Psi/{id}';
      operationId: 'downloadGetMetricsPsiById';
      variables: DownloadGetMetricsPsiByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/Raf/{id}';
      operationId: 'downloadGetMetricsRafById';
      variables: DownloadGetMetricsRafByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/SeverityCmi/{id}';
      operationId: 'downloadGetMetricsSeverityCmiById';
      variables: DownloadGetMetricsSeverityCmiByIdVariables;
    }
  | {
      path: '/api/Download/Metrics/SeverityCmiConditionImpact/{id}';
      operationId: 'downloadGetMetricsSeverityCmiConditionImpactById';
      variables: DownloadGetMetricsSeverityCmiConditionImpactByIdVariables;
    }
  | {
      path: '/api/Org/{orgId}';
      operationId: 'orgGetByOrgId';
      variables: OrgGetByOrgIdVariables;
    }
  | {
      path: '/api/Org/FundamentalGroups';
      operationId: 'orgGetFundamentalGroups';
      variables: OrgGetFundamentalGroupsVariables;
    }
  | {
      path: '/api/Org/SystemView/{id}';
      operationId: 'orgGetSystemViewById';
      variables: OrgGetSystemViewByIdVariables;
    }
  | {
      path: '/api/Org';
      operationId: 'orgGet';
      variables: OrgGetVariables;
    }
  | {
      path: '/api/OrgChangeRequests/Hierarchy';
      operationId: 'orgChangeRequestsGetHierarchy';
      variables: OrgChangeRequestsGetHierarchyVariables;
    }
  | {
      path: '/api/OrgChangeRequests';
      operationId: 'orgChangeRequestsGet';
      variables: OrgChangeRequestsGetVariables;
    }
  | {
      path: '/api/OrgChangeRequests/UserHierarchy';
      operationId: 'orgChangeRequestsGetUserHierarchy';
      variables: OrgChangeRequestsGetUserHierarchyVariables;
    }
  | {
      path: '/api/OrgChangeRequests/Template';
      operationId: 'orgChangeRequestsGetTemplate';
      variables: OrgChangeRequestsGetTemplateVariables;
    }
  | {
      path: '/api/OrgChangeRequests/History';
      operationId: 'orgChangeRequestsGetHistory';
      variables: OrgChangeRequestsGetHistoryVariables;
    }
  | {
      path: '/api/PhysicianGroups';
      operationId: 'physicianGroupsGet';
      variables: PhysicianGroupsGetVariables;
    }
  | {
      path: '/api/Provider';
      operationId: 'providerGet';
      variables: ProviderGetVariables;
    }
  | {
      path: '/api/Reports/Provider/{providerOrgId}';
      operationId: 'reportsGetProviderByProviderOrgId';
      variables: ReportsGetProviderByProviderOrgIdVariables;
    }
  | {
      path: '/api/Reports/Provider/Log/PhysicianGroup/{physicianGroupOrgId}';
      operationId: 'reportsGetProviderLogPhysicianGroupByPhysicianGroupOrgId';
      variables: ReportsGetProviderLogPhysicianGroupByPhysicianGroupOrgIdVariables;
    }
  | {
      path: '/api/Reports/Provider/Generate';
      operationId: 'reportsGetProviderGenerate';
      variables: ReportsGetProviderGenerateVariables;
    }
  | {
      path: '/api/Roles';
      operationId: 'rolesGet';
      variables: RolesGetVariables;
    }
  | {
      path: '/api/Roles/{id}';
      operationId: 'rolesGetById';
      variables: RolesGetByIdVariables;
    }
  | {
      path: '/api/Saf/Conditions';
      operationId: 'safGetConditions';
      variables: SafGetConditionsVariables;
    }
  | {
      path: '/api/Saf/Graph/Cmi/{id}';
      operationId: 'safGetGraphCmiById';
      variables: SafGetGraphCmiByIdVariables;
    }
  | {
      path: '/api/Saf/Graph/DocScore/{id}';
      operationId: 'safGetGraphDocScoreById';
      variables: SafGetGraphDocScoreByIdVariables;
    }
  | {
      path: '/api/Saf/Graph/HcupCmi/{id}';
      operationId: 'safGetGraphHcupCmiById';
      variables: SafGetGraphHcupCmiByIdVariables;
    }
  | {
      path: '/api/Saf/Histogram/SafHistogramReport';
      operationId: 'safGetHistogramSafHistogramReport';
      variables: SafGetHistogramSafHistogramReportVariables;
    }
  | {
      path: '/api/Saf/Histogram/Filters';
      operationId: 'safGetHistogramFilters';
      variables: SafGetHistogramFiltersVariables;
    }
  | {
      path: '/api/Saf/Histogram/Filters/{orgId}';
      operationId: 'safGetHistogramFiltersByOrgId';
      variables: SafGetHistogramFiltersByOrgIdVariables;
    }
  | {
      path: '/api/Saf/Histogram';
      operationId: 'safGetHistogram';
      variables: SafGetHistogramVariables;
    }
  | {
      path: '/api/Saf/Histogram/MetricTypes';
      operationId: 'safGetHistogramMetricTypes';
      variables: SafGetHistogramMetricTypesVariables;
    }
  | {
      path: '/api/Saf/Metrics/Conditions';
      operationId: 'safGetMetricsConditions';
      variables: SafGetMetricsConditionsVariables;
    }
  | {
      path: '/api/Saf/Metrics';
      operationId: 'safGetMetrics';
      variables: SafGetMetricsVariables;
    }
  | {
      path: '/api/Saf/Metrics/DrgMixComparison/{id}';
      operationId: 'safGetMetricsDrgMixComparisonById';
      variables: SafGetMetricsDrgMixComparisonByIdVariables;
    }
  | {
      path: '/api/Saf/Metrics/SeverityCmiComparison/{id}';
      operationId: 'safGetMetricsSeverityCmiComparisonById';
      variables: SafGetMetricsSeverityCmiComparisonByIdVariables;
    }
  | {
      path: '/api/Saf/Download/Metrics/CmiComparisonReport/{orgId}/{compareToOrgId}';
      operationId: 'safGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgId';
      variables: SafGetDownloadMetricsCmiComparisonReportByOrgIdByCompareToOrgIdVariables;
    }
  | {
      path: '/api/Saf/Download/Metrics/DrgMixComparison/{id}';
      operationId: 'safGetDownloadMetricsDrgMixComparisonById';
      variables: SafGetDownloadMetricsDrgMixComparisonByIdVariables;
    }
  | {
      path: '/api/SAF/Org/DefaultDates/{orgId}';
      operationId: 'sAFGetOrgDefaultDatesByOrgId';
      variables: SAFGetOrgDefaultDatesByOrgIdVariables;
    }
  | {
      path: '/api/SAF/Org/{id}';
      operationId: 'sAFGetOrgById';
      variables: SAFGetOrgByIdVariables;
    }
  | {
      path: '/api/SAF/Org';
      operationId: 'sAFGetOrg';
      variables: SAFGetOrgVariables;
    }
  | {
      path: '/api/Schedule/Reports';
      operationId: 'scheduleGetReports';
      variables: ScheduleGetReportsVariables;
    }
  | {
      path: '/api/Schedule/Reports/{id}';
      operationId: 'scheduleGetReportsById';
      variables: ScheduleGetReportsByIdVariables;
    }
  | {
      path: '/api/Schedule/Reports/Download/{id}';
      operationId: 'scheduleGetReportsDownloadById';
      variables: ScheduleGetReportsDownloadByIdVariables;
    }
  | {
      path: '/api/Schedule/Reports/ReportTypes';
      operationId: 'scheduleGetReportsReportTypes';
      variables: ScheduleGetReportsReportTypesVariables;
    }
  | {
      path: '/api/Schedule/Reports/Frequencies';
      operationId: 'scheduleGetReportsFrequencies';
      variables: ScheduleGetReportsFrequenciesVariables;
    }
  | {
      path: '/api/Schema/AuthorizedUser';
      operationId: 'schemaGetAuthorizedUser';
      variables: SchemaGetAuthorizedUserVariables;
    }
  | {
      path: '/api/Schema/Cmi';
      operationId: 'schemaGetCmi';
      variables: SchemaGetCmiVariables;
    }
  | {
      path: '/api/Schema/Condition';
      operationId: 'schemaGetCondition';
      variables: SchemaGetConditionVariables;
    }
  | {
      path: '/api/Schema/DefaultDates';
      operationId: 'schemaGetDefaultDates';
      variables: SchemaGetDefaultDatesVariables;
    }
  | {
      path: '/api/Schema/DocScore';
      operationId: 'schemaGetDocScore';
      variables: SchemaGetDocScoreVariables;
    }
  | {
      path: '/api/Schema/ElixhauserMortality';
      operationId: 'schemaGetElixhauserMortality';
      variables: SchemaGetElixhauserMortalityVariables;
    }
  | {
      path: '/api/Schema/ElixhauserReadmission';
      operationId: 'schemaGetElixhauserReadmission';
      variables: SchemaGetElixhauserReadmissionVariables;
    }
  | {
      path: '/api/Schema/Los';
      operationId: 'schemaGetLos';
      variables: SchemaGetLosVariables;
    }
  | {
      path: '/api/Schema/OverallPerformance';
      operationId: 'schemaGetOverallPerformance';
      variables: SchemaGetOverallPerformanceVariables;
    }
  | {
      path: '/api/Schema/Psi';
      operationId: 'schemaGetPsi';
      variables: SchemaGetPsiVariables;
    }
  | {
      path: '/api/Schema/Raf';
      operationId: 'schemaGetRaf';
      variables: SchemaGetRafVariables;
    }
  | {
      path: '/api/Schema/SeverityCmi';
      operationId: 'schemaGetSeverityCmi';
      variables: SchemaGetSeverityCmiVariables;
    }
  | {
      path: '/api/Schema/UserTestDto';
      operationId: 'schemaGetUserTestDto';
      variables: SchemaGetUserTestDtoVariables;
    }
  | {
      path: '/api/Settings/{userId}/Histogram/Filters/{id}';
      operationId: 'settingsGetByUserIdHistogramFiltersById';
      variables: SettingsGetByUserIdHistogramFiltersByIdVariables;
    }
  | {
      path: '/api/Settings/{userId}/Histogram/Filters';
      operationId: 'settingsGetByUserIdHistogramFilters';
      variables: SettingsGetByUserIdHistogramFiltersVariables;
    }
  | {
      path: '/api/Reports/System/ExecutiveSummary';
      operationId: 'reportsGetSystemExecutiveSummary';
      variables: ReportsGetSystemExecutiveSummaryVariables;
    }
  | {
      path: '/api/TenantSettings/AuthConfig';
      operationId: 'tenantSettingsGetAuthConfig';
      variables: TenantSettingsGetAuthConfigVariables;
    }
  | {
      path: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}/{providerOrgHierarchyId}';
      operationId: 'trainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgId';
      variables: TrainingGetAssignmentByPhysicianGroupOrgIdByProviderOrgIdVariables;
    }
  | {
      path: '/api/Training/Assignment/DefaultCreditMonths';
      operationId: 'trainingGetAssignmentDefaultCreditMonths';
      variables: TrainingGetAssignmentDefaultCreditMonthsVariables;
    }
  | {
      path: '/api/Training/Assignment/{physicianGroupOrgHierarchyId}';
      operationId: 'trainingGetAssignmentByPhysicianGroupOrgId';
      variables: TrainingGetAssignmentByPhysicianGroupOrgIdVariables;
    }
  | {
      path: '/api/Training/ConditionPerformance';
      operationId: 'trainingGetConditionPerformance';
      variables: TrainingGetConditionPerformanceVariables;
    }
  | {
      path: '/api/Training/Content/SpecialtyBaskets';
      operationId: 'trainingGetContentSpecialtyBaskets';
      variables: TrainingGetContentSpecialtyBasketsVariables;
    }
  | {
      path: '/api/Training/Content/Conditions/{conditionId}';
      operationId: 'trainingGetContentConditionsByConditionId';
      variables: TrainingGetContentConditionsByConditionIdVariables;
    }
  | {
      path: '/api/Training/Content/ContentConditions';
      operationId: 'trainingGetContentContentConditions';
      variables: TrainingGetContentContentConditionsVariables;
    }
  | {
      path: '/api/Training/Content/ContentConditionsWithTrainingContent';
      operationId: 'trainingGetContentContentConditionsWithTrainingContent';
      variables: TrainingGetContentContentConditionsWithTrainingContentVariables;
    }
  | {
      path: '/api/Training/Content/FundamentalSpecialties';
      operationId: 'trainingGetContentFundamentalSpecialties';
      variables: TrainingGetContentFundamentalSpecialtiesVariables;
    }
  | {
      path: '/api/Training/Content/PocketCards/{pocketCardId}';
      operationId: 'trainingGetContentPocketCardsByPocketCardId';
      variables: TrainingGetContentPocketCardsByPocketCardIdVariables;
    }
  | {
      path: '/api/Training/Content/Questions/{questionId}';
      operationId: 'trainingGetContentQuestionsByQuestionId';
      variables: TrainingGetContentQuestionsByQuestionIdVariables;
    }
  | {
      path: '/api/Training/Content/SpecialtyBaskets/{specialtyBasketId}';
      operationId: 'trainingGetContentSpecialtyBasketsBySpecialtyBasketId';
      variables: TrainingGetContentSpecialtyBasketsBySpecialtyBasketIdVariables;
    }
  | {
      path: '/api/Training/Content/SpecialtyBasketsForContentCondition/{contentConditionId}';
      operationId: 'trainingGetContentSpecialtyBasketsForContentConditionByContentConditionId';
      variables: TrainingGetContentSpecialtyBasketsForContentConditionByContentConditionIdVariables;
    }
  | {
      path: '/api/Training/Content/{contentConditionId}';
      operationId: 'trainingGetContentByContentConditionId';
      variables: TrainingGetContentByContentConditionIdVariables;
    }
  | {
      path: '/api/Training/Content/TrainingVideos/{trainingVideoId}';
      operationId: 'trainingGetContentTrainingVideosByTrainingVideoId';
      variables: TrainingGetContentTrainingVideosByTrainingVideoIdVariables;
    }
  | {
      path: '/api/Training/Content/VideoLinkTypes';
      operationId: 'trainingGetContentVideoLinkTypes';
      variables: TrainingGetContentVideoLinkTypesVariables;
    }
  | {
      path: '/api/Training/Management/ApprovalTrainingContent';
      operationId: 'trainingGetManagementApprovalTrainingContent';
      variables: TrainingGetManagementApprovalTrainingContentVariables;
    }
  | {
      path: '/api/Training/Management/approvaltrainingcontent/{orgId}';
      operationId: 'trainingGetManagementApprovaltrainingcontentByOrgId';
      variables: TrainingGetManagementApprovaltrainingcontentByOrgIdVariables;
    }
  | {
      path: '/api/Training/Management/Content/{conditionId}';
      operationId: 'trainingGetManagementContentByConditionId';
      variables: TrainingGetManagementContentByConditionIdVariables;
    }
  | {
      path: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}/Download';
      operationId: 'reportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownload';
      variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdDownloadVariables;
    }
  | {
      path: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}/Download';
      operationId: 'reportsGetTrainingHospitalByHospitalOrgIdDownload';
      variables: ReportsGetTrainingHospitalByHospitalOrgIdDownloadVariables;
    }
  | {
      path: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}/Download';
      operationId: 'reportsGetTrainingPhysicianByPhysicianOrgIdDownload';
      variables: ReportsGetTrainingPhysicianByPhysicianOrgIdDownloadVariables;
    }
  | {
      path: '/api/Reports/Training/System/{systemOrgHierarchyId}/Download';
      operationId: 'reportsGetTrainingSystemBySystemOrgIdDownload';
      variables: ReportsGetTrainingSystemBySystemOrgIdDownloadVariables;
    }
  | {
      path: '/api/Reports/Training/PhysicianGroup/{physicianGroupOrgHierarchyId}';
      operationId: 'reportsGetTrainingPhysicianGroupByPhysicianGroupOrgId';
      variables: ReportsGetTrainingPhysicianGroupByPhysicianGroupOrgIdVariables;
    }
  | {
      path: '/api/Reports/Training/Hospital/{hospitalOrgHierarchyId}';
      operationId: 'reportsGetTrainingHospitalByHospitalOrgId';
      variables: ReportsGetTrainingHospitalByHospitalOrgIdVariables;
    }
  | {
      path: '/api/Reports/Training/Physician/{physicianOrgHierarchyId}';
      operationId: 'reportsGetTrainingPhysicianByPhysicianOrgId';
      variables: ReportsGetTrainingPhysicianByPhysicianOrgIdVariables;
    }
  | {
      path: '/api/Reports/Training/System/{systemOrgHierarchyId}';
      operationId: 'reportsGetTrainingSystemBySystemOrgId';
      variables: ReportsGetTrainingSystemBySystemOrgIdVariables;
    }
  | {
      path: '/api/Reports/Training/conditions/{physicianGroupOrgHierarchyId}';
      operationId: 'reportsGetTrainingConditionsByPhysicianGroupOrgId';
      variables: ReportsGetTrainingConditionsByPhysicianGroupOrgIdVariables;
    }
  | {
      path: '/api/Training/Test/NextSequence';
      operationId: 'trainingGetTestNextSequence';
      variables: TrainingGetTestNextSequenceVariables;
    }
  | {
      path: '/api/Training/Test/Progress';
      operationId: 'trainingGetTestProgress';
      variables: TrainingGetTestProgressVariables;
    }
  | {
      path: '/api/Users';
      operationId: 'usersGet';
      variables: UsersGetVariables;
    }
  | {
      path: '/api/Users/{userId}';
      operationId: 'usersGetByUserId';
      variables: UsersGetByUserIdVariables;
    }
  | {
      path: '/api/Users/IsNpiTaken';
      operationId: 'usersGetIsNpiTaken';
      variables: UsersGetIsNpiTakenVariables;
    }
  | {
      path: '/api/Users/ValidateNpi';
      operationId: 'usersGetValidateNpi';
      variables: UsersGetValidateNpiVariables;
    }
  | {
      path: '/api/Users/ClinicalLeaders';
      operationId: 'usersGetClinicalLeaders';
      variables: UsersGetClinicalLeadersVariables;
    }
  | {
      path: '/api/Version';
      operationId: 'versionGet';
      variables: VersionGetVariables;
    };
