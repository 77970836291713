// DO NOT USE Role Based Access Control (RBAC) - Per Brian, Future features include giving Tenants the ability to create
// new roles and assign existing features to those roles on the fly
// Use ABAC - Attribute Based Access Control

import { User } from '@clinintell/modules/user';

// Feature type is
// features.join("' | '")

export type Feature =
  | 'conditionSingle'
  | 'dashboardAdmin'
  | 'dashboardProvider'
  | 'dashboardProviderLead'
  | 'metricsAll'
  | 'metricsDocScore'
  | 'reportGroupReportAdminEdit'
  | 'reportGroupReportAdminView'
  | 'reportBenchmarkReportEdit'
  | 'reportGroupReportProviderView'
  | 'reportProviderReportView'
  | 'reportProviderReportSend'
  | 'reportGroupReportSend'
  | 'reportProviderSend'
  | 'schedulerReportView'
  | 'schedulerReportEdit'
  | 'reportTrainingReportProgressView'
  | 'trainingContentView'
  | 'trainingEdit'
  | 'trainingWatchedVideosCredit'
  | 'trainingDeployToGroup'
  | 'trainingPerfomanceView'
  | 'trainingPreview'
  | 'trainingReminderSend'
  | 'trainingStatusView'
  | 'trainingTake'
  | 'usersEdit'
  | 'usersView'
  | 'resourceAdmin'
  | 'resourceGeneralAdminView'
  | 'resourceLeadAdminView'
  | 'resourceAnalyticsOnlyView'
  | 'resourceEducatorView'
  | 'resourceProviderView'
  | 'resourceProviderLeadView'
  | 'resourceSuperUserView'
  | 'resourcePreviewTrainingView'
  | 'resourceDemoView'
  | 'resourceGuestView'
  | 'metricsConditionsViewAllConditions'
  | 'metricsConditionsViewTargetConditions'
  | 'metricsConditionsSaveTargetConditions'
  | 'reportCmiAnalysisView'
  | 'trainingContentApproval'
  | 'reportCmiComparisonView'
  | 'docScoreGoalView'
  | 'docScoreGoalEdit'
  | 'docScoreGoalOverride'
  | 'reportCmiAnalysisViewProviders'
  | 'reportCmiComparisonOfProvidersView'
  | 'reportSystemReport'
  | 'safHistogramView'
  | 'safHistogramAdvancedFilter'
  | 'reportMedicareComparisonView'
  | 'reportMedicareComparisonOfProvidersView'
  | 'orgHierarchyBulkUpload'
  | 'schedulerReportView'
  | 'schedulerReportEdit'
  | 'manageProvider';

export type RoleCategory = {
  static: Feature[];
  dynamic: unknown;
};

export type DynamicPermissionParam =
  | {
      [key: string]: string | number;
    }
  | unknown
  | undefined;

export const userCan = (user: User, features: Feature[] | [], data?: DynamicPermissionParam): boolean => {
  if (!user || !user.features) {
    return false;
  }

  const userPermissions: Feature[] = user.features.map(v => v.featureName);
  // if allowed features/permissions array is empty there are no rules and access is allowed
  if (features.length === 0) {
    return true;
  }
  // if allowed list includes any of user.features, then allow
  const isAllowed: boolean = features.some((v: Feature) => userPermissions.includes(v));
  return isAllowed;
};

type UserRoles = 'Provider' | 'Administrator';

export const getUserRole = (features: Feature[]): UserRoles => {
  const userHasDashboardProviderFeature = features.find(feature => feature === 'metricsDocScore');
  return userHasDashboardProviderFeature ? 'Provider' : 'Administrator';
};
