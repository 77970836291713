import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMetricsNavigation, useMetricsNavigationDispatch } from '@clinintell/modules/store';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import useAllConditionsData from '../fetchers/useAllConditionsData';

import {
  MetricsGetCmiByOrgIdQueryParams,
  MetricsGetConditionsByOrgIdQueryParams,
  MetricsGetPsiPiesByOrgIdResponse,
  useMetricsGetCmiByOrgId,
  useMetricsGetConditionsByOrgId,
  useMetricsGetDocScoreByOrgId,
  useMetricsGetElixhauserMortalityByOrgId,
  useMetricsGetElixhauserReadmissionByOrgId,
  useMetricsGetLosByOrgId,
  useMetricsGetPsiByOrgId,
  useMetricsGetRafByOrgId,
  useMetricsGetSeverityCmiByOrgId
} from '@clinintell/api/apiComponents';
import { ConditionData, MetricDataRecord } from '../typings/metricTypes';
import { setTargetedConditions } from '@clinintell/modules/metricsNavigation';
import useErrorHandling from '@clinintell/errors/useErrorHandling';

export type UseMetricFetcherOutput = {
  data: MetricDataRecord[];
  conditionData: ConditionData;
  isLoading: boolean;
  psiPieShares: MetricsGetPsiPiesByOrgIdResponse | undefined;
  targetedConditions: number[];
  userCanDownload: boolean;
};

// original cacheTime 1000 * 60 * 60 * 2
// original staleTime 1000 * 60 * 60 * 1
export const CACHE_TIMES = {
  cacheTime: 0,
  staleTime: 0
};

const MetricsDataContext = createContext<UseMetricFetcherOutput>({
  data: [],
  conditionData: {},
  psiPieShares: undefined,
  targetedConditions: [],
  isLoading: false,
  userCanDownload: true
});

export const MetricsDataProvider: React.FC = ({ children }) => {
  const { showErrorMessage } = useErrorHandling();
  const [data, setData] = useState<MetricDataRecord[]>([]);
  const [conditionData, setConditionData] = useState<ConditionData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [psiPieShares, setPsiPieShares] = useState<MetricsGetPsiPiesByOrgIdResponse>();
  const [userCanDownload, setUserCanDownload] = useState(true);

  const {
    metric,
    display,
    entity,
    condition,
    periodStart,
    periodEnd,
    comparisonPeriodStart,
    comparisonPeriodEnd,
    isInitialized,
    navigateToTargetConditions,
    psi02Weight,
    psi04Weight,
    psi07Weight,
    psi90Weight,
    hccWeight,
    exmWeight,
    exrWeight,
    psiWeight
  } = useMetricsNavigation();
  const metricsNavDispatch = useMetricsNavigationDispatch();

  const commonDateParams: MetricsGetCmiByOrgIdQueryParams = {
    ...(comparisonPeriodStart && comparisonPeriodEnd && { historicalStart: formatDateForAPI(comparisonPeriodStart) }),
    ...(comparisonPeriodStart && comparisonPeriodEnd && { historicalEnd: formatDateForAPI(comparisonPeriodEnd) }),
    currentStart: formatDateForAPI(periodStart),
    currentEnd: formatDateForAPI(periodEnd)
  };

  // If on the comparison table view from target conditions...
  const isComparisonTableViewFromTargetConditions = display === 'comparison' && condition && navigateToTargetConditions;

  const commonConditionParams: MetricsGetConditionsByOrgIdQueryParams = {
    shareDrg: 5,
    shareHcc: isComparisonTableViewFromTargetConditions ? 5 : hccWeight,
    shareMortality: isComparisonTableViewFromTargetConditions ? 5 : exmWeight,
    shareReadmission: isComparisonTableViewFromTargetConditions ? 5 : exrWeight,
    sharePsi: isComparisonTableViewFromTargetConditions ? 5 : psiWeight,
    ...commonDateParams
  };

  const handleMetricStateChange = (
    data: MetricDataRecord[] | undefined | null,
    isLoading: boolean,
    conditionData?: ConditionData,
    psiPieData?: MetricsGetPsiPiesByOrgIdResponse
  ) => {
    if (isLoading || !data) {
      setIsLoading(true);
    } else {
      setData(data);

      if (conditionData) {
        setConditionData(conditionData);
      }

      if (psiPieData) {
        setPsiPieShares(psiPieData);
      }

      setIsLoading(false);
    }
  };

  const { data: cmiData, isFetching: isFetchingCmiData } = useMetricsGetCmiByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'cmi' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: severityCmiData, isFetching: isFetchingSeverityCmiData } = useMetricsGetSeverityCmiByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'severityCmi' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: docScoreData, isFetching: isFetchingDocScoreData } = useMetricsGetDocScoreByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'docScore' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: losData, isFetching: isFetchingLosData } = useMetricsGetLosByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'los' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: elixMortalityData, isFetching: isFetchingElixMortalityData } = useMetricsGetElixhauserMortalityByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'elixhauserMortality' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: elixReadData, isFetching: isFetchingElixReadData } = useMetricsGetElixhauserReadmissionByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'elixhauserReadmission' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: psiPieData, isFetching: isFetchingPsiPieData } = useMetricsGetPsiByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'psi' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: rafData, isFetching: isFetchingRafData } = useMetricsGetRafByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'raf' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const allConditionsData = useAllConditionsData({
    isEnabled: metric === 'allConditions' && isInitialized,
    entity,
    conditionParams: commonConditionParams
  });

  const { data: targetedConditionData, isFetching: isFetchingTargetedConditionData } = useMetricsGetConditionsByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonDateParams,
        includeAllConditions: 0
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'targetedConditions' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  const { data: conditionsData, isFetching: isFetchingConditionsData } = useMetricsGetConditionsByOrgId(
    {
      pathParams: {
        orgId: entity
      },
      queryParams: {
        ...commonConditionParams,
        ...(commonConditionParams.shareHcc && { shareHcc: commonConditionParams.shareHcc }),
        ...(commonConditionParams.shareMortality && { shareMortality: commonConditionParams.shareMortality }),
        ...(commonConditionParams.shareReadmission && { shareReadmission: commonConditionParams.shareReadmission }),
        ...(commonConditionParams.sharePsi && { sharePsi: commonConditionParams.sharePsi }),
        adjPsi2Weight: psi02Weight || -1,
        adjPsi4Weight: psi04Weight || -1,
        adjPsi7Weight: psi07Weight || -1,
        adjPsi90Weight: psi90Weight || -1,
        conditionId: condition || 0
      },
      onError: showErrorMessage
    },
    {
      enabled: metric === 'condition' && isInitialized,
      refetchOnWindowFocus: false,
      ...CACHE_TIMES
    }
  );

  useEffect(() => {
    metricsNavDispatch(setTargetedConditions(allConditionsData.targetedConditions));
  }, [allConditionsData.targetedConditions, metricsNavDispatch]);

  useEffect(() => {
    switch (metric) {
      case 'cmi':
        handleMetricStateChange(cmiData, isFetchingCmiData);
        break;
      case 'severityCmi':
        handleMetricStateChange(severityCmiData, isFetchingSeverityCmiData);
        break;
      case 'docScore':
        handleMetricStateChange(docScoreData, isFetchingDocScoreData);
        break;
      case 'los':
        handleMetricStateChange(losData, isFetchingLosData);
        break;
      case 'raf':
        handleMetricStateChange(rafData, isFetchingRafData);
        break;
      case 'elixhauserMortality':
        handleMetricStateChange(elixMortalityData, isFetchingElixMortalityData);
        break;
      case 'elixhauserReadmission':
        handleMetricStateChange(elixReadData, isFetchingElixReadData);
        break;
      case 'psi':
        handleMetricStateChange(psiPieData, isFetchingPsiPieData);
        break;
      case 'allConditions':
        handleMetricStateChange(
          allConditionsData.metrics,
          allConditionsData.isLoading,
          allConditionsData.conditionData,
          allConditionsData.psiPieShares
        );
        break;
      case 'targetedConditions':
        handleMetricStateChange(targetedConditionData?.metrics, isFetchingTargetedConditionData);
        break;
      case 'condition':
        handleMetricStateChange(conditionsData?.metrics, isFetchingConditionsData);
        break;
    }
  }, [
    allConditionsData.conditionData,
    allConditionsData.isLoading,
    allConditionsData.metrics,
    allConditionsData.psiPieShares,
    allConditionsData.targetedConditions,
    cmiData,
    conditionsData,
    docScoreData,
    elixMortalityData,
    elixReadData,
    isFetchingCmiData,
    isFetchingConditionsData,
    isFetchingDocScoreData,
    isFetchingElixMortalityData,
    isFetchingElixReadData,
    isFetchingLosData,
    isFetchingPsiPieData,
    isFetchingRafData,
    isFetchingSeverityCmiData,
    isFetchingTargetedConditionData,
    losData,
    metric,
    psiPieData,
    rafData,
    severityCmiData,
    targetedConditionData
  ]);

  useEffect(() => {
    // Need to hide the download button if there is no footer data
    setUserCanDownload(
      data.some(rec => rec.isFooter) ||
        metric === 'allConditions' ||
        metric === 'targetedConditions' ||
        metric === 'condition'
    );
  }, [data, metric]);

  return (
    <MetricsDataContext.Provider
      value={{
        data,
        isLoading,
        targetedConditions: [],
        psiPieShares,
        conditionData,
        userCanDownload
      }}
    >
      {children}
    </MetricsDataContext.Provider>
  );
};

export const useMetricsData = () => {
  const context = useContext(MetricsDataContext);

  if (!context) {
    throw new Error('useMetricsData hook must be used within a MetricsDataProvider');
  }

  return context;
};
