import {
  CmiDto,
  ConditionMetric,
  ConditionMetricConditionsMetricDto,
  LosDto,
  OEMetricDto,
  SeverityCmiDto
} from '@clinintell/api/apiSchemas';
import { ColumnDefinition } from '@clinintell/components/Table';

export type NodeType = 'Entity' | 'System View' | 'Hospital' | 'Specialty Group' | 'Provider' | 'Conditions';
export type NodeTypeLabel = 'System' | 'Hospital' | 'Physician Group' | 'Physician Name' | 'Clinical Condition' | 'N/A';

export enum NodeTypeEnum {
  'Entity' = 1,
  'System View',
  'Hospital',
  'Specialty Group',
  'Provider',
  'Conditions'
}

export type MetricDataRecord = CmiDto | SeverityCmiDto | OEMetricDto | LosDto | ConditionMetric;
export type ConditionData = Pick<
  ConditionMetricConditionsMetricDto,
  'drgShare' | 'hccShare' | 'mortalityShare' | 'psiShare' | 'readmissionShare'
>;

export interface MetricRecordJSON {
  [index: string]: string | number | boolean;
  name: string;
  current: number;
  clinicallyExpected: number;
  gapVsCe: number;
  gapVsCeInErrorRange: number;
  cases: number;
  opportunityRW: number;
  isFooter: boolean;
  orgId: number;
  nodeTypeId: number;
  nodeType: NodeType;
  mdcId: number;
  mdcName: string;
  conditionTypeId: number;
  conditionId: number;
  isTargetCondition: boolean;
  otoE: number;
  otoECE: number;
  otoEChange: number;
  otoEChangeInErrorRange: number;
  otoEErrorChangeNegative: number;
  otoEErrorChangePositive: number;
  otoEErrorChange: number;
  otoEErrorGapNegative: number;
  otoEErrorGapPositive: number;
  otoEErrorGap: number;
  otoEGapVsCe: number;
  otoEGapVsCeInErrorRange: number;
}

export interface CMIMetricRecordJSON extends MetricRecordJSON {
  historical: number;
  historicalClinicallyExpected: number;
  change: number;
}

export interface SmartStrategyMetricRecordJSON extends Exclude<CMIMetricRecordJSON, 'historicalClinicallyExpected'> {
  csvOrgIds: string;
  isLegalCondition: boolean;
  isAutoGenCondition: boolean;
  errorGap: number;
  errorChange: number;
  changeInErrorRange: number;
}

export interface MetricShareJSON {
  drg: number;
  hcc: number;
  elixhauserMortality: number;
  elixhauserReadmission: number;
  psi: number;
}

interface MetricShares {
  drgShare: number;
  hccShare: number;
  mortalityShare: number;
  readmissionShare: number;
  psiShare: number;
}

export interface MetricConditionRecordJSON extends MetricShares {
  metrics: MetricRecordJSON[];
}

export interface SmartStrategyConditionRecordJSON extends MetricShares {
  metrics: SmartStrategyMetricRecordJSON[];
}

export interface MetricConditionTrainingRecordJSON extends MetricRecordJSON {
  hasApprovedContent: boolean;
}

export interface MetricSchema {
  properties: Record<string, ColumnDefinition>;
}

export interface MetricConditionSchema extends MetricSchema {
  name: string;
  orgId: number;
  definitions: {
    ConditionMetric: {
      properties: Record<string, ColumnDefinition>;
    };
  };
}

export interface DefaultDatesJSON {
  absoluteMinMonth: string;
  historicalMinMonth: string;
  historicalMaxMonth: string;
  currentMinMonth: string;
  currentMaxMonth: string;
  interventionMonth: string | null;
}

// TODO consider refactoring CustomizedTreeView.tsx to use this
export interface TreeItemType {
  id: number;
  name: string;
  children: TreeItemType[] | null;
  childrenCount: number;
  parentId?: number;
  lineage?: number[] | [];
  nodeTypeId: number;
}
export interface TreeSchema {
  treeItem: TreeItemType;
}
export interface ConditionJSON {
  id: number;
  conditionName: string;
  conditionDescription: string;
  conditionType: string;
}
