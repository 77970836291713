import { ApplicationAPI, HttpResponseException } from '@clinintell/utils/api';
import { ApiContext } from './apiContext';
import { ApiRequestError, InvalidSessionError } from '../errors/customErrors';
export type ErrorWrapper<TError = undefined> = TError | { status?: number; payload: string };

export type ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & ApiContext['fetcherOptions'];

export type IRequestErrorDetails = {
  path: string;
  method: string;
  baseUrl: string;
  resolvedUrl: string;
  body?: unknown;
  queryParams?: Record<string, unknown>;
  pathParams?: Record<string, unknown>;
};

export async function apiFetch<
  TData,
  TError,
  TBody extends object | FormData | undefined | null,
  THeaders extends object,
  TQueryParams extends Record<string, unknown>,
  TPathParams extends Record<string, unknown>
>({
  url, // NOTE: stupid naming but part of codegen, this is the path
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
  onError
}: ApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  const accessToken = await ApplicationAPI.authenticateAndRetrieveCookie();

  const urlComponents = ApplicationAPI._url.split('/api');
  const apiUrl = `${urlComponents[0]}${url}`;

  const requestUrl: RequestInfo = resolveUrl(apiUrl, queryParams, pathParams);
  const requestConfig: RequestInit = {
    method: method.toUpperCase(),
    body: body && body instanceof FormData ? body : JSON.stringify(body)
  };

  const requestErrorDetails: IRequestErrorDetails = {
    baseUrl: apiUrl!,
    resolvedUrl: requestUrl,
    path: url,
    method: requestConfig.method!,
    body: requestConfig.body,
    pathParams,
    queryParams
  };

  try {
    const requestHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...headers
    };

    /**
     * As the fetch API is being used, when multipart/form-data is specified
     * the Content-Type header must be deleted so that the browser can set
     * the correct boundary.
     * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
     */
    if (requestHeaders['Content-Type'].toLowerCase().includes('multipart/form-data')) {
      delete requestHeaders['Content-Type'];
    }

    const response = await window.fetch(requestUrl, {
      signal,
      headers: requestHeaders,
      ...requestConfig
    });

    if (!response.ok) {
      let error: ErrorWrapper<TError>;

      try {
        error = await response.json();
      } catch (e) {
        error = {
          status: response.status,
          payload: ''
        };

        try {
          error.payload = await response.text();
        } catch {
          error.payload = 'Unexpected error';
        }
      }

      throw error;
    }

    const contentType = response.headers.get('Content-Type');

    return parseContent(response, contentType);
  } catch (requestError) {
    const errorWrapper = requestError as ErrorWrapper;

    // Suppress to the user anything to do with aborted network calls
    // React Query has built in handling and will abort network calls if too many of the same calls are sent out at once
    if (signal?.aborted) {
      // NOTE: for now do not bother recording aborted requests - they add too much noise
      // we can re-enable this in the future if needing to analyze aborted request issues
      // captureErrorSilently(new AbortedApiRequestError(errorWrapper, requestErrorDetails));

      return null as TData;
    }

    if (errorWrapper?.status === 401) {
      // TODO: implement refresh logic?
      throw new InvalidSessionError('Invalid session error found post fetch');
    }

    const error = new ApiRequestError(errorWrapper, requestErrorDetails);

    // NOTE: this should never happen as onError is required, put here as a safeguard
    if (!onError) throw error;

    onError(error);

    // NOTE: since onError is used there is no data and the call will return null
    return null as TData;
  }
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, unknown> = {},
  pathParams: Record<string, unknown> = {}
) => {
  let query = new URLSearchParams(queryParams as Record<string, string>).toString();
  if (query) query = `?${query}`;

  return url.replace(/\{\w*\}/g, key => (pathParams as Record<string, string>)[key.slice(1, -1)]) + query;
};

/**
 *
 * @param response API response
 * @param contentType content type of the response (from Content-Type header)
 * @returns null if no content type is specified
 * @throws a parsing error if the content cant be parsed
 */
const parseContent = async <TData>(response: Response, contentType: string | null): Promise<TData> => {
  if (response.status === 204 || contentType === null) return null as TData;
  try {
    switch (contentType) {
      case 'application/json':
      case 'application/json; charset=utf-8':
        return (await response.json()) as TData;
      case 'text/plain; charset=utf-8':
        const resp = response.clone();
        // The backend API is incorrectly returning text content types for certain calls that are actually
        // returning JSON data. Try to parse JSON first, if that fails parse the text.
        try {
          return (await response.json()) as TData;
        } catch {
          return ((await resp.text()) as unknown) as TData;
        }
      case 'application/vnd.openxmlformats':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/pdf':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/zip':
        return ((await response.arrayBuffer()) as unknown) as TData;
      default:
        return (await response.json()) as TData;
    }
  } catch (exception) {
    // TODO: replace with ContentParsingError
    throw new Error(`Response parsing exception caught - ${(exception as HttpResponseException).message}`);
  }
};
